import React from "react";
import { useOutletContext } from "react-router-dom";
import { useFormik } from "formik";
import { Box } from "@mui/material";
import { IUser } from "../../../../types/user.type";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { EditButton } from "../../../../components/editbutton/editbutton";
import { InputB } from "../../../../components/input-b/inputB";
import { InputDate } from "../../../../components/inputdate/inputdate";
import { MyButton } from "../../../../components/button/button";
import { AlertContext } from "../../../../hooks/notification/useAlert";
import { useChangeProfile } from "../../../../hooks/player/playerInfo/useChangeProfile";
import { Loader } from "../../../../components/loader/loader";

export const ProfileInfo = () => {
  const [edit, setEdit] = React.useState(false);
  const [user]: IUser[] = useOutletContext();
  const { alertHandler } = React.useContext(AlertContext);
  const { mutate: change, isPending } = useChangeProfile(user.id, alertHandler);
  const initialValues = user.person_info
    ? {
        name: user.person_info.name,
        surname: user.person_info.surname,
        secondname: user.person_info.secondname,
        date: user.person_info.date,
        country: "country",
        city: "city",
      }
    : {
        name: "",
        surname: "",
        secondname: "",
        date: 0,
        country: "",
        city: "",
      };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      change({
        name: values.name,
        surname: values.surname,
        secondname: values.secondname,
        date: values.date.toString(),
      });
      setEdit(false);
    },
  });
  const dateHandler = (date: Date) => {
    const newDate = date.valueOf();
    // console.log(newDate);
    formik.setValues({ ...formik.values, date: newDate.toString() });
  };
  const closeHandler = () => {
    formik.setValues(initialValues);
    setEdit(false);
  };
  const openHandler = () => {
    setEdit(true);
  };
  return (
    <Box
      sx={{
        padding: "5px 20px 60px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        position: "relative",
      }}
    >
      {isPending && <Loader />}
      <EditButton onClick={openHandler} />
      <form onSubmit={formik.handleSubmit}>
        <InputB
          id="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          label="Name"
          disabled={!edit}
        />
        <InputB
          id="surname"
          onChange={formik.handleChange}
          value={formik.values.surname}
          label="Surname"
          disabled={!edit}
        />
        <InputB
          id="secondname"
          onChange={formik.handleChange}
          value={formik.values.secondname}
          label="Secondname"
          disabled={!edit}
        />
        <InputDate
          id="date"
          label="Date"
          value={new Date(+formik.values.date)}
          onChange={dateHandler}
          disabled={!edit}
        />
        <InputB
          id="country"
          onChange={formik.handleChange}
          value={formik.values.country}
          label="Country"
          disabled={!edit}
        />
        <InputB
          id="city"
          onChange={formik.handleChange}
          value={formik.values.city}
          label="City"
          disabled={!edit}
        />
        {edit && (
          <Box
            sx={{
              display: "flex",
              gap: "14px",
              justifyContent: "flex-end",
              position: "absolute",
              bottom: "20px",
              right: "20px",
            }}
          >
            <MyButton
              type="button"
              text="Отмена"
              onClick={closeHandler}
              cancel
            />
            <MyButton type="submit" text="Сохранить" />
          </Box>
        )}
      </form>
    </Box>
  );
};
