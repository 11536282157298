import React, { useContext, useEffect } from "react";
import { ModalTemplate } from "../../../../components/modal/modal.template";
import { Box, Typography } from "@mui/material";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { InputB } from "../../../../components/input-b/inputB";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AlertContext } from "../../../../hooks/notification/useAlert";
import { useCreatePR } from "../../../../hooks/bonuses/useCreatePR";
import { IBrands } from "../../../../types/brand.type";
import { InputSelect } from "../../../../components/inputSelect/input.select";

interface IInitialValues {
  brand: IBrands | undefined;
  count_pers: 0;
  min_sum:
    | {
        currency_name: string;
        min_sum: string;
      }[];
  max_sum:
    | {
        currency_name: string;
        max_sum: string;
      }[];
}

const InitialValues: IInitialValues = {
  brand: undefined,
  count_pers: 0,
  min_sum: [],
  max_sum: [],
};

const PercentageSchema = Yup.object().shape({
  count_pers: Yup.number().required().positive().integer(),
  min_sum: Yup.array<
    {
      currency_name: string;
      min_sum: string;
    }[]
  >().required(),
  max_sum: Yup.array<
    {
      currency_name: string;
      max_sum: string;
    }[]
  >().required(),
});

type ICreateFreespinsModalProps = {
  brands: IBrands[];
  open: boolean;
  close: () => void;
};
export const CreatePercentageModal = ({
  brands,
  open,
  close,
}: ICreateFreespinsModalProps) => {
  const formikPR = useFormik({
    initialValues: InitialValues,
    validationSchema: PercentageSchema,
    onSubmit: (values) => {
      create(values);
    },
  });
  const { alertHandler } = useContext(AlertContext);
  const { mutate: create, isSuccess } = useCreatePR(alertHandler);
  useEffect(() => {
    if (isSuccess) {
      formikPR.setValues(InitialValues);
      alertHandler("Successfully created");
      close();
    }
  }, [isSuccess]);
  const minSumInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    min_sum: { currency_name: string; min_sum: string }
  ) => {
    const reg = /^[0-9]*$/;
    const value = e.target.value;
    if (reg.test(value) || value === "") {
      formikPR.setValues({
        ...formikPR.values,
        min_sum: formikPR.values.min_sum.map((old_min_sum) => {
          if (old_min_sum.currency_name === min_sum.currency_name) {
            old_min_sum.min_sum = value;
            return old_min_sum;
          }
          return old_min_sum;
        }),
      });
    }
  };
  const maxSumInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    max_sum: { currency_name: string; max_sum: string }
  ) => {
    const reg = /^[0-9]*$/;
    const value = e.target.value;
    if (reg.test(value) || value === "") {
      formikPR.setValues({
        ...formikPR.values,
        max_sum: formikPR.values.max_sum.map((old_max_sum) => {
          if (old_max_sum.currency_name === max_sum.currency_name) {
            old_max_sum.max_sum = value;
            return old_max_sum;
          }
          return old_max_sum;
        }),
      });
    }
  };
  useEffect(() => {
    if (formikPR.values.brand) {
      formikPR.setValues({
        ...formikPR.values,
        max_sum: formikPR.values.brand.currencies.map((currency) => ({
          currency_name: currency.name,
          max_sum: "0",
        })),
        min_sum: formikPR.values.brand.currencies.map((currency) => ({
          currency_name: currency.name,
          min_sum: "0",
        })),
      });
    }
  }, [formikPR.values.brand]);
  return (
    <ModalTemplate
      open={open}
      close={close}
      confirm={formikPR.handleSubmit}
      heading={"Create Percentage"}
      confirmButton="Create"
    >
      <Box sx={{ width: "440px" }}>
        <Box
          sx={{
            borderRadius: BORDERRADIUS.PRIMARY,
            border: `1px solid ${COLORS.ORANGE}`,
            display: "flex",
            gap: "10px",
            alignItems: "center",
            color: COLORS.ORANGE,
            padding: "10px",
            marginBottom: "10px",
          }}
        >
          <ErrorOutlineRoundedIcon />
          <Typography>
            To create percentage bonus fill the form.
            <br />
            All numbers should be integer.
          </Typography>
        </Box>
        <Box sx={{ marginBottom: "10px" }}>
          <InputSelect
            label={"Brand"}
            dataArray={brands.map((brand) => ({
              value: brand,
              text: brand.brand_name,
            }))}
            selectFunction={(value) => {
              formikPR.setValues({ ...formikPR.values, brand: value });
            }}
          />
        </Box>
        <InputB
          id="count_pers"
          onChange={formikPR.handleChange}
          value={formikPR.values.count_pers}
          label="Count of percents"
          error={formikPR.errors.count_pers}
        />
        <Box>
          {formikPR.values.brand &&
            formikPR.values.min_sum.map((min_sum, index) => {
              return (
                <InputB
                  key={`min_${min_sum.currency_name}_${index}`}
                  id={`min_${min_sum.currency_name}`}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    minSumInputHandler(event, min_sum);
                  }}
                  value={min_sum.min_sum}
                  label={`Min sum for ${min_sum.currency_name}`}
                />
              );
            })}
        </Box>
        <Box>
          {formikPR.values.brand &&
            formikPR.values.max_sum.map((max_sum, index) => {
              return (
                <InputB
                  key={`max_${max_sum.currency_name}_${index}`}
                  id={`max_${max_sum.currency_name}`}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    maxSumInputHandler(event, max_sum);
                  }}
                  value={max_sum.max_sum}
                  label={`Max sum for ${max_sum.currency_name}`}
                />
              );
            })}
        </Box>
      </Box>
    </ModalTemplate>
  );
};
