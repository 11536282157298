import React from "react";
import { IBonusesListItem } from "./BonusesItem/typing/BonusesListItem.interface";
import { Box } from "@mui/material";
import { DesktopItem } from "./DesktopItem";
import { MobileItem } from "./MobileItem";

export const BonusPreview = ({
  active,
  title,
  sub_title,
  type,
  sub_type,
  min_dep,
  wager_fs,
  wager_pers,
  bg_img,
  sub_img,
}: IBonusesListItem) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "40px",
        height: "100%",
      }}
    >
      <DesktopItem
        active={active}
        sub_title={sub_title}
        title={title}
        type={type}
        sub_type={sub_type}
        min_dep={min_dep}
        wager_fs={wager_fs}
        wager_pers={wager_pers}
        bg_img={bg_img}
        sub_img={sub_img}
      />
      <MobileItem
        active={active}
        sub_title={sub_title}
        title={title}
        type={type}
        sub_type={sub_type}
        min_dep={min_dep}
        wager_fs={wager_fs}
        wager_pers={wager_pers}
        bg_img={bg_img}
        sub_img={sub_img}
      />
    </Box>
  );
};
