import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { bonusService } from "../../services/bonusService";
import { IFreespinsExample } from "../../types/freespins.type";

export const useCreateFS = (
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (value: IFreespinsExample) => bonusService.createFS(value),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["freespins"] });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
