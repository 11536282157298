import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetPaymentMethods } from "../../../hooks/payments/useGetPaymentMethods";
import PaymentMethodsList from "../../../modules/payments/methods/paymentMethodList";
import { useGetPayments } from "../../../hooks/payments/useGetPayments";
import { Statistics } from "../../../modules/payments/methods/paymentStatistics";
import {
  IPayment,
  IPaymentMethod,
  IPaymentSystem,
} from "../../../types/payment.type";
import PaymentSystemList from "../../../modules/payments/methods/paymentSystemList";

export const PaymentMethods = () => {
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<Record<string, number>>({});
  const [amountByCurrencyAndMethod, setAmountByCurrencyAndMethod] = useState<
    Record<string, { amount: number; currency: string }>
  >({});
  const [averageCommission, setAverageCommission] = useState<number>(0);
  const [paymentsByStatusAndMethod, setPaymentsByStatusAndMethod] = useState<
    Record<string, { count: number; amount: number; currency: string }>
  >({});

  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [paymentSystems, setPaymentSystems] = useState<IPaymentSystem[]>([]);
  const [payments, setPayments] = useState<IPayment[]>([]);
  const { mutate, data: responseData } = useGetPaymentMethods();
  const { data } = useGetPayments("");

  const [getStat, setGetStat] = useState<boolean>(false);

  useEffect(() => {
    // debugger;
    if (responseData && responseData.methods && responseData.methods) {
      setPaymentMethods((prev: any) => [...prev, ...responseData.methods]);
    }

    if (responseData && responseData.systems && responseData.systems) {
      setPaymentSystems((prev: any) => [...prev, ...responseData.systems]);
    }
  }, [responseData]);

  useEffect(() => {
    if (data && payments.length === 0) {
      setPayments(data);
      setGetStat(true);
    }
  }, [data, payments.length]);

  useEffect(() => {
    mutate();
  }, []);

  const handleEdit = (id: number) => {
    console.log("Edit method with ID:", id);
    // Ваш код для редагування
  };

  const handleDelete = (id: number) => {
    console.log("Delete method with ID:", id);
    // Ваш код для видалення
  };
  useEffect(() => {
    // debugger;
    if (payments.length > 0) {
      // Загальна кількість транзакцій
      const newtotalTransactions = payments.length;

      // Загальна сума транзакцій
      const newtotalAmount = payments
        // .filter((pay) => pay.method_id?.active == true)
        .reduce((acc, payment) => {
          const key = `${payment?.method_id?.name}(${payment?.method_id?.method_id}): ${payment.currency.name}: ${payment.type}`;
          acc[key] = (acc[key] || 0) + 1;
          return acc;
        }, {} as Record<string, number>);

      // Сума за валютами та методами
      const newamountByCurrencyAndMethod = payments.reduce((acc, payment) => {
        const key = `${payment?.method_id?.name}(${payment?.method_id?.method_id}): ${payment.currency.name}: ${payment.type}`;
        if (!acc[key]) {
          acc[key] = { amount: 0, currency: "" };
        }

        acc[key].amount += parseFloat(payment.amount);
        acc[key].currency = payment.currency.name;
        return acc;
      }, {} as Record<string, { amount: number; currency: string }>);

      // Загальна комісія та середня комісія
      const newtotalCommission = payments.reduce(
        (acc, payment) => acc + parseFloat(payment.commission),
        0
      );
      const newaverageCommission = newtotalCommission / totalTransactions;

      // Статистика по статусу і методам
      const newpaymentsByStatusAndMethod = payments.reduce((acc, payment) => {
        const key = `${payment?.method_id?.name}(${payment?.method_id?.method_id}): ${payment.status}: ${payment.type}`;
        if (!acc[key]) {
          acc[key] = { count: 0, amount: 0, currency: "" };
        }
        acc[key].count += 1;
        acc[key].amount += parseFloat(payment.amount);
        acc[key].currency = payment.currency.name;
        return acc;
      }, {} as Record<string, { count: number; amount: number; currency: string }>);

      // Оновлення стану
      setTotalTransactions(newtotalTransactions);
      setTotalAmount(newtotalAmount);
      setAmountByCurrencyAndMethod(newamountByCurrencyAndMethod);
      setAverageCommission(newaverageCommission);
      setPaymentsByStatusAndMethod(newpaymentsByStatusAndMethod);
    }
  }, [getStat]);

  return (
    <Container
      sx={{
        padding: "10px",
      }}
      disableGutters
      maxWidth={false}
    >
      <Box
        sx={{
          backgroundColor: "#f0f2f5", // Приклад кольору фону
          borderRadius: "8px", // Приклад радіуса заокруглення
          padding: "16px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom>
              Systems
            </Typography>
            <PaymentSystemList paymentSystems={paymentSystems} />

            <Typography variant="h5" gutterBottom>
              Methods
            </Typography>
            <PaymentMethodsList
              paymentMethods={paymentMethods}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            {/* <Typography variant="h5" gutterBottom>
              Methods Statistics
            </Typography> */}
            <Box
              sx={{
                padding: "16px",
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="body1">
                Total Methods: {paymentMethods.length}
              </Typography>
              <Statistics
                totalTransactions={totalTransactions}
                totalAmount={totalAmount}
                amountByCurrencyAndMethod={amountByCurrencyAndMethod}
                averageCommission={averageCommission}
                paymentsByStatusAndMethod={paymentsByStatusAndMethod}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
