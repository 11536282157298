import React from "react";
import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { BORDER } from "../../consts/border";
import { COLORS } from "../../consts";
import { AppMenu } from "../../components/menu/menu";
import { HeaderStyled } from "../../components/header/header.styled";
interface IHeaderProps {
  id: string;
  name: string;
  reg_date: string;
  balances: {
    balance: string;
    active: boolean;
  }[];
}
export const Header = ({ id, name, balances, reg_date }: IHeaderProps) => {
  const date = new Date(+reg_date).toLocaleString();
  return (
    <HeaderStyled>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderBottom: BORDER.PRIMARY,
          backgroundColor: COLORS.PRIMARY_BG,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            padding: "0px 16px",
          }}
        >
          <AppMenu />
          <Typography
            variant="subtitle2"
            sx={{
              color: COLORS.DARKGREY,
              padding: "0 5px",
            }}
          >
            {id}
          </Typography>
          <Typography variant="subtitle2" color={COLORS.DARKGREY}>
            {name}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#f8f8f8",
              backgroundColor: COLORS.GREEN,
              padding: "0 16px",
            }}
          >
            Отмониторенный
          </Typography>
          {balances.map((bal, index) => {
            return (
              <Typography
                variant="subtitle2"
                color={bal.active ? COLORS.ORANGE : COLORS.GREY}
                key={"balance_" + index}
              >
                {bal.balance}
              </Typography>
            );
          })}
          {/* <Typography
            variant="subtitle2"
            color={rub_active ? COLORS.ORANGE : COLORS.GREY}
          >
            {rub_balance} RUB
          </Typography> */}
          {/* <Typography
            variant="subtitle2"
            color={eur_active ? COLORS.ORANGE : COLORS.GREY}
          >
            {eur_balance} EUR
          </Typography>
          <Typography
            variant="subtitle2"
            color={usd_active ? COLORS.ORANGE : COLORS.GREY}
          >
            {usd_balance} USD
          </Typography> */}
          <Typography
            variant="subtitle2"
            sx={{ color: COLORS.GREY, fontSize: "12px" }}
          >
            {date}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            padding: "0px 36px",
            backgroundColor: COLORS.SECONDARY_BG,
          }}
        >
          <NavLink to={"./info"} className="header__link">
            Общая информация
          </NavLink>
          <NavLink to={"./kyc"} className="header__link">
            KYC
          </NavLink>
          <NavLink to={"./financial"} className="header__link">
            Финансовая история
          </NavLink>
          <NavLink to={"./payments"} className="header__link">
            Платежная история
          </NavLink>
          <NavLink to={"./bonuses"} className="header__link">
            Бонусы
          </NavLink>
          <NavLink to={"./casino"} className="header__link">
            История казино
          </NavLink>
        </Box>
      </Box>
    </HeaderStyled>
  );
};
