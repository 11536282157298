import React, { useEffect, useState } from "react";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { InputB } from "../../../../components/input-b/inputB";
import { InputDate } from "../../../../components/inputdate/inputdate";
import { Box } from "@mui/material";
import { MyButton } from "../../../../components/button/button";
import { useFormik } from "formik";
import { InputSelect } from "../../../../components/inputSelect/input.select";
import { ICurrency } from "../../../../types/brand.type";

interface InitialValues {
  [key: string]: string | number | null | undefined;
  id: string;
  from: null | string;
  to: null | string;
  status?:
    | "pending"
    | "success"
    | "error"
    | "failed"
    | "rejected"
    | "approved"
    | "hold";
  type?: "refill" | "withdrawals";
  currencyId?: number;
}

const initialValues: InitialValues = {
  id: "",
  from: "",
  to: "",
  status: undefined,
  type: undefined,
  currencyId: undefined,
};

type IFinanceFilterProps = {
  playerId: string;
  page: number;
  currencies: ICurrency[];
  setObj: React.Dispatch<any>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPayments: React.Dispatch<React.SetStateAction<any[]>>;
};

export const PaymentFilter = ({
  playerId,
  page,
  currencies,
  setObj,
  setPage,
  setPayments,
}: IFinanceFilterProps) => {
  const [clearActive, setClearActive] = useState(true);
  const [submitActive, setSubmitActive] = useState(true);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      setClearActive(false);
      setSubmitActive(true);
      setPage(1);
      setPayments([]);
      setObj({
        playerId,
        page,
        ...formik.values,
      });
    },
  });
  const clearHandler = () => {
    setClearActive(true);
    formik.setValues(initialValues);
    setPayments([]);
    setObj({ playerId, page, ...initialValues });
  };
  const fromDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({ ...formik.values, from: date.valueOf().toString() });
      return;
    }
    formik.setValues({ ...formik.values, from: "" });
  };
  const toDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({ ...formik.values, to: date.valueOf().toString() });
      return;
    }
    formik.setValues({ ...formik.values, to: "" });
  };
  useEffect(() => {
    setObj({
      playerId,
      page,
      ...formik.values,
    });
  }, [page]);
  useEffect(() => {
    for (const key in formik.values) {
      if (formik.values[key] !== initialValues[key]) {
        setSubmitActive(false);
      }
    }
  }, [formik.values]);
  console.log(formik.values.currencyId);
  return (
    <Box
      sx={{
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        padding: "10px 16px",
        margin: "10px",
        width: "400px",
      }}
    >
      <InputB
        id={"id"}
        onChange={formik.handleChange}
        value={formik.values.id}
        label={"Transaction ID"}
      />
      <InputSelect
        label="Type"
        dataArray={[
          { value: undefined, text: "None" },
          { value: "withdrawals", text: "Withdrawal" },
          { value: "refill", text: "Deposit" },
        ]}
        selectFunction={(value) => {
          formik.setValues({ ...formik.values, type: value });
        }}
      />
      <InputSelect
        label="Status"
        dataArray={[
          { value: undefined, text: "None" },
          { value: "pending", text: "Pending" },
          { value: "success", text: "Success" },
          { value: "approved", text: "Approved" },
          { value: "error", text: "Error" },
          { value: "failed", text: "Failed" },
          { value: "rejected", text: "Rejected" },
          { value: "hold", text: "Hold" },
        ]}
        selectFunction={(value) => {
          formik.setValues({ ...formik.values, status: value });
        }}
      />
      <InputDate
        id="from"
        label="From date"
        disabled={false}
        value={
          formik.values.from === null
            ? ""
            : formik.values.from === ""
            ? formik.values.from
            : new Date(+formik.values.from)
        }
        onChange={fromDateHandler}
      />
      <InputDate
        id="to"
        label="To date"
        disabled={false}
        value={
          formik.values.to === null
            ? ""
            : formik.values.to === ""
            ? formik.values.to
            : new Date(+formik.values.to)
        }
        onChange={toDateHandler}
      />

      <InputSelect
        label={"Currencies"}
        dataArray={currencies.map((curr) => ({
          value: curr.id,
          text: curr.name,
        }))}
        selectFunction={(value: any) => {
          formik.setValues({
            ...formik.values,
            currencyId: +value,
          });
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <MyButton
          disabled={clearActive}
          text="Clear"
          cancel
          type="button"
          onClick={clearHandler}
        />
        <MyButton
          disabled={submitActive}
          text="Filter"
          type="button"
          onClick={formik.handleSubmit}
        />
      </Box>
    </Box>
  );
};
