import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputB } from "../../../../components/input-b/inputB";
import { MyButton } from "../../../../components/button/button";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { useFormik } from "formik";
import { InputSelect } from "../../../../components/inputSelect/input.select";

type IBonusFilterProps = {
  id: string;
  page: number;
  setObj: React.Dispatch<any>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setBonuses: React.Dispatch<React.SetStateAction<any[]>>;
};
interface InitialValues {
  [key: string]: string | number | boolean | null | undefined;
  promo_code: string;
  status?:
    | "active"
    | "deposit"
    | "use"
    | "wagering"
    | "wagered"
    | "fail"
    | "canceled"
    | "expired";
}

const initialValues: InitialValues = {
  promo_code: "",
  status: undefined,
};
export const BonusFilter = ({
  id,
  page,
  setObj,
  setPage,
  setBonuses,
}: IBonusFilterProps) => {
  const [clearActive, setClearActive] = useState(true);
  const [submitActive, setSubmitActive] = useState(true);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      setClearActive(false);
      setSubmitActive(true);
      setPage(1);
      setBonuses([]);
      setObj({
        id,
        page,
        ...formik.values,
      });
    },
  });

  const clearHandler = () => {
    setClearActive(true);
    formik.setValues(initialValues);
    setBonuses([]);
    setPage(1);
    setObj({ id, page, ...initialValues });
  };

  useEffect(() => {
    setObj({
      id,
      page,
      ...formik.values,
    });
  }, [page]);

  useEffect(() => {
    for (const key in formik.values) {
      if (formik.values[key] !== initialValues[key]) {
        setSubmitActive(false);
      }
    }
  }, [formik.values]);
  return (
    <Box
      sx={{
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        padding: "10px 16px",
        margin: "10px",
        width: "400px",
      }}
    >
      <InputB
        id={"promo_code"}
        onChange={formik.handleChange}
        value={formik.values.promo_code}
        label={"Promo code"}
      />
      <InputSelect
        label={"Status"}
        dataArray={[
          { value: undefined, text: "None" },
          { value: "active", text: "Active" },
          { value: "deposit", text: "Deposit" },
          { value: "use", text: "Use" },
          { value: "wagering", text: "Wagering" },
          { value: "wagered", text: "Wagered" },
          { value: "fail", text: "Fail" },
          { value: "canceled", text: "Canceled" },
          { value: "expired", text: "Expired" },
        ]}
        selectFunction={(value) => {
          formik.setValues({ ...formik.values, status: value });
        }}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <MyButton
          disabled={clearActive}
          text="Clear"
          cancel
          type="button"
          onClick={clearHandler}
        />
        <MyButton
          disabled={submitActive}
          text="Filter"
          type="button"
          onClick={formik.handleSubmit}
        />
      </Box>
    </Box>
  );
};
