import React, { useContext, useEffect } from "react";
import { EmployeeContext } from "../../hooks/auth/useAuth";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { employeeService } from "../../services/employee.service";
import { Loader } from "../../components/loader/loader";

export const Root = () => {
  const employee = useContext(EmployeeContext);
  const navigate = useNavigate();
  useEffect(() => {
    const authCheck = async () => {
      if (!employee.employee.auth) {
        try {
          const data = await employeeService.checkToken();
          employee.authorizate(data!?.data);
        } catch (error) {
          navigate("/login");
        }
      }
    };
    authCheck();
  }, [employee.employee.auth]);

  if (!employee.employee.auth && localStorage.getItem("token")) {
    return <Loader />;
  }
  if (!employee.employee.auth) {
    return <Navigate to={"/login"} />;
  }
  return <Outlet />;
};

export const RootPage = () => {
  return <Navigate to={"/search"} />;
};
