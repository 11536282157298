import React from "react";
import { Box, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { IUser } from "../../types/user.type";
import { useSearchUsers } from "../../hooks/search/useSearchUsers";
import { BORDERRADIUS, COLORS } from "../../consts";
import { getBalances } from "../../utils/GetBalance";
import { SearchItem } from "./searchItem/searchItem";

interface ISearchedListProps {
  query: string;
  list: IUser[];
  setList: React.Dispatch<React.SetStateAction<IUser[]>>;
}
export const SearchedList = ({ query, list, setList }: ISearchedListProps) => {
  const { data, isLoading, isSuccess } = useSearchUsers(query);
  if (data && isSuccess) {
    setList(data.users);
  }
  const { ref, inView } = useInView({ threshold: 1 });
  React.useEffect(() => {
    if (inView) {
      // console.log("fetch");
    }
  }, [inView]);
  return (
    <Box
      sx={{
        margin: "10px 5% ",
        padding: "10px 16px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          color: COLORS.DARKGREY,
          borderRadius: BORDERRADIUS.PRIMARY,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5px 16px",
            backgroundColor: COLORS.PRIMARY,
            color: COLORS.DARKGREY,
            marginBottom: "5px",
            borderRadius: BORDERRADIUS.PRIMARY,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ width: "81px", textAlign: "center" }}
          >
            ID
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{ width: "150px", textAlign: "center" }}
          >
            Имя Фамилия
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              width: "140px",
              textAlign: "center",
            }}
          >
            Статус
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              width: "150px",
              textAlign: "center",
            }}
          >
            Email
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: "120px", textAlign: "center" }}
          >
            Баланс
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: "120px", textAlign: "center" }}
          >
            Верификация
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ width: "132px", textAlign: "center" }}
          >
            Дата регистрации
          </Typography>
        </Box>
        {!isLoading &&
          list.map((item, index) => {
            const full_name = item.person_info
              ? `${item.person_info.name} ${item.person_info.surname}`
              : "";
            const active_main_balance = getBalances(item)[0] as string;
            const date = new Date(+item.registration_date).toLocaleString();
            const verification = item.verification
              ? "Верифицирован"
              : "Не верифицирован";
            if (index === list.length - 1) {
              return (
                <SearchItem
                  key={item.id}
                  id={item.id}
                  full_name={full_name}
                  email={item.contacts.email}
                  balance={active_main_balance}
                  verification={verification}
                  date={date}
                  inView={ref}
                />
              );
            }
            return (
              <SearchItem
                key={item.id}
                id={item.id}
                full_name={full_name}
                email={item.contacts.email}
                balance={active_main_balance}
                verification={verification}
                date={date}
              />
            );
          })}
        {isLoading && <Box>Loading ...</Box>}
      </Box>
    </Box>
  );
};
