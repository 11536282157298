import React from "react";
import { MyButton } from "../../../components/button/button";
import { Box } from "@mui/material";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { InputDate } from "../../../components/inputdate/inputdate";
import { InputSelect } from "../../../components/inputSelect/input.select";
import { useFormik } from "formik";
import { IBrands, ICurrency } from "../../../types/brand.type";
import { InputB } from "../../../components/input-b/inputB";
import { useGetBrands } from "../../../hooks/brand/useGetBrands";
import { makeUniq } from "../../../utils/makeUniqArray";

export interface IInitialValues {
  player_id: null | string;
  id: null | string;
  from: null | string;
  to: null | string;
  status: undefined | string;
  currency: undefined | ICurrency;
  brand_filter: undefined | IBrands;
}

const initialValues: IInitialValues = {
  player_id: "",
  id: "",
  from: "",
  to: "",
  status: undefined,
  currency: undefined,
  brand_filter: undefined,
};

type ICasinoFilterProps = {
  setFilter: React.Dispatch<React.SetStateAction<IInitialValues>>;
};

export const CasinoFilter = ({ setFilter }: ICasinoFilterProps) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setFilter(values);
    },
  });
  const { data: brands } = useGetBrands();
  const userIdHandler = (value: string) => {
    const regEx = /[A-Za-zА-яа-я]/;
    if (value !== null && !regEx.test(value)) {
      formik.setValues({ ...formik.values, player_id: value });
      return;
    }
    formik.setValues({ ...formik.values, player_id: "" });
  };
  const fromDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({ ...formik.values, from: date.valueOf().toString() });
      return;
    }
    formik.setValues({ ...formik.values, from: "" });
  };
  const toDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({
        ...formik.values,
        to: (
          new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
          ).valueOf() +
          24 * 60 * 60 * 1000 -
          1000
        ).toString(),
      });
      return;
    }
    formik.setValues({ ...formik.values, to: "" });
  };
  const clearHandler = () => {
    formik.setValues(initialValues);
    setFilter(initialValues);
  };
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "350px",
        height: "100%",
        borderRadius: BORDERRADIUS.PRIMARY,
        backgroundColor: COLORS.PRIMARY_BG,
        padding: "10px 16px",
      }}
    >
      <InputDate
        id={"from"}
        label={"From"}
        disabled={false}
        value={
          formik.values.from === null
            ? ""
            : formik.values.from === ""
            ? formik.values.from
            : new Date(+formik.values.from)
        }
        time
        onChange={fromDateHandler}
      />
      <InputDate
        id={"to"}
        label={"To"}
        disabled={false}
        value={
          formik.values.to === null
            ? ""
            : formik.values.to === ""
            ? formik.values.to
            : new Date(+formik.values.to)
        }
        time
        onChange={toDateHandler}
      />
      <InputB
        id={"player_id"}
        onChange={(e: React.ChangeEvent) => {
          //@ts-ignore
          userIdHandler(e.target.value);
        }}
        value={formik.values.player_id === null ? "" : formik.values.player_id}
        label={"Player ID"}
      />
      <Box sx={{ marginBottom: "10px" }}>
        <InputSelect
          label={"Status"}
          dataArray={[
            { value: "pending", text: "Pending" },
            { value: "rejected", text: "Rejected" },
            { value: "closed", text: "Closed" },
          ]}
          selectFunction={(value) => {
            formik.setValues({
              ...formik.values,
              status: value,
            });
          }}
        />
      </Box>
      {brands && (
        <>
          <Box sx={{ marginBottom: "10px" }}>
            <InputSelect
              label={"Brand"}
              dataArray={brands.map((brand) => ({
                value: brand.brand_name,
                text: brand.brand_name,
              }))}
              selectFunction={(value: any) => {
                formik.setValues({
                  ...formik.values,
                  brand_filter: value,
                });
              }}
            />
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <InputSelect
              label={"Currency"}
              dataArray={makeUniq(
                brands
                  .map((brand) => brand.currencies)
                  .flat(1)
                  .map((currency) => ({
                    value: currency.name,
                    text: currency.name,
                  })),
                "value"
              )}
              selectFunction={(value: any) => {
                formik.setValues({
                  ...formik.values,
                  currency: value,
                });
              }}
            />
          </Box>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <MyButton type="button" cancel text={"Clear"} onClick={clearHandler} />
        <MyButton text={"Filter"} onClick={formik.handleSubmit} />
      </Box>
    </Box>
  );
};
