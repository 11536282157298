import React from "react";
import { InputDateStyled } from "./inputdate.styled";
//@ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IInputDateProps {
  id: string;
  label: string;
  disabled: boolean;
  value: Date | string;
  onChange: (date: Date) => void;
  time?: boolean;
}
export const InputDate = ({
  id,
  label,
  disabled,
  value,
  onChange,
  time,
}: IInputDateProps) => {
  return (
    <InputDateStyled>
      <label htmlFor={id}>{label}</label>
      <DatePicker
        id={id}
        showTimeSelect={time}
        timeIntervals={15}
        selected={value}
        onChange={onChange}
        showYearDropdown
        dateFormatCalendar="MMMM"
        yearDropdownItemNumber={100}
        dateFormat="dd.MM.yyyy"
        scrollableYearDropdown
        disabled={disabled}
        autoComplete="off"
      />
    </InputDateStyled>
  );
};
