import { Box } from "@mui/material";
import { InputB } from "../../../../components/input-b/inputB";
import { InputSelect } from "../../../../components/inputSelect/input.select";
import { InputDate } from "../../../../components/inputdate/inputdate";
import { IFreespins } from "../../../../types/freespins.type";
import { IPercentage } from "../../../../types/perscentage.type";
import { IInitialValues } from "../typing/initialValues";
import { IBrands } from "../../../../types/brand.type";
import { FormikErrors } from "formik";

interface IMainFiledsProps {
  handler: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  values: IInitialValues;
  brands: IBrands[];
  FSArray: {
    value: IFreespins;
    text: string;
  }[];
  PRArray: {
    value: IPercentage;
    text: string;
  }[];
  setValues: (
    values: React.SetStateAction<IInitialValues>,
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<IInitialValues>>;
  startHandler: (date: Date) => void;
  endHandler: (date: Date) => void;
  setFS: (value: IFreespins) => void;
  setPR: (value: IPercentage) => void;
}

export const MainFields = ({
  brands,
  handler,
  values,
  FSArray,
  PRArray,
  setValues,
  startHandler,
  endHandler,
  setFS,
  setPR,
}: IMainFiledsProps) => {
  const minDepInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    min_dep_currency: { currency_name: string; min_dep: string }
  ) => {
    const reg = /^[0-9]*[.]?[0-9]+$/;
    const reg2 = /^[0-9]*[.]?$/;
    const value = e.target.value;
    if (
      reg.test(value) ||
      value === "" ||
      (value[value.length - 1] === "." && reg2.test(value))
    ) {
      setValues({
        ...values,
        min_dep: values.min_dep.map((min_dep) => {
          if (min_dep.currency_name === min_dep_currency.currency_name) {
            min_dep.min_dep = value;
            return min_dep;
          }
          return min_dep;
        }),
      });
    }
  };
  return (
    <Box sx={{ width: "100%", maxWidth: "350px", height: "100%" }}>
      <Box sx={{ marginBottom: "10px" }}>
        <InputSelect
          label="Brands"
          dataArray={brands.map((brand) => ({
            value: brand,
            text: brand.brand_name,
          }))}
          selectFunction={(value) => {
            setValues({ ...values, brand: value });
          }}
        />
      </Box>
      <InputB
        id="display_name"
        onChange={handler}
        value={values.display_name}
        label="Display Name"
      />
      <InputB
        id="description"
        onChange={handler}
        value={values.description}
        label="Description"
      />
      <Box sx={{ marginBottom: "10px" }}>
        <InputSelect
          label="Freespins"
          dataArray={[{ value: "none", text: "None" }, ...FSArray]}
          selectFunction={setFS}
        />
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <InputSelect
          label="Percentage"
          dataArray={[{ value: "none", text: "None" }, ...PRArray]}
          selectFunction={setPR}
        />
      </Box>
      {values.brand &&
        values.min_dep.map((min_dep, index) => {
          return (
            <InputB
              key={`mid_dep_${min_dep.currency_name}_${index}`}
              id={`mid_dep_${min_dep.currency_name}`}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                minDepInputHandler(event, min_dep);
              }}
              value={min_dep.min_dep}
              label={`Min Deposit for ${min_dep.currency_name}`}
            />
          );
        })}
      <InputDate
        id="start_date"
        label="Start Date"
        disabled={false}
        value={
          values.start_date === ""
            ? values.start_date
            : new Date(+values.start_date)
        }
        onChange={startHandler}
      />
      <InputDate
        id="end_date"
        label="End Date"
        disabled={false}
        value={
          values.end_date === "" ? values.end_date : new Date(+values.end_date)
        }
        onChange={endHandler}
      />
      <InputB
        id="duration"
        onChange={handler}
        value={values.duration}
        label="Duration"
      />
      <InputB
        id="wager_fs"
        onChange={handler}
        value={values.wager_fs}
        label="Wager for FS"
      />
      <InputB
        id="wager_pers"
        onChange={handler}
        value={values.wager_pers}
        label="Wager for PR"
      />
      <Box sx={{ marginBottom: "10px" }}>
        <InputSelect
          label="Sub Type"
          dataArray={[
            { value: "common", text: "Common" },
            { value: "chain", text: "Chain" },
          ]}
          selectFunction={(value) => {
            setValues({ ...values, sub_type: value });
          }}
        />
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <InputSelect
          label="Placement"
          dataArray={[
            { value: "none", text: "None" },
            { value: "banner", text: "Banner" },
          ]}
          selectFunction={(value) => {
            setValues({ ...values, place: value });
          }}
        />
      </Box>
    </Box>
  );
};
