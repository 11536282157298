import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { IBonusesListItem } from "./typing/BonusesListItem.interface";
import "./BonusesListItem.css";
import { BonusShortRule } from "./BonusShortRules/bonus.short.rule";
import { BonusItem } from "./BonusItem/bonus.item";

const BonusesListItem: React.FC<IBonusesListItem> = ({
  bg_img,
  sub_img,
  min_dep,
  wager_fs,
  wager_pers,
  fs,
  pers,
  sub_type,
  title,
  sub_title,
  promo_code,
  isMobile,
}) => {
  const [info, setInfo] = useState(false);
  const openInfo = () => {
    setInfo(true);
  };
  const closeInfo = () => {
    setInfo(false);
  };

  useEffect(() => {}, [bg_img, sub_img]);
  return (
    <React.Fragment>
      <Box
        className={
          isMobile ? "bonus-card__container mobile" : "bonus-card__container"
        }
      >
        <BonusItem
          bg_img={bg_img}
          sub_img={sub_img}
          sub_type={sub_type}
          promo_code={promo_code}
          title={title}
          sub_title={sub_title}
          openInfo={openInfo}
        />
        <BonusShortRule
          open={info}
          close={closeInfo}
          title={title}
          min_dep={min_dep}
          fs={fs}
          pers={pers}
          wager_fs={wager_fs}
          wager_pers={wager_pers}
        />
      </Box>
    </React.Fragment>
  );
};

export default BonusesListItem;
