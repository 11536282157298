import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import { MyButton } from "../button/button";

type IInputImage = {
  id: string;
  label: string;
  upload: (value: FileList | null) => void;
  buttonText?: string;
};

export const InputImage = ({ id, label, buttonText, upload }: IInputImage) => {
  const filePicker = useRef<HTMLInputElement>(null);
  const uploadHandler = () => {
    filePicker.current?.click();
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography sx={{ fontSize: "13px" }}>{label}</Typography>
      <input
        type="file"
        ref={filePicker}
        id={id}
        onChange={(e) => {
          upload(e.currentTarget.files);
        }}
        accept="image/*,.png,.jpeg,.jpg,.web,.gif"
        style={{
          width: 0,
          height: 0,
          visibility: "hidden",
          opacity: 0,
        }}
      />
      <MyButton text={buttonText || "Upload"} onClick={uploadHandler} />
    </Box>
  );
};
