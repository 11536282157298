import React, { useContext } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { COLORS } from "../../consts";
import { Link, useNavigate } from "react-router-dom";
import "./menu.css";
import { EmployeeContext } from "../../hooks/auth/useAuth";

export const AppMenu = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
  };
  const { logout } = useContext(EmployeeContext);
  return (
    <>
      <Button
        id="basic-button"
        onClick={handleClick}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="listbox"
        aria-expanded={open ? "true" : undefined}
        sx={{ color: COLORS.SECONDARY_BG }}
      >
        <MenuIcon sx={{ color: COLORS.GREY }} />
      </Button>
      <Menu
        id="basic-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          position: "fixed",
          top: 0,
          left: open ? "0" : "-100%",
          width: "250px",
          height: "100vh",
          backgroundColor: COLORS.GREY,
          transition: "all 0.4s ease-in-out",
        }}
        PopoverClasses={{ paper: "menu_popover" }}
      >
        <Link to="/">
          <Typography
            sx={{
              display: "block",
              position: "absolute",
              top: "-36px",
              left: "0",
              color: COLORS.SEMITRANPARENT,
              fontSize: "30px",
              textTransform: "capitalize",
              fontWeight: "bold",
              width: "100%",
              lineHeight: "36px",
              paddingLeft: "20px",
            }}
          >
            back
            <Typography
              sx={{
                display: "block",
                position: "absolute",
                top: "-1px",
                left: "70px",
                color: COLORS.GREEN,
                fontSize: "30px",
                textTransform: "capitalize",
                fontWeight: "bold",
                width: "100%",
                lineHeight: "36px",
                paddingLeft: "20px",
              }}
              component={"span"}
            >
              Office
            </Typography>
          </Typography>
        </Link>
        <MenuItem sx={{ padding: "0" }}>
          <Link
            to="/search"
            style={{
              padding: "6px 32px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            Search
          </Link>
        </MenuItem>
        <MenuItem sx={{ padding: "0" }}>
          <Link
            to="/bonuses"
            style={{
              padding: "6px 32px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            Bonuses
          </Link>
        </MenuItem>
        <MenuItem
          sx={{
            padding: "0",
          }}
        >
          <Link
            to="/payments"
            style={{
              padding: "6px 32px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            Payments
          </Link>
        </MenuItem>
        <MenuItem
          sx={{
            padding: "0",
          }}
        >
          <Link
            to="/casino"
            style={{
              padding: "6px 32px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            Game History
          </Link>
        </MenuItem>
        <MenuItem
          sx={{
            padding: "0",
          }}
        >
          <Link
            to="/access"
            style={{
              padding: "6px 32px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            Access
          </Link>
        </MenuItem>
        <MenuItem
          sx={{
            padding: "0",
          }}
        >
          <Link
            to="/lobby"
            style={{
              padding: "6px 32px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            Lobby
          </Link>
        </MenuItem>
        <MenuItem
          sx={{
            padding: "0",
          }}
        >
          <Link
            to="/brand"
            style={{
              padding: "6px 32px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            Brand Configurator
          </Link>
        </MenuItem>
        <MenuItem
          sx={{
            padding: "0",
          }}
        >
          <Link
            to="/statistics"
            style={{
              padding: "6px 32px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            Statistics
          </Link>
        </MenuItem>
        <MenuItem
          sx={{
            padding: "6px 32px",
            fontWeight: "500",
            letterSpacing: "1px",
          }}
          onClick={logout}
        >
          Quit
        </MenuItem>
      </Menu>
    </>
  );
};
