import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { bonusService } from "../../services/bonusService";
import { IPercentageExample } from "../../types/perscentage.type";

export const useCreatePR = (
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (value: IPercentageExample) => bonusService.createPR(value),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["percentage"] });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
