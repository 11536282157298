import { Alert, Box, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../../consts";

interface IAlertProps {
  message: string;
  closeHandler: () => void;
  error?: string;
}

export const MyAlert = ({ message, error, closeHandler }: IAlertProps) => {
  return (
    <Alert
      color="error"
      severity="error"
      variant="outlined"
      sx={{
        color: COLORS.RED,
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: COLORS.PRIMARY_BG,
        borderColor: COLORS.RED,
        width: "100%",
        maxWidth: "300px",
        position: "absolute",
        top: "20px",
        right: "30px",
        padding: "0px 16px",
      }}
      onClose={closeHandler}
    >
      <Box sx={{ padding: "0" }}>
        <Typography
          sx={{ color: COLORS.RED, fontWeight: 600, fontSize: "14px" }}
        >
          An {error} Error Occurred
        </Typography>
        <Typography sx={{ color: COLORS.RED, fontSize: "12px" }}>
          {message}
        </Typography>
      </Box>
    </Alert>
  );
};
