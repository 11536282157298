import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { brandService } from "../../services/brand.service";

export const useEditMirror = (
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (value: { [key: string]: boolean | string | number }) =>
      await brandService.editMirror(value),
    onSuccess: (data) => {
      client.invalidateQueries({ queryKey: ["mirrors"] });
      openHandler(data.message);
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
