import { useQuery } from "@tanstack/react-query";
import { casinoService } from "../../services/casino.service";

export const useGetGameHistory = (query?: string) => {
  return useQuery({
    queryFn: () => casinoService.getGameHistory(query),
    queryKey: query ? ["casino", query] : ["casino"],
    gcTime: 0,
    retry: 1,
  });
};
