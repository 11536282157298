import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { paymentsService } from "../../services/paymentsService";

export const useTakePendingPayments = (
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: () => paymentsService.takePendingPayments(),
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === "pendingCount" ||
          query.queryKey[0] === "pendingPayments",
      });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
