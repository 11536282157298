import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { brandService } from "../../services/brand.service";

export const useCreateBrand = (
  openHandler: (message: string, error?: string) => void,
  callback: () => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (value: {
      name: string;
      host: string;
      currencies: { name: string; default: boolean }[];
      mirror_hostname?: string;
    }) => await brandService.createBrand(value),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["brands"] });
      callback();
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
