import styled from "styled-components";
import { BORDERRADIUS, COLORS } from "../../consts";

type IMyInputProps = {
  svg: React.ReactNode | null;
};

export const MyInput = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  & svg {
    position: absolute;
    left: 10px;
    bottom: 4.5px;
  }
  & label {
    font-size: 12px;
  }
  & input {
    padding: ${(props: IMyInputProps) => {
      if (props.svg) {
        return "6px 16px 6px 44px";
      }
      return "6px 16px";
    }};
    font-size: 13px;
    border: none;
    border: 1px solid #f8f8f8;
    background-color: #f8f8f8;
    outline: none;
    border-radius: ${BORDERRADIUS.PRIMARY};
    transition: all 0.4s ease-in-out;
    &:disabled {
      background-color: ${COLORS.SECONDARY_BG};
      border: 1px solid ${COLORS.SECONDARY_BG};
    }
    &:focus {
      border: 1px solid ${COLORS.GREEN};
    }
  }
`;
