import { useQuery } from "@tanstack/react-query";
import { bonusService } from "../../services/bonusService";

export const useGetFreespins = () => {
  return useQuery({
    queryFn: () => bonusService.getFreespins(),
    queryKey: ["freespins"],
    gcTime: 0,
  });
};
