import styled from "styled-components";
import { BORDERRADIUS, COLORS } from "../../consts";

export const InputDateStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  & label {
    font-size: 12px;
  }
  & input {
    width: 100%;
    border: none;
    padding: 6px 16px;
    font-size: 13px;
    border-radius: ${BORDERRADIUS.PRIMARY};
    border: 1px solid #f8f8f8;
    outline: none;
    background-color: #f8f8f8;
    &:disabled {
      background-color: ${COLORS.SECONDARY_BG};
      border: 1px solid ${COLORS.SECONDARY_BG};
    }
    &:focus {
      border: 1px solid ${COLORS.GREEN};
    }
  }
  & .react-datepicker__header {
    border: none;
    background-color: ${COLORS.PRIMARY_BG};
  }
  & .react-datepicker__triangle::after {
    border-bottom-color: ${COLORS.PRIMARY_BG} !important;
  }
  &
    .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown {
    display: inline-block;
    margin-bottom: 10px;
  }
  &
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
    display: inline-block;
    font-size: 14px;
  }
  & .react-datepicker__day.react-datepicker__day--selected {
    background-color: ${COLORS.GREEN};
  }
  & .react-datepicker__navigation-icon--previous::before,
  .react-datepicker__navigation-icon--next::before {
    top: 14px;
  }
  & .react-datepicker__year-read-view--down-arrow {
    top: 4px;
  }
  & .react-datepicker__day--keyboard-selected {
    background-color: ${COLORS.GREEN + "4c"};
  }
`;
