import { useFormik } from "formik";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { COLORS } from "../../../../consts";
import { MyButton } from "../../../../components/button/button";
import { ModalTemplate } from "../../../../components/modal/modal.template";
import { InputB } from "../../../../components/input-b/inputB";
import { useAddComments } from "../../../../hooks/payments/useAddComment";

type IQueueHeaderProps = {
  count: number | undefined;
  selected: number;
  take: () => void;
  clear: () => void;
  alertHandler: (message: string, error?: string | undefined) => void;
};
export const QueueHeader = ({
  count,
  selected,
  take,
  clear,
  alertHandler,
}: IQueueHeaderProps) => {
  const [modal, setModal] = useState(false);
  const { mutate: addComment } = useAddComments(alertHandler);
  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    onSubmit: (values) => {
      addComment(values.comment);
      closeModal();
    },
  });
  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    formik.setValues({ comment: "" });
    setModal(false);
  };
  const addCard = () => {
    formik.setValues({ comment: `${formik.values.comment} {card}` });
  };
  const addAmount = () => {
    formik.setValues({ comment: `${formik.values.comment} {amount}` });
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: "35px",
        alignItems: "center",
        padding: "5px 0",
      }}
    >
      <Typography sx={{ display: "flex" }}>
        Count:
        <Typography
          sx={{
            fontWeight: 900,
            color: COLORS.DARKGREY,
            marginLeft: "5px",
          }}
          component={"span"}
        >
          {count}
        </Typography>
      </Typography>
      <MyButton text="Take" onClick={take} />
      <Typography sx={{ display: "flex" }}>
        Selected:
        <Typography
          sx={{
            fontWeight: 900,
            color: COLORS.DARKGREY,
            marginLeft: "5px",
          }}
          component={"span"}
        >
          {selected}
        </Typography>
      </Typography>
      <MyButton text="Clear" onClick={clear} />
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <MyButton text="Add comment" onClick={openModal} />
      </Box>
      <ModalTemplate
        open={modal}
        close={closeModal}
        confirm={formik.handleSubmit}
        heading="Add comment"
        confirmButton="Add"
        closeButton="Cancel"
      >
        <Box sx={{ width: "400px" }}>
          <Typography sx={{ color: COLORS.GREY, marginBottom: "20px" }}>
            Write text of new comment
          </Typography>
          <InputB
            id="comment"
            onChange={formik.handleChange}
            value={formik.values.comment}
            label={"Comment text"}
          />
          <Box sx={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
            <MyButton
              text="Card"
              onClick={addCard}
              sx={{
                padding: "2px 7px",
                borderRadius: "20px",
                textTransform: "lowercase",
                backgroundColor: COLORS.GREY,
                "&:hover": {
                  backgroundColor: COLORS.GREY,
                },
              }}
            />
            <MyButton
              text="Amount"
              type="submit"
              onClick={addAmount}
              sx={{
                padding: "2px 7px",
                borderRadius: "20px",
                textTransform: "lowercase",
                backgroundColor: COLORS.GREY,
                "&:hover": {
                  backgroundColor: COLORS.GREY,
                },
              }}
            />
          </Box>
        </Box>
      </ModalTemplate>
    </Box>
  );
};
