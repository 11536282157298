import React, { useContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Header } from "../../components/header/header";
import { useGetUserData } from "../../hooks/player/playerInfo/useGetUserData";
import { AlertContext } from "../../hooks/notification/useAlert";
import { getBalances } from "../../utils/GetBalance";

export const PlayerLayout = () => {
  const params = useParams();
  const { alertHandler } = useContext(AlertContext);

  if (!params.id) {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, isSuccess } = useGetUserData(params.id);
  if (isSuccess && data) {
    const [_, ...balances] = getBalances(data) as {
      balance: string;
      active: boolean;
    }[];

    return (
      <div>
        <Header
          id={data.id}
          name={
            data.person_info
              ? `${data.person_info.name} ${data.person_info.surname}`
              : ""
          }
          balances={balances}
          reg_date={data.registration_date}
        />
        <Outlet context={[data]} />
      </div>
    );
  }
  return null;
};
