import { Box } from "@mui/material";
import React from "react";
import { InputImage } from "../../../../../components/inputImage/inputImage";

interface IImageUploadProps {
  imagesHandler: (key: "bg_image" | "sub_image", file: File | null) => void;
}

export const ImageUpload = ({ imagesHandler }: IImageUploadProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
        maxWidth: "420px",
      }}
    >
      <InputImage
        id="sub_image"
        label="Upload sub image"
        upload={(value) => {
          imagesHandler("sub_image", value ? value[0] : null);
        }}
      />

      <InputImage
        id="bg_image"
        label="Upload backgroud image"
        upload={(value) => {
          imagesHandler("bg_image", value ? value[0] : null);
        }}
      />
    </Box>
  );
};
