import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BORDERRADIUS, COLORS } from "../../consts";
import { AppMenu } from "../../components/menu/menu";
import { useGetStatistics } from "../../hooks/statistics/useGetStatistic";
import { useNavigate } from "react-router-dom";
import { EmployeeContext } from "../../hooks/auth/useAuth";
import { AlertContext } from "../../hooks/notification/useAlert";
import {
  IInitialValues,
  StatsFilters,
} from "../../modules/statistics/filters/statsFilters";
import { queryCreator } from "../../utils/queryToObj";
import { AllStatistics } from "../../modules/statistics/statistics/statistics";
import { IFTD, IRegCount, ITotalAmount } from "../../types/statistics.type";
import { useGetBrands } from "../../hooks/brand/useGetBrands";

export const Statistics = () => {
  const navigator = useNavigate();
  const { logout } = useContext(EmployeeContext);
  const { alertHandler } = useContext(AlertContext);
  const [period, setPeriod] = useState(24 * 60 * 60 * 1000);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState<IInitialValues>({
    from: "",
    to: "",
    brand: undefined,
    currency: undefined,
  });
  const { data, error } = useGetStatistics(query);
  const { data: brands, error: error_brands } = useGetBrands();
  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
      if (error.message === "Unathorized") {
        logout();
        navigator("/");
      }
    }
    if (error_brands) {
      //@ts-ignore
      alertHandler(error_brands.message, error_brands.code);
      if (error_brands.message === "Unathorized") {
        logout();
        navigator("/");
      }
    }
  }, [error]);

  useEffect(() => {
    const queryString = queryCreator({
      ...filters,
      period,
    });
    setQuery(queryString);
  }, [period, filters]);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <AppMenu />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 36px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "calc(100% - 20px)",
            gap: "10px",
            margin: "10px",
            height: "calc(100% - 20px)",
          }}
        >
          {data ? (
            <AllStatistics
              total_amount={
                data.filter(
                  (stats) => stats.type === "total_amount"
                ) as ITotalAmount[]
              }
              ftd={data.filter((stats) => stats.type === "ftd") as IFTD[]}
              reg_count={
                data.filter(
                  (stats) => stats.type === "reg_count"
                ) as IRegCount[]
              }
            />
          ) : (
            <Box
              sx={{
                width: "calc(100% - 10px)",
                height: "100%",
                padding: "10px 10px",
                backgroundColor: COLORS.PRIMARY_BG,
                borderRadius: BORDERRADIUS.PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No stats were found
            </Box>
          )}
          <StatsFilters
            setFilter={setFilters}
            period={period}
            setPeriod={setPeriod}
            brands={brands ? brands : []}
          />
        </Box>
      </Box>
    </Box>
  );
};
