import { useQuery } from "@tanstack/react-query";
import { paymentsService } from "../../services/paymentsService";

export const useGetPaymentSystemBalance = () => {
  return useQuery({
    queryFn: () => paymentsService.getPaymentSystemBalance(),
    queryKey: ["balance"],
    staleTime: 0,
    gcTime: 0,
    retry: 1,
  });
};
