import { Box } from "@mui/material";
import React, { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeletePermissionFromRole } from "../../../hooks/access/useDeletePermissionFromRole";
import { SimpleModal } from "../../../components/modal/simple.modal";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { IRole } from "../../../types/role.type";

type IRoleComponentProps = {
  role: IRole;
  alertHandler: (message: string, error?: string | undefined) => void;
};

export const RoleComponent = ({ role, alertHandler }: IRoleComponentProps) => {
  const { mutate: deletePermission } = useDeletePermissionFromRole(
    alertHandler,
    role.id
  );
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const deleteCloseModal = () => {
    setDeleteConfirmModal(false);
  };
  const deleteOpenModal = () => {
    setDeleteConfirmModal(true);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: "100%",
        padding: "10px 16px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "5px 0",
          backgroundColor: COLORS.DARKGREY,
          borderRadius: BORDERRADIUS.PRIMARY,
          textAlign: "center",
          color: COLORS.LIGHTGREY,
          fontWeight: 900,
          fontSize: "16px",
          marginBottom: "10px",
        }}
      >
        {role.name} Role
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          overflowY: "auto",
          fontSize: "13px",
        }}
      >
        {role.permissions.map((permission) => {
          return (
            <Box key={permission.id}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: COLORS.GREY,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 10px",
                    color: COLORS.DARKGREY,
                    fontSize: "14px",
                  }}
                >
                  <DoneIcon sx={{ marginRight: "20px", color: COLORS.GREEN }} />
                  {permission.name}
                </Box>
                <DeleteIcon
                  sx={{ cursor: "pointer" }}
                  onClick={deleteOpenModal}
                />
              </Box>
              <SimpleModal
                open={deleteConfirmModal}
                close={deleteCloseModal}
                confirm={() => {
                  deletePermission({ role, permission });
                }}
                heading={"Confirm delete"}
                mainText={
                  "Please, confirm deletion of the permission from the role"
                }
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
