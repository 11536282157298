import { Box } from "@mui/material";
import React from "react";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { IFTD, IRegCount, ITotalAmount } from "../../../types/statistics.type";

type IAllStatisticsProps = {
  total_amount: ITotalAmount[];
  ftd: IFTD[];
  reg_count: IRegCount[];
};

export const AllStatistics = ({
  total_amount,
  ftd,
  reg_count,
}: IAllStatisticsProps) => {
  return (
    <Box
      sx={{
        width: "calc(100% - 10px)",
        overflowX: "auto",
        overflowY: "auto",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          padding: "10px 10px",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          marginBottom: "10px",
          height: "100%",
          maxHeight: "50%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: COLORS.PRIMARY,
            padding: "5px 16px",
            borderRadius: BORDERRADIUS.PRIMARY,
            fontSize: "13px",
            marginBottom: "10px",
            color: "black",
            gap: "10px",
            textAlign: "center",
          }}
        >
          <Box sx={{ width: "80px" }}>Brand</Box>
          <Box sx={{ width: "100px" }}>Deposits</Box>
          <Box sx={{ width: "100px" }}>Withdrawal</Box>
          <Box sx={{ width: "52px" }}>Currency</Box>
          <Box sx={{ width: "80px" }}>Start Date</Box>
          <Box sx={{ width: "80px" }}>End Date</Box>
        </Box>
        <Box sx={{ height: "calc(100% - 40px)", overflow: "auto" }}>
          {total_amount.map((amount) => {
            return amount.data.length !== 0 ? (
              amount.data.map((data) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      color: COLORS.DARKGREY,
                      textAlign: "center",
                      "&:hover": {
                        backgroundColor: COLORS.SECONDARY_BG,
                      },
                    }}
                    key={`amount__${data.brand_name}_${data.currency_name}_${amount.start_date}`}
                  >
                    <Box sx={{ width: "80px", marginLeft: "16px" }}>
                      {data.brand_name}
                    </Box>
                    <Box sx={{ width: "100px" }}>
                      {(+data.total_refills).toFixed(2)}
                    </Box>
                    <Box sx={{ width: "100px" }}>
                      {(+data.total_withdrawals).toFixed(2)}
                    </Box>
                    <Box sx={{ width: "52px" }}>{data.currency_name}</Box>
                    <Box
                      sx={{
                        maxWidth: "80px",
                        fontSize: "12px",
                        lineHeight: "12px",
                        textAlign: "center",
                      }}
                    >
                      {new Date(amount.start_date).toLocaleString()}
                    </Box>
                    <Box
                      sx={{
                        maxWidth: "80px",
                        fontSize: "12px",
                        lineHeight: "12px",
                        textAlign: "center",
                      }}
                    >
                      {new Date(amount.end_date).toLocaleString()}
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  color: COLORS.DARKGREY,
                  textAlign: "center",
                  "&:hover": {
                    backgroundColor: COLORS.SECONDARY_BG,
                  },
                }}
                key={`amount_currency_${amount.start_date}`}
              >
                <Box sx={{ width: "80px", marginLeft: "16px" }}>-</Box>
                <Box sx={{ width: "100px" }}>0.00</Box>
                <Box sx={{ width: "100px" }}>0.00</Box>
                <Box sx={{ width: "52px" }}>-</Box>
                <Box
                  sx={{
                    maxWidth: "80px",
                    fontSize: "12px",
                    lineHeight: "12px",
                    textAlign: "center",
                  }}
                >
                  {new Date(amount.start_date).toLocaleString()}
                </Box>
                <Box
                  sx={{
                    maxWidth: "80px",
                    fontSize: "12px",
                    lineHeight: "12px",
                    textAlign: "center",
                  }}
                >
                  {new Date(amount.end_date).toLocaleString()}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          padding: "10px 10px",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          marginBottom: "10px",
          height: "100%",
          maxHeight: "50%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: COLORS.PRIMARY,
            padding: "5px 16px",
            borderRadius: BORDERRADIUS.PRIMARY,
            fontSize: "13px",
            marginBottom: "10px",
            color: "black",
            gap: "10px",
            textAlign: "center",
          }}
        >
          <Box sx={{ width: "80px" }}>Brand</Box>
          <Box sx={{ width: "150px" }}>Registration count</Box>
          <Box sx={{ width: "80px" }}>Start Date</Box>
          <Box sx={{ width: "80px" }}>End Date</Box>
        </Box>
        <Box sx={{ height: "calc(100% - 40px)", overflow: "auto" }}>
          {reg_count.map((reg) => {
            return reg.data.length !== 0 ? (
              reg.data.map((data) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      color: COLORS.DARKGREY,
                      textAlign: "center",
                      "&:hover": {
                        backgroundColor: COLORS.SECONDARY_BG,
                      },
                    }}
                    key={`reg_${data.brandName}_${reg.start_date}`}
                  >
                    <Box sx={{ width: "80px", marginLeft: "16px" }}>
                      {data.brandName}
                    </Box>
                    <Box sx={{ width: "150px" }}>{data.registrationCount}</Box>
                    <Box
                      sx={{
                        maxWidth: "80px",
                        fontSize: "12px",
                        lineHeight: "12px",
                        textAlign: "center",
                      }}
                    >
                      {new Date(reg.start_date).toLocaleString()}
                    </Box>
                    <Box
                      sx={{
                        maxWidth: "80px",
                        fontSize: "12px",
                        lineHeight: "12px",
                        textAlign: "center",
                      }}
                    >
                      {new Date(reg.end_date).toLocaleString()}
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  color: COLORS.DARKGREY,
                  textAlign: "center",
                  "&:hover": {
                    backgroundColor: COLORS.SECONDARY_BG,
                  },
                }}
                key={`reg_brand_${reg.start_date}`}
              >
                <Box sx={{ width: "80px", marginLeft: "16px" }}>-</Box>
                <Box sx={{ width: "150px" }}>0</Box>
                <Box
                  sx={{
                    maxWidth: "80px",
                    fontSize: "12px",
                    lineHeight: "12px",
                    textAlign: "center",
                  }}
                >
                  {new Date(reg.start_date).toLocaleString()}
                </Box>
                <Box
                  sx={{
                    maxWidth: "80px",
                    fontSize: "12px",
                    lineHeight: "12px",
                    textAlign: "center",
                  }}
                >
                  {new Date(reg.end_date).toLocaleString()}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          padding: "10px 10px",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          marginBottom: "10px",
          height: "100%",
          maxHeight: "calc(50% - 30px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: COLORS.PRIMARY,
            padding: "5px 16px",
            borderRadius: BORDERRADIUS.PRIMARY,
            fontSize: "13px",
            marginBottom: "10px",
            color: "black",
            gap: "10px",
            textAlign: "center",
          }}
        >
          <Box sx={{ width: "80px" }}>Brand</Box>
          <Box sx={{ width: "100px" }}>FTD count</Box>
          <Box sx={{ width: "100px" }}>FTD Total</Box>
          <Box sx={{ width: "100px" }}>FTD AVG</Box>
          <Box sx={{ width: "52px" }}>Currency</Box>
          <Box sx={{ width: "80px" }}>Start Date</Box>
          <Box sx={{ width: "80px" }}>End Date</Box>
        </Box>
        <Box sx={{ height: "calc(100% - 40px)", overflow: "auto" }}>
          {ftd.map((ftd_item) => {
            return ftd_item.data.length !== 0 ? (
              ftd_item.data.map((data) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      color: COLORS.DARKGREY,
                      textAlign: "center",
                      "&:hover": {
                        backgroundColor: COLORS.SECONDARY_BG,
                      },
                    }}
                    key={`ftd_item_${data.brandName}_${data.currencyName}_${ftd_item.start_date}`}
                  >
                    <Box sx={{ width: "80px", marginRight: "16px" }}>
                      {data.brandName}
                    </Box>
                    <Box sx={{ width: "100px" }}>
                      {(+data.ftdCount).toFixed(2)}
                    </Box>
                    <Box sx={{ width: "100px" }}>
                      {(data.totalFtdAmount ? +data.totalFtdAmount : 0).toFixed(
                        2
                      )}
                    </Box>
                    <Box sx={{ width: "100px" }}>
                      {(data.averageFtdAmount
                        ? +data.averageFtdAmount
                        : 0
                      ).toFixed(2)}
                    </Box>
                    <Box sx={{ width: "52px" }}>{data.currencyName}</Box>
                    <Box
                      sx={{
                        maxWidth: "80px",
                        fontSize: "12px",
                        lineHeight: "12px",
                        textAlign: "center",
                      }}
                    >
                      {new Date(ftd_item.start_date).toLocaleString()}
                    </Box>
                    <Box
                      sx={{
                        maxWidth: "80px",
                        fontSize: "12px",
                        lineHeight: "12px",
                        textAlign: "center",
                      }}
                    >
                      {new Date(ftd_item.end_date).toLocaleString()}
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  color: COLORS.DARKGREY,
                  textAlign: "center",
                  "&:hover": {
                    backgroundColor: COLORS.SECONDARY_BG,
                  },
                }}
                key={`ftd_item_brand_${ftd_item.start_date}`}
              >
                <Box sx={{ width: "80px" }}>-</Box>
                <Box sx={{ width: "100px" }}>0</Box>
                <Box sx={{ width: "100px" }}>0.00</Box>
                <Box sx={{ width: "100px" }}>0.00</Box>
                <Box sx={{ width: "52px" }}>-</Box>
                <Box
                  sx={{
                    maxWidth: "80px",
                    fontSize: "12px",
                    lineHeight: "12px",
                    textAlign: "center",
                  }}
                >
                  {new Date(ftd_item.start_date).toLocaleString()}
                </Box>
                <Box
                  sx={{
                    maxWidth: "80px",
                    fontSize: "12px",
                    lineHeight: "12px",
                    textAlign: "center",
                  }}
                >
                  {new Date(ftd_item.end_date).toLocaleString()}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
