import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { InputDate } from "../../../../components/inputdate/inputdate";
import { InputB } from "../../../../components/input-b/inputB";
import { MyButton } from "../../../../components/button/button";
import { useFormik } from "formik";
import { queryCreator } from "../../../../utils/queryToObj";
import { InputSelect } from "../../../../components/inputSelect/input.select";

export interface InitialValues {
  [key: string]: string | number | null | undefined;
  from: null | string;
  to: null | string;
  id: null | string;
  user: null | string;
  status?:
    | "pending"
    | "success"
    | "error"
    | "failed"
    | "rejected"
    | "approved"
    | "hold";
  type?: "refill" | "withdrawals";
  currency?: "USD" | "RUB" | "EUR";
}

const initialValues: InitialValues = {
  from: "",
  to: "",
  user: "",
  id: "",
  status: undefined,
  type: undefined,
  currency: undefined,
};

interface IPaymentFilterProps {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setFilter: React.Dispatch<React.SetStateAction<InitialValues>>;
  setPayments: React.Dispatch<any>;
}
export const PaymentFilter = ({
  setPage,
  setFilter,
  setPayments,
}: IPaymentFilterProps) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      setPage(1);
      setPayments([]);
      setFilter(values);
    },
  });
  const clearHandler = () => {
    formik.setValues(initialValues);
    setPage(1);
    setPayments([]);
    setFilter(initialValues);
  };
  const fromDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({ ...formik.values, from: date.valueOf().toString() });
      return;
    }
    formik.setValues({ ...formik.values, from: "" });
  };
  const toDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({ ...formik.values, to: date.valueOf().toString() });
      return;
    }
    formik.setValues({ ...formik.values, to: "" });
  };
  const userIdHandler = (value: string) => {
    const regEx = /[A-Za-zА-яа-я]/;
    if (value !== null && !regEx.test(value)) {
      formik.setValues({ ...formik.values, user: value });
      return;
    }
    formik.setValues({ ...formik.values, user: "" });
  };
  return (
    <Box
      sx={{
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        width: "100%",
        maxWidth: "400px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <InputDate
          id="from"
          label="С"
          disabled={false}
          value={
            formik.values.from === null
              ? ""
              : formik.values.from === ""
              ? formik.values.from
              : new Date(+formik.values.from)
          }
          onChange={fromDateHandler}
        />
        <InputDate
          id="to"
          label="По"
          disabled={false}
          value={
            formik.values.to === null
              ? ""
              : formik.values.to === ""
              ? formik.values.to
              : new Date(+formik.values.to)
          }
          onChange={toDateHandler}
        />
        <InputB
          id="id"
          onChange={(e: any) => {
            formik.setValues({ ...formik.values, id: e.target.value });
          }}
          value={formik.values.id === null ? "" : formik.values.id}
          label="Номер транзакции"
        />
        <InputB
          id="account"
          onChange={(e: React.ChangeEvent) => {
            //@ts-ignore
            userIdHandler(e.target.value);
          }}
          value={formik.values.user === null ? "" : formik.values.user}
          label="Номер пользователя"
        />
        <Box sx={{ marginBottom: "10px" }}>
          <InputSelect
            label="Статус"
            dataArray={[
              { value: undefined, text: "None" },
              { value: "pending", text: "Pending" },
              { value: "approved", text: "Approved" },
              { value: "success", text: "Success" },
              { value: "error", text: "Error" },
              { value: "failed", text: "Failed" },
              { value: "rejected", text: "Rejected" },
              { value: "hold", text: "Hold" },
            ]}
            selectFunction={(value) => {
              formik.setValues({ ...formik.values, status: value });
            }}
          />
        </Box>
        <Box sx={{ marginBottom: "10px" }}>
          <InputSelect
            label="Тип операции"
            dataArray={[
              { value: undefined, text: "None" },
              { value: "refill", text: "Deposit" },
              { value: "withdrawals", text: "Withdrawal" },
            ]}
            selectFunction={(value) => {
              formik.setValues({ ...formik.values, type: value });
            }}
          />
        </Box>
        <InputSelect
          label="Валюта"
          dataArray={[
            { value: undefined, text: "None" },
            { value: "USD", text: "USD" },
            { value: "RUB", text: "RUB" },
            { value: "EUR", text: "EUR" },
          ]}
          selectFunction={(value) => {
            formik.setValues({ ...formik.values, currency: value });
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <MyButton type="button" cancel onClick={clearHandler} text="Clear" />
          <MyButton type="submit" text="Filter" onClick={formik.handleSubmit} />
          <MyButton
            type="submit"
            text="TEST"
            onClick={async () => {
              const a = await fetch("http://localhost:5000/api/bo/stats/");
              console.log(a);
            }}
            sx={{ color: "white", backgroundColor: "black" }}
          />
        </Box>
      </Box>
    </Box>
  );
};
