import { Box } from "@mui/material";
import { useContext, useState } from "react";
import { AppMenu } from "../../components/menu/menu";
import { COLORS } from "../../consts";

import { Portal } from "../../components/portal/portal";

import { useGetRoles } from "../../hooks/access/useGetRoles";
import { AlertContext } from "../../hooks/notification/useAlert";
import { AccessSearch } from "../../modules/access/search/search";
import { Employee } from "../../modules/access/employee/employee";
import { Role } from "../../modules/access/role/role";
import { useCreateRole } from "../../hooks/access/useCreateRole";
import { RoleModal } from "../../modules/access/role/role.modal";
import { EmployeeModal } from "../../modules/access/employee/employee.modal";
import { useCreateEmployee } from "../../hooks/access/useCreateEmployee";
import { useGetEmployee } from "../../hooks/access/useGetEmployee";
import { PermissionModal } from "../../modules/access/pemission/permission.modal";
import { useCreatPermission } from "../../hooks/access/useCreatePermission";

export const Access = () => {
  const { alertHandler } = useContext(AlertContext);
  const [openEmployee, setOpenEmployee] = useState(false);
  const [openRole, setOpenRole] = useState(false);
  const [openPermission, setOpenPermission] = useState(false);

  const roles = useGetRoles();
  const employees = useGetEmployee();
  const { mutate: createRole, isPending: roleLoading } =
    useCreateRole(alertHandler);
  const { mutate: createEmployee, isPending: emplLoading } =
    useCreateEmployee(alertHandler);
  const { mutate: createPermission, isPending: permissionLoading } =
    useCreatPermission(alertHandler);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <AppMenu />
      </Box>
      <Box
        sx={{
          margin: "10px",
          width: "calc(100% - 20px)",
          height: "calc(100vh - 54px)",
        }}
      >
        <AccessSearch
          openModal={() => {
            setOpenEmployee(true);
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            height: "calc(100% - 92px)",
            width: "100%",
            margin: "10px 0",
            gap: "10px",
          }}
        >
          <Employee
            employees={employees.data}
            isLoading={employees.isLoading}
          />
          <Role
            roles={roles.data ? roles.data : []}
            openRoleModal={() => {
              setOpenRole(true);
            }}
            openPermissionModal={() => {
              setOpenPermission(true);
            }}
          />
        </Box>
        {openEmployee && (
          <Portal>
            <EmployeeModal
              closeModal={() => {
                setOpenEmployee(false);
              }}
              submitHanler={createEmployee}
              isLoading={emplLoading}
              roles={roles.data ? roles.data : []}
            />
          </Portal>
        )}
        {openRole && (
          <Portal>
            <RoleModal
              closeModal={() => {
                setOpenRole(false);
              }}
              submitHanler={createRole}
              isLoading={roleLoading}
            />
          </Portal>
        )}
        {openPermission && (
          <Portal>
            <PermissionModal
              isLoading={permissionLoading}
              closeModal={() => {
                setOpenPermission(false);
              }}
              submitHanler={createPermission}
            />
          </Portal>
        )}
      </Box>
    </Box>
  );
};
