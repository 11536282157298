import { useQuery } from "@tanstack/react-query";
import { lobbyService } from "../../services/lobby.service";

export const useGetMySlots = (query: string) => {
  return useQuery({
    queryFn: () => lobbyService.getMySlots(query),
    queryKey: ["mySlots", query],
    staleTime: 0,
    gcTime: 0,
    retry: 1,
  });
};
