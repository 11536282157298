import React from "react";
import { InfoContainer } from "../../../components/infoContainer/info.container";
import { ProfileInfo } from "../../../modules/player/info/profileInfo/profileInfo";
import { Restrictions } from "../../../modules/player/info/resctictions/restrictions";
import { Contacts } from "../../../modules/player/info/contacts/contacts";
import { Comments } from "../../../modules/player/info/comments/comments";
import { OtherInfo } from "../../../modules/player/info/otherInfo/otherinfo";
import { Profit } from "../../../modules/player/info/profit/profit";

export const Info = () => {
  return (
    <InfoContainer>
      <ProfileInfo />
      <OtherInfo />
      <Restrictions />
      <Contacts />
      <Comments />
      <Profit />
    </InfoContainer>
  );
};
