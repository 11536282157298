import { useQuery } from "@tanstack/react-query";
import { brandService } from "../../services/brand.service";

export const useGetMirrors = (brand_id?: number) => {
  return useQuery({
    queryFn: () => brandService.getMirrors(brand_id),
    queryKey: brand_id ? ["mirrors", brand_id] : ["mirrors"],
    gcTime: 0,
    retry: 1,
  });
};
