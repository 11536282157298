import React from "react";
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import { InputB } from "../../../components/input-b/inputB";
import { MyButton } from "../../../components/button/button";
import { Loader } from "../../../components/loader/loader";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { IRole } from "../../../types/role.type";
import { UseMutateFunction } from "@tanstack/react-query";
import { IMessage } from "../../../types/message.type";
import { AlertError } from "../../../errors/AlertError";
import { InputSelect } from "../../../components/inputSelect/input.select";

type IEmployeeModalProps = {
  isLoading: boolean;
  roles: IRole[];
  closeModal: () => void;
  submitHanler: UseMutateFunction<
    IMessage | undefined,
    AlertError,
    {
      email: string;
      access?: string | undefined;
    },
    unknown
  >;
};

export const EmployeeModal = ({
  isLoading,
  roles,
  closeModal,
  submitHanler,
}: IEmployeeModalProps) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      access: "-",
    },
    onSubmit: (values) => {
      if (values.access === "-") {
        submitHanler({ email: values.email });
        return;
      }
      submitHanler(values);
    },
  });
  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: COLORS.SEMITRANPARENT,
      }}
      onClick={closeModal}
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          padding: "10px 35px",
          width: "450px",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Typography
          variant="h4"
          sx={{ marginBottom: "15px", color: COLORS.DARKGREY }}
        >
          Create new user
        </Typography>
        <InputB
          id="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          label="Email"
        />
        <InputSelect
          label="Access profile"
          dataArray={[
            { value: undefined, text: "-" },
            ...roles.map((role) => {
              return { value: role.id, text: role.name };
            }),
          ]}
          selectFunction={(value) => {
            formik.setValues({ ...formik.values, access: value });
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 0",
          }}
        >
          <MyButton
            type="button"
            text="Create"
            disabled={false}
            onClick={formik.handleSubmit}
          />
        </Box>
        {isLoading && <Loader />}
      </Box>
    </Box>
  );
};
