import React from "react";
import { createReactEditorJS } from "react-editor-js";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Link from "@editorjs/link";
import Underline from "@editorjs/underline";
import { Box, Typography } from "@mui/material";
import EditorJS, { OutputData } from "@editorjs/editorjs";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { MyButton } from "../../../../components/button/button";
import { FormikErrors } from "formik";

const EDITOR_JS_TOOLS = {
  header: Header,
  list: List,
  underline: Underline,
  linkTool: Link,
};

const ReactEditorJS = createReactEditorJS();

type IEditorComponentProps = {
  onSave: (values: {
    emailTitle: string;
    emailDescription: string;
    emailFooter: string;
    emailSubject: string;
  }) => void;
  values: {
    emailTitle: string;
    emailDescription: string;
    emailFooter: string;
    emailSubject: string;
  };
};

export const EditorComponent = ({ onSave, values }: IEditorComponentProps) => {
  const editorCoreDescription = React.useRef<EditorJS | null>(null);
  const editorCoreFooter = React.useRef<EditorJS | null>(null);

  const handleInitializeDescription = React.useCallback((instance: any) => {
    editorCoreDescription.current = instance;
  }, []);
  const handleInitializeFooter = React.useCallback((instance: any) => {
    editorCoreFooter.current = instance;
  }, []);
  const handleSave = React.useCallback(async () => {
    const outputDataDescription = await editorCoreDescription.current?.save();
    const outputDataFooter = await editorCoreFooter.current?.save();
    const convertedDataDescription = convertDataToHtml(outputDataDescription);
    const convertedDataFooter = convertDataToHtml(outputDataFooter);
    if (!convertedDataDescription || !convertedDataFooter) {
      return;
    }
    onSave({
      emailSubject: values.emailSubject,
      emailTitle: values.emailTitle,
      emailDescription: convertedDataDescription,
      emailFooter: convertedDataFooter,
    });
  }, [values.emailSubject, values.emailTitle]);
  const handleClear = React.useCallback(() => {
    editorCoreDescription.current?.clear();
    editorCoreFooter.current?.clear();
  }, []);

  return (
    <>
      <Typography sx={{ fontSize: "13px" }}>Email Text</Typography>
      <Box
        sx={{
          maxHeight: "457px",
          borderRadius: BORDERRADIUS.PRIMARY,
          backgroundColor: "#fff",
          overflowY: "auto",
          marginBottom: "10px",
          color: COLORS.DARKGREY,
        }}
      >
        <ReactEditorJS
          tools={EDITOR_JS_TOOLS}
          holder="editor_js_description"
          defaultValue={{
            time: Date.now(),
            blocks: [
              {
                type: "paragraph",
                data: {
                  text: "Email Description",
                },
              },
            ],
          }}
          inlineToolbar={["link", "bold", "italic", "underline"]}
          onInitialize={handleInitializeDescription}
        >
          <div id="editor_js_description"></div>
        </ReactEditorJS>
        <ReactEditorJS
          tools={EDITOR_JS_TOOLS}
          holder="editor_js_footer"
          defaultValue={{
            time: Date.now(),
            blocks: [
              {
                type: "paragraph",
                data: {
                  text: "Email Footer",
                },
              },
            ],
          }}
          inlineToolbar={["link", "bold", "italic", "underline"]}
          onInitialize={handleInitializeFooter}
        >
          <div id="editor_js_footer"></div>
        </ReactEditorJS>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          height: "auto",
          marginBottom: "30px",
        }}
      >
        <MyButton text="Clear" type="button" onClick={handleClear} cancel />
        <MyButton
          text="Save"
          onClick={handleSave}
          sx={{ marginLeft: "10px", width: "auto" }}
        />
      </Box>
    </>
  );
};

function convertDataToHtml(data: OutputData | undefined) {
  if (!data) {
    return;
  }
  let convertedHtml = "";
  data.blocks.forEach((block) => {
    switch (block.type) {
      case "header":
        convertedHtml += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
        break;
      case "embded":
        convertedHtml += `<div><iframe width="560" height="315" src="${block.data.embed}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>`;
        break;
      case "paragraph":
        convertedHtml += `<p>${block.data.text}</p>`;
        break;
      case "delimiter":
        convertedHtml += "<hr />";
        break;
      case "image":
        convertedHtml += `<img class="img-fluid" src="${block.data.file.url}" title="${block.data.caption}" /><br /><em>${block.data.caption}</em>`;
        break;
      case "list":
        convertedHtml += "<ul>";
        block.data.items.forEach(function (li: any) {
          convertedHtml += `<li>${li}</li>`;
        });
        convertedHtml += "</ul>";
        break;
      default:
        console.log("Unknown block type", block.type);
        break;
    }
  });
  return convertedHtml;
}
