import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { BORDERRADIUS, COLORS, SHADOW } from "../../../consts";
import { MyButton } from "../../../components/button/button";
import { IProvider, ISlot } from "../../../types/lobby.type";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import ComputerIcon from "@mui/icons-material/Computer";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useGetMySlots } from "../../../hooks/lobby/useGetMySlots";
import { InputB } from "../../../components/input-b/inputB";
import { useFormik } from "formik";
import { AlertContext } from "../../../hooks/notification/useAlert";
import { useChangeFilter } from "../../../hooks/lobby/useChangeFilter";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { useChangeCounter } from "../../../hooks/lobby/useChangeCounter";
import { useNavigate } from "react-router-dom";
import { EmployeeContext } from "../../../hooks/auth/useAuth";
import { useChangeMinBet } from "../../../hooks/lobby/useChangeMinBet";
import { useChangeReview } from "../../../hooks/lobby/useChangeReview";
import { makeUniq } from "../../../utils/makeUniqArray";
import { InputSelect } from "../../../components/inputSelect/input.select";
import { queryCreator } from "../../../utils/queryToObj";

export const MySlots = () => {
  const navigator = useNavigate();
  const { logout } = useContext(EmployeeContext);
  const { alertHandler } = useContext(AlertContext);
  const [query, setQuery] = useState<string>("");
  const [order, setOrder] = useState<{
    [key: string]: "ASC" | "DESC";
  }>({
    id: "DESC",
  });
  const [filter, setFilter] = useState<{
    search: string;
    provider: IProvider | undefined;
  }>({ search: "", provider: undefined });
  const [provider, setProvider] = useState<IProvider[]>([]);
  const searchHandler = (e: React.ChangeEvent<any>) => {
    setFilter({ ...filter, search: e.target.value });
  };
  const providerHandler = (value: IProvider) => {
    setFilter({ ...filter, provider: value });
  };
  const orderHandler = (key: string) => {
    if (order[key] === "DESC") {
      setOrder({ [key]: "ASC" });
      return;
    }
    setOrder({ [key]: "DESC" });
  };
  const clearAllHandler = () => {
    setOrder({ id: "DESC" });
    setFilter({ search: "", provider: undefined });
  };
  const { data, error } = useGetMySlots(query);
  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
      if (error.message === "Unathorized") {
        logout();
        navigator("/");
      }
    }
  }, [error]);
  useEffect(() => {
    if (data) {
      setProvider(
        makeUniq<IProvider>(
          data.map((slot) => {
            return { ...slot.provider };
          }),
          "id"
        )
      );
    }
  }, [data]);
  useEffect(() => {
    const queryString = queryCreator({
      ...order,
      ...filter,
      provider: filter.provider?.id,
    });
    setQuery(queryString);
  }, [order, filter.search, filter.provider]);
  return (
    <Box
      sx={{
        display: "flex",
        height: "calc(100% - 10px)",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          padding: "10px 16px",
          display: "flex",
          alignItems: "flex-end",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            maxWidth: "300px",
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
            width: "100%",
          }}
        >
          <InputB
            id={"game_name"}
            onChange={searchHandler}
            value={filter.search}
            label={"Game name"}
          />
        </Box>
        <Box sx={{ maxWidth: "300px", width: "100%", marginBottom: "10px" }}>
          <InputSelect
            label={"Provider"}
            dataArray={[
              ...provider.map((item) => {
                return { text: item.name, value: item };
              }),
            ]}
            selectFunction={providerHandler}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <MyButton
            cancel
            text="Clear"
            sx={{ marginBottom: "10px", height: "auto" }}
            onClick={clearAllHandler}
          />
          <MyButton
            text="Search"
            sx={{ marginBottom: "10px", height: "auto" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          flex: "1 1 auto",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          padding: "10px 16px",
          height: "calc(100% - 81px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: COLORS.GREY,
            width: "100%",
            padding: "6px 16px",
            fontSize: "13px",
            color: COLORS.DARKGREY,
            borderRadius: BORDERRADIUS.PRIMARY,
            textAlign: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer", width: "60px" }}
            onClick={() => {
              orderHandler("id");
            }}
          >
            ID
          </Box>
          <Box sx={{ width: "110px" }}>Image</Box>
          <Box sx={{ width: "120px" }}>Title</Box>
          <Box
            sx={{ cursor: "pointer", width: "60px" }}
            onClick={() => {
              orderHandler("internal_id");
            }}
          >
            Internal ID
          </Box>
          <Box sx={{ width: "24px" }}>FS</Box>
          <Box
            sx={{ cursor: "pointer", width: "60px" }}
            onClick={() => {
              orderHandler("lines");
            }}
          >
            Lines
          </Box>
          <Box sx={{ width: "55.14px" }}>Reviewed</Box>
          <Box sx={{ width: "55px" }}>Platform</Box>
          <Box
            sx={{ cursor: "pointer", width: "214px" }}
            onClick={() => {
              orderHandler("min_bet");
            }}
          >
            Min bet
          </Box>
          <Box sx={{ width: "110px" }}>Provider</Box>
          <Box sx={{ width: "300px" }}>Filters</Box>
          <Box
            sx={{ cursor: "pointer", width: "214px" }}
            onClick={() => {
              orderHandler("pop_counter");
            }}
          >
            Pop counter
          </Box>
        </Box>
        <Box sx={{ height: "calc(100% - 32px)", overflow: "auto" }}>
          {data &&
            data.map((slot) => {
              return <MySlotItem slot={slot} key={slot.id} />;
            })}
        </Box>
      </Box>
    </Box>
  );
};

type IMySlotItemProps = {
  slot: ISlot;
};

export const MySlotItem = ({ slot }: IMySlotItemProps) => {
  const { alertHandler } = useContext(AlertContext);
  const pop_counter = useFormik({
    initialValues: {
      pop_counter: slot.pop_counter,
    },
    onSubmit: (values) => {
      if (+values.pop_counter !== slot.pop_counter) {
        changeCounter({ id: slot.id, counter: +values.pop_counter });
      }
    },
  });
  const min_bet = useFormik({
    initialValues: {
      min_bet: slot.min_bet,
    },
    onSubmit: (values) => {
      if (+values.min_bet !== slot.min_bet) {
        changeMinBet({ id: slot.id, min_bet: +values.min_bet });
      }
    },
  });
  const { mutate: changeFilter } = useChangeFilter(alertHandler);
  const { mutate: changeCounter, isPending: isPopLoading } =
    useChangeCounter(alertHandler);
  const { mutate: changeMinBet, isPending: isMinLoading } =
    useChangeMinBet(alertHandler);
  const { mutate: changeReview, isPending: isReviewLoading } =
    useChangeReview(alertHandler);
  const cancelPopHandler = () => {
    pop_counter.setValues({ pop_counter: slot.pop_counter });
  };
  const cancelMinHandler = () => {
    min_bet.setValues({ min_bet: slot.min_bet });
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "3px 16px",
      }}
    >
      <Box sx={{ width: "60px", textAlign: "center" }}>{slot.id}</Box>
      <Box sx={{ width: "110px", height: "70px" }}>
        <img
          src={`${process.env.REACT_APP_SERVER_URL_BO}/api/bo/slot/image?image_name=${slot.image_url}`}
          alt={slot.image_url}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: BORDERRADIUS.PRIMARY,
          }}
        />
      </Box>
      <Box sx={{ width: "120px", textAlign: "center" }}>{slot.title}</Box>
      <Box sx={{ width: "60px", textAlign: "center" }}>{slot.internal_id}</Box>
      <Box
        sx={{
          width: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {slot.is_fs ? <CardGiftcardIcon /> : <CancelIcon />}
      </Box>
      <Box sx={{ width: "60px", textAlign: "center" }}>{slot.lines}</Box>
      <Box sx={{ width: "55.14px", textAlign: "center" }}>
        {slot.reviewed ? (
          <CheckCircleOutlineIcon
            sx={{
              "@keyframes rotate1": {
                from: {
                  transform: "rotate(0deg)",
                },
                to: {
                  transform: "rotate(180deg)",
                },
              },
              animation: isReviewLoading
                ? "rotate1 0.6s linear infinite"
                : "none",
              opacity: isReviewLoading ? 0.7 : 1,
              color: COLORS.GREEN,
              cursor: "pointer",
            }}
            onClick={() => {
              if (isReviewLoading) {
                return;
              }
              changeReview({ id: slot.id, reviewed: !slot.reviewed });
            }}
          />
        ) : (
          <CancelIcon
            sx={{
              "@keyframes rotate1": {
                from: {
                  transform: "rotate(0deg)",
                },
                to: {
                  transform: "rotate(180deg)",
                },
              },
              animation: isReviewLoading
                ? "rotate1 0.6s linear infinite"
                : "none",
              opacity: isReviewLoading ? 0.7 : 1,
              color: COLORS.RED,
              cursor: "pointer",
            }}
            onClick={() => {
              if (isReviewLoading) {
                return;
              }
              changeReview({ id: slot.id, reviewed: !slot.reviewed });
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          width: "55px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {slot.is_mobile && slot.is_pc ? (
          <>
            <MobileFriendlyIcon />
            <ComputerIcon sx={{ fontSize: "28px" }} />
          </>
        ) : slot.is_pc ? (
          <ComputerIcon />
        ) : (
          <MobileFriendlyIcon />
        )}
      </Box>
      <Box sx={{ padding: "0 16px", position: "relative", width: "214px" }}>
        <InputB
          id={"min_bet"}
          onChange={min_bet.handleChange}
          value={min_bet.values.min_bet}
          label={""}
        />
        {+min_bet.values.min_bet !== +slot.min_bet && (
          <Box
            sx={{
              position: "absolute",
              top: "5px",
              right: "20px",
              zIndex: 2,
            }}
          >
            <CloseIcon
              sx={{
                color: COLORS.RED,
                cursor: "pointer",
                borderRadius: BORDERRADIUS.PRIMARY,
                ":hover": {
                  backgroundColor: COLORS.PRIMARY_BG,
                },
              }}
              onClick={cancelMinHandler}
            />
            <DoneIcon
              sx={{
                color: COLORS.GREEN,
                cursor: "pointer",
                borderRadius: BORDERRADIUS.PRIMARY,
                ":hover": {
                  backgroundColor: COLORS.PRIMARY_BG,
                },
              }}
              onClick={() => {
                min_bet.handleSubmit();
              }}
            />
          </Box>
        )}
        {isMinLoading && (
          <Box
            sx={{
              position: "absolute",
              width: "calc(100% - 32px)",
              height: "calc(100% - 10px)",
              margin: "0 16px 10px",
              borderRadius: BORDERRADIUS.PRIMARY,
              top: "0px",
              left: "0px",
              backgroundColor: "#ffffffb5",
              zIndex: 10,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          ></Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "110px",
        }}
      >
        <img
          src={`${process.env.REACT_APP_SERVER_URL_BO}/api/bo/provider/image?image_name=${slot.provider.image}`}
          alt={slot.provider.image}
        />
        <Box sx={{ fontSize: "12px", textAlign: "center" }}>
          {slot.provider.name}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "6px",
          maxWidth: "300px",
        }}
      >
        <Box
          sx={{
            fontSize: "12px",
            padding: "0 8px",
            borderRadius: BORDERRADIUS.PRIMARY,
            color: "#ffffff",
            cursor: "pointer",
            backgroundColor: slot.popular ? COLORS.GREEN : "#d4d4d4",
            transition: "all 0.2s ease-out",
            ":hover": {
              boxShadow: SHADOW.PRIMARY,
            },
          }}
          onClick={() => {
            changeFilter({ id: slot.id, key: "popular" });
          }}
        >
          Popular
        </Box>
        <Box
          sx={{
            fontSize: "12px",
            padding: "0 8px",
            borderRadius: BORDERRADIUS.PRIMARY,
            color: "#ffffff",
            cursor: "pointer",
            backgroundColor: slot.recommended ? COLORS.GREEN : "#d4d4d4",
            transition: "all 0.2s ease-out",
            ":hover": {
              boxShadow: SHADOW.PRIMARY,
            },
          }}
          onClick={() => {
            changeFilter({ id: slot.id, key: "recommended" });
          }}
        >
          Recommended
        </Box>
        <Box
          sx={{
            fontSize: "12px",
            padding: "0 8px",
            borderRadius: BORDERRADIUS.PRIMARY,
            color: "#ffffff",
            cursor: "pointer",
            backgroundColor: slot.new ? COLORS.GREEN : "#d4d4d4",
            transition: "all 0.2s ease-out",
            ":hover": {
              boxShadow: SHADOW.PRIMARY,
            },
          }}
          onClick={() => {
            changeFilter({ id: slot.id, key: "new" });
          }}
        >
          New
        </Box>
        <Box
          sx={{
            fontSize: "12px",
            padding: "0 8px",
            borderRadius: BORDERRADIUS.PRIMARY,
            color: "#ffffff",
            cursor: "pointer",
            backgroundColor: slot.books ? COLORS.GREEN : "#d4d4d4",
            transition: "all 0.2s ease-out",
            ":hover": {
              boxShadow: SHADOW.PRIMARY,
            },
          }}
          onClick={() => {
            changeFilter({ id: slot.id, key: "books" });
          }}
        >
          Books
        </Box>
        <Box
          sx={{
            fontSize: "12px",
            padding: "0 8px",
            borderRadius: BORDERRADIUS.PRIMARY,
            color: "#ffffff",
            cursor: "pointer",
            backgroundColor: slot.animals ? COLORS.GREEN : "#d4d4d4",
            transition: "all 0.2s ease-out",
            ":hover": {
              boxShadow: SHADOW.PRIMARY,
            },
          }}
          onClick={() => {
            changeFilter({ id: slot.id, key: "animals" });
          }}
        >
          Animals
        </Box>
        <Box
          sx={{
            fontSize: "12px",
            padding: "0 8px",
            borderRadius: BORDERRADIUS.PRIMARY,
            color: "#ffffff",
            cursor: "pointer",
            backgroundColor: slot.seven ? COLORS.GREEN : "#d4d4d4",
            transition: "all 0.2s ease-out",
            ":hover": {
              boxShadow: SHADOW.PRIMARY,
            },
          }}
          onClick={() => {
            changeFilter({ id: slot.id, key: "seven" });
          }}
        >
          777
        </Box>
        <Box
          sx={{
            fontSize: "12px",
            padding: "0 8px",
            borderRadius: BORDERRADIUS.PRIMARY,
            color: "#ffffff",
            cursor: "pointer",
            backgroundColor: slot.buy_fs ? COLORS.GREEN : "#d4d4d4",
            transition: "all 0.2s ease-out",
            ":hover": {
              boxShadow: SHADOW.PRIMARY,
            },
          }}
          onClick={() => {
            changeFilter({ id: slot.id, key: "buy_fs" });
          }}
        >
          Buy FS
        </Box>
        <Box
          sx={{
            fontSize: "12px",
            padding: "0 8px",
            borderRadius: BORDERRADIUS.PRIMARY,
            color: "#ffffff",
            cursor: "pointer",
            backgroundColor: slot.fruits ? COLORS.GREEN : "#d4d4d4",
            transition: "all 0.2s ease-out",
            ":hover": {
              boxShadow: SHADOW.PRIMARY,
            },
          }}
          onClick={() => {
            changeFilter({ id: slot.id, key: "fruits" });
          }}
        >
          Fruits
        </Box>
      </Box>
      <Box
        sx={{
          padding: "0 16px",
          position: "relative",
          width: "214px",
        }}
      >
        <InputB
          id={"pop_counter"}
          onChange={pop_counter.handleChange}
          value={pop_counter.values.pop_counter}
          label={""}
        />
        {+pop_counter.values.pop_counter !== slot.pop_counter && (
          <Box
            sx={{
              position: "absolute",
              top: "5px",
              right: "20px",
              zIndex: 2,
            }}
          >
            <CloseIcon
              sx={{
                color: COLORS.RED,
                cursor: "pointer",
                borderRadius: BORDERRADIUS.PRIMARY,
                ":hover": {
                  backgroundColor: COLORS.PRIMARY_BG,
                },
              }}
              onClick={cancelPopHandler}
            />
            <DoneIcon
              sx={{
                color: COLORS.GREEN,
                cursor: "pointer",
                borderRadius: BORDERRADIUS.PRIMARY,
                ":hover": {
                  backgroundColor: COLORS.PRIMARY_BG,
                },
              }}
              onClick={() => {
                pop_counter.handleSubmit();
              }}
            />
          </Box>
        )}
        {isPopLoading && (
          <Box
            sx={{
              position: "absolute",
              width: "calc(100% - 32px)",
              height: "calc(100% - 10px)",
              margin: "0 16px 10px",
              borderRadius: BORDERRADIUS.PRIMARY,
              top: "0px",
              left: "0px",
              backgroundColor: "#ffffffb5",
              zIndex: 10,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          ></Box>
        )}
      </Box>
    </Box>
  );
};
