import { AxiosResponse } from "axios";
import { HttpService, httpService } from "./httpService";
import { IBrands, IMirrors } from "../types/brand.type";
import { IMessage } from "../types/message.type";

class BrandService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  async createBrand(data: {
    name: string;
    host: string;
    currencies: {
      name: string;
      default: boolean;
    }[];
    mirror_hostname?: string;
  }) {
    const response = await this.httpService.post<AxiosResponse<IMessage>>(
      {
        url: "/api/bo/brand/",
        data,
      },
      true
    );
    return response.data;
  }

  async getBrands(id?: number) {
    const response = await this.httpService.get<AxiosResponse<IBrands[]>>(
      {
        url: `/api/bo/brand/${id ? "?id=" + id : ""}`,
      },
      true
    );
    return response.data;
  }

  async getMirrors(brand_id?: number) {
    const response = await this.httpService.get<AxiosResponse<IMirrors[]>>(
      {
        url: `/api/bo/mirrors/${brand_id ? "?brand_id=" + brand_id : ""}`,
      },
      true
    );
    return response.data;
  }

  async editMirror(data: { [key: string]: boolean | string | number }) {
    const response = await this.httpService.post<AxiosResponse<IMessage>>(
      {
        url: "/api/bo/mirrors/",
        data,
      },
      true
    );
    return response.data;
  }
}

export const brandService = new BrandService(httpService);
