import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BORDERRADIUS, COLORS } from "../../consts";
import { AppMenu } from "../../components/menu/menu";
import { useGetGameHistory } from "../../hooks/casino/usetGetGameHistory";
import { CasinoHistory } from "../../modules/casino/history/casinoHistory";
import {
  CasinoFilter,
  IInitialValues,
} from "../../modules/casino/casinoFilter/casinoFilter";
import { AlertContext } from "../../hooks/notification/useAlert";
import { EmployeeContext } from "../../hooks/auth/useAuth";
import { queryCreator } from "../../utils/queryToObj";
import { ICasinoRound } from "../../types/casino.type";

export const Game = () => {
  const navigator = useNavigate();
  const { logout } = useContext(EmployeeContext);
  const { alertHandler } = useContext(AlertContext);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [order, setOrder] = useState<{ [key: string]: "ASC" | "DESC" }>({
    start_date: "DESC",
  });
  const [history, setHistory] = useState<ICasinoRound[]>([]);
  const [filter, setFilter] = useState<IInitialValues>({
    player_id: "",
    id: "",
    from: "",
    to: "",
    status: undefined,
    currency: undefined,
    brand_filter: undefined,
  });
  const { data, error, isFetching } = useGetGameHistory(query);
  const orderHandler = (key: string) => {
    if (order[key] === "DESC") {
      setOrder({ [key]: "ASC" });
      return;
    }
    setOrder({ [key]: "DESC" });
  };
  useEffect(() => {
    const queryString = queryCreator({
      ...order,
      ...filter,
      page: 1,
    });
    setPage(1);
    setHistory([]);
    setQuery(queryString);
  }, [order, filter]);
  useEffect(() => {
    const queryString = queryCreator({
      ...order,
      ...filter,
      page,
    });
    setQuery(queryString);
  }, [page]);
  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
      if (error.message === "Unathorized") {
        logout();
        navigator("/");
      }
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setHistory((prev) => [...prev, ...data.data]);
    }
  }, [data]);

  useEffect(() => {
    if (history.length === 0) {
      if (data) {
        setHistory(data.data);
      }
    }
  }, [history]);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <AppMenu />
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 36px)",
          width: "100%",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            gap: "10px",
          }}
        >
          <CasinoHistory
            page={page}
            history={history}
            count={data ? data.count : 0}
            setPage={setPage}
            orderHandler={orderHandler}
            isFetching={isFetching}
          />
          <CasinoFilter setFilter={setFilter} />
        </Box>
      </Box>
    </Box>
  );
};
