import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { lobbyService } from "../../services/lobby.service";

export const useSaveProvider = (
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (value: FormData) =>
      await lobbyService.saveProvider(value),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["providers"] });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
