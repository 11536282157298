import React from "react";
import { Box } from "@mui/material";
import { COLORS } from "../../consts";
import { LoaderStyled } from "./loader.styled";

export const Loader = ({ transparent }: { transparent?: boolean }) => {
  return (
    <LoaderStyled transparent={transparent}>
      <Box
        sx={{
          width: "7.5px",
          backgroundColor: COLORS.ORANGE,
        }}
        className="animation0"
      ></Box>
      <Box
        sx={{
          width: "7.5px",
          backgroundColor: COLORS.ORANGE,
        }}
        className="animation1"
      ></Box>
      <Box
        sx={{
          width: "7.5px",
          backgroundColor: COLORS.ORANGE,
        }}
        className="animation2"
      ></Box>
      <Box
        sx={{
          width: "7.5px",
          backgroundColor: COLORS.ORANGE,
        }}
        className="animation3"
      ></Box>
      <Box
        sx={{
          width: "7.5px",
          backgroundColor: COLORS.ORANGE,
        }}
        className="animation4"
      ></Box>
      <Box
        sx={{
          width: "7.5px",
          backgroundColor: COLORS.ORANGE,
        }}
        className="animation5"
      ></Box>
      <Box
        sx={{
          width: "7.5px",
          backgroundColor: COLORS.ORANGE,
        }}
        className="animation6"
      ></Box>
    </LoaderStyled>
  );
};
