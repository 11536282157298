import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { AppMenu } from "../../../components/menu/menu";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { CreateFreespinsModal } from "../../../modules/bonuses/create/modals/create.freespins.modal";
import { useGetFreespins } from "../../../hooks/bonuses/useGetFS";
import { IFreespins } from "../../../types/freespins.type";
import { CreatePercentageModal } from "../../../modules/bonuses/create/modals/create.percentage.modal";
import { useGetPercentage } from "../../../hooks/bonuses/useGetPR";
import { IPercentage } from "../../../types/perscentage.type";
import { useCreateBonus } from "../../../hooks/bonuses/useCreateBonus";
import { AlertContext } from "../../../hooks/notification/useAlert";
import { IInitialValues } from "../../../modules/bonuses/create/typing/initialValues";
import { MainFields } from "../../../modules/bonuses/create/mainFields/mainFields";
import { AdditionalFields } from "../../../modules/bonuses/create/additionalFields/AdditionalFields";
import { PreviewModal } from "../../../modules/bonuses/create/modals/create.preview.modal";
import { useGetBrands } from "../../../hooks/brand/useGetBrands";
import { EmployeeContext } from "../../../hooks/auth/useAuth";

const InitialValues: IInitialValues = {
  display_name: "",
  description: "",
  fs: "",
  pers: "",
  min_dep: [],
  start_date: "",
  end_date: "",
  duration: 0,
  wager_fs: 0,
  wager_pers: 0,
  type: "individual",
  sub_type: "common",
  place: "none",
  additional_items: {
    deposit: undefined,
    game: undefined,
  },
  email: undefined,
  brand: undefined,
};
export const BonusesCreate = () => {
  const navigate = useNavigate();
  const cancelHandler = () => {
    formik.setValues(InitialValues);
    navigate("/bonuses");
  };
  const { logout } = useContext(EmployeeContext);
  const { data: brands, error } = useGetBrands();
  const { data: freespins } = useGetFreespins();
  const { data: percentage } = useGetPercentage();
  const [freespinsSelectArray, setFreespinsSelectArray] = useState<
    { value: IFreespins; text: string }[]
  >([]);
  const [percentageSelectArray, setPercentageSelectArray] = useState<
    { value: IPercentage; text: string }[]
  >([]);
  const { alertHandler } = useContext(AlertContext);
  const { mutate: create } = useCreateBonus(alertHandler, cancelHandler);
  const formik = useFormik({
    initialValues: InitialValues,
    onSubmit: async () => {
      const formData = new FormData();
      for (const key in formik.values) {
        if (
          key === "fs" ||
          key === "pers" ||
          key === "additional_items" ||
          key === "email" ||
          key === "brand" ||
          key === "min_dep"
        ) {
          if (formik.values[key] === undefined) {
            continue;
          }
          formData.append(key, JSON.stringify(formik.values[key]));
          continue;
        }
        formData.append(key, formik.values[key]);
      }
      for (const key in images) {
        if (!images[key]) {
          continue;
        }
        //@ts-ignore
        formData.append(key, images[key]);
      }
      create(formData);
    },
  });
  const startDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({
        ...formik.values,
        start_date: date.valueOf().toString(),
      });
      return;
    }
    formik.setValues({ ...formik.values, start_date: "" });
  };
  const endDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({
        ...formik.values,
        end_date: (date.valueOf() + 86340000).toString(),
      });
      return;
    }
    formik.setValues({ ...formik.values, end_date: "" });
  };
  const setFSValue = (value: IFreespins) => {
    formik.setValues({
      ...formik.values,
      fs: value,
    });
  };
  const setPRValue = (value: IPercentage) => {
    formik.setValues({
      ...formik.values,
      pers: value,
    });
  };
  const [images, setImages] = useState<{
    [key: string]: File | null;
    bg_image: File | null;
    sub_image: File | null;
    email_image: File | null;
  }>({ bg_image: null, sub_image: null, email_image: null });
  const imagesHandler = (
    key: "bg_image" | "sub_image" | "email_image",
    file: File | null
  ) => {
    if (!file) {
      return;
    }
    setImages((state) => {
      const newState = { ...state };
      for (const keys in newState) {
        if (keys === key) newState[keys] = file;
      }
      return newState;
    });
  };

  const [fsModal, setFsModal] = useState(false);
  const [persModal, setPersModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const closeFSModal = () => {
    setFsModal(false);
  };
  const openFSModal = () => {
    setFsModal(true);
  };
  const closePersModal = () => {
    setPersModal(false);
  };
  const openPersModal = () => {
    setPersModal(true);
  };
  const closePreviewModal = () => {
    setPreviewModal(false);
  };
  const openPreviewModal = () => {
    setPreviewModal(true);
  };

  useEffect(() => {
    if (!freespins || !percentage) {
      return;
    }
    const newFSArray = freespins.map((freespin) => {
      return {
        value: freespin,
        text: `${freespin.count_fs} FS in game ${freespin.game_name} bet ${freespin.bet}`,
      };
    });
    const newPRArray = percentage.map((per) => {
      return {
        value: per,
        text: `${per.count_pers}% to Deposit min ${per.min_sum?.map(
          (min_sum) => `${min_sum.min_sum} ${min_sum.currency_name}`
        )} max ${per.max_sum?.map(
          (max_sum) => `${max_sum.max_sum} ${max_sum.currency_name}`
        )}`,
      };
    });
    setFreespinsSelectArray(newFSArray);
    setPercentageSelectArray(newPRArray);
  }, [freespins, percentage]);
  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
      if (error.message === "Unathorized") {
        logout();
        navigate("/");
      }
    }
  }, [error]);
  useEffect(() => {
    if (formik.values.brand) {
      formik.setValues({
        ...formik.values,
        min_dep: formik.values.brand.currencies.map((currency) => ({
          currency_name: currency.name,
          min_dep: "0",
        })),
      });
    }
  }, [formik.values.brand]);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <AppMenu />
      </Box>
      <Box
        sx={{
          margin: "10px",
          width: "calc(100% - 20px)",
          height: "calc(100vh - 54px)",
          display: "flex",
          alignItems: "flex-start",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "6px 16px",
            backgroundColor: COLORS.PRIMARY_BG,
            borderRadius: BORDERRADIUS.PRIMARY,
            gap: "3px",
            marginBottom: "10px",
            width: "100%",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              backgroundColor: COLORS.GREY,
              borderRadius: BORDERRADIUS.PRIMARY,
              color: COLORS.LIGHTGREY,
              fontWeight: "700",
              textAlign: "center",
              marginBottom: "12px",
            }}
          >
            Create Bonus
          </Typography>
          <Box sx={{ display: "flex", gap: "40px" }}>
            <MainFields
              brands={brands ? brands : []}
              handler={formik.handleChange}
              values={formik.values}
              setValues={formik.setValues}
              FSArray={freespinsSelectArray}
              PRArray={percentageSelectArray}
              startHandler={startDateHandler}
              endHandler={endDateHandler}
              setFS={setFSValue}
              setPR={setPRValue}
            />
            <AdditionalFields
              values={formik.values}
              images={images}
              imagesHandler={imagesHandler}
              setValues={formik.setValues}
              openFSModal={openFSModal}
              openPersModal={openPersModal}
              openPreviewModal={openPreviewModal}
              cancelHandler={cancelHandler}
              submitHandler={formik.handleSubmit}
            />
          </Box>
        </Box>
      </Box>
      <CreateFreespinsModal
        brands={brands ? brands : []}
        open={fsModal}
        close={closeFSModal}
      />
      <CreatePercentageModal
        brands={brands ? brands : []}
        open={persModal}
        close={closePersModal}
      />
      <PreviewModal
        open={previewModal}
        close={closePreviewModal}
        values={formik.values}
        images={images}
      />
    </Box>
  );
};
