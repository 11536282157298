import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { useOutletContext } from "react-router-dom";
import { IUser } from "../../../../types/user.type";
import { AlertContext } from "../../../../hooks/notification/useAlert";
import { useGetVerificationStatus } from "../../../../hooks/player/playerInfo/useGetVerificationStatus";
import { Loader } from "../../../../components/loader/loader";

export const OtherInfo = () => {
  const [user]: IUser[] = useOutletContext();
  const { alertHandler } = React.useContext(AlertContext);
  const { data, isLoading, error, isError } = useGetVerificationStatus(user.id);
  const [verificationStatus, setVerificationStatus] = useState(false);
  useEffect(() => {
    if (data && !isError) {
      setVerificationStatus(data.verification_status);
    }
  }, [data]);
  useEffect(() => {
    if (isError) {
      //@ts-ignore
      alertHandler(error.message, error.code);
    }
  }, [error]);
  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        position: "relative",
      }}
    >
      {isLoading && <Loader />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>Верификация: </Typography>
        <Box
          sx={{
            padding: "3px 16px",
            border: `1px solid ${
              verificationStatus ? COLORS.GREEN : COLORS.ORANGE
            }`,
            fontSize: "14px",
            color: verificationStatus ? COLORS.GREEN : COLORS.ORANGE,
          }}
        >
          {verificationStatus ? "Верифицирован" : "Не верифицирован"}
        </Box>
      </Box>
    </Box>
  );
};
