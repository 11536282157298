import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { lobbyService } from "../../services/lobby.service";
import { ISelectSlot } from "../../types/lobby.type";

export const useSaveSlots = (
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (value: {
      providerInternalId: number | null;
      slots: ISelectSlot[];
    }) => await lobbyService.saveSlots(value),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["providers"] });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
