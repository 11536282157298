import React, { useEffect, useState } from "react";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { InputB } from "../../../../components/input-b/inputB";
import { InputDate } from "../../../../components/inputdate/inputdate";
import { Box } from "@mui/material";
import { MyButton } from "../../../../components/button/button";
import { useFormik } from "formik";
import { InputSelect } from "../../../../components/inputSelect/input.select";

interface InitialValues {
  [key: string]: string | number | null | undefined;
  transaction_id: string;
  from: null | string;
  to: null | string;
  type?:
    | "debit"
    | "credit"
    | "deposit"
    | "withdrawal"
    | "bonus_accrual"
    | "bonus_release"
    | "bonus_close"
    | "writeoff"
    | "manual_deposit";
}

type IFinanceFilterProps = {
  playerId: string;
  page: number;
  setObj: React.Dispatch<any>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setFinances: React.Dispatch<React.SetStateAction<any[]>>;
};

const initialValues: InitialValues = {
  transaction_id: "",
  type: undefined,
  from: "",
  to: "",
};

export const FinanceFilter = ({
  playerId,
  page,
  setObj,
  setPage,
  setFinances,
}: IFinanceFilterProps) => {
  const [clearActive, setClearActive] = useState(true);
  const [submitActive, setSubmitActive] = useState(true);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      setClearActive(false);
      setSubmitActive(true);
      setPage(1);
      setFinances([]);
      setObj({
        playerId,
        page,
        ...formik.values,
      });
    },
  });

  const clearHandler = () => {
    setClearActive(true);
    formik.setValues(initialValues);
    setFinances([]);
    setPage(1);
    setObj({ playerId, page, ...initialValues });
  };
  const fromDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({ ...formik.values, from: date.valueOf().toString() });
      return;
    }
    formik.setValues({ ...formik.values, from: "" });
  };
  const toDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({ ...formik.values, to: date.valueOf().toString() });
      return;
    }
    formik.setValues({ ...formik.values, to: "" });
  };
  useEffect(() => {
    setObj({
      playerId,
      page,
      ...formik.values,
    });
  }, [page]);

  useEffect(() => {
    for (const key in formik.values) {
      if (formik.values[key] !== initialValues[key]) {
        setSubmitActive(false);
      }
    }
  }, [formik.values]);
  return (
    <Box
      sx={{
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        padding: "10px 16px",
        margin: "10px",
        width: "400px",
      }}
    >
      <InputB
        id={"transaction_id"}
        onChange={formik.handleChange}
        value={formik.values.transaction_id}
        label={"Transaction ID"}
      />
      <InputSelect
        label="Type"
        dataArray={[
          { value: undefined, text: "None" },
          { value: "credit", text: "credit" },
          { value: "debit", text: "debit" },
          { value: "deposit", text: "deposit" },
          { value: "withdrawal", text: "withdrawal" },
          { value: "withdrawal_cancel", text: "withdrawal_cancel" },
          { value: "bonus_accrual", text: "bonus_accrual" },
          { value: "bonus_release", text: "bonus_release" },
          { value: "bonus_close", text: "bonus_close" },
          { value: "writeoff", text: "writeoff" },
          { value: "manual_deposit", text: "manual_deposit" },
        ]}
        selectFunction={(value) => {
          formik.setValues({ ...formik.values, type: value });
        }}
      />
      <InputDate
        id="from"
        label="From date"
        disabled={false}
        value={
          formik.values.from === null
            ? ""
            : formik.values.from === ""
            ? formik.values.from
            : new Date(+formik.values.from)
        }
        onChange={fromDateHandler}
      />
      <InputDate
        id="to"
        label="To date"
        disabled={false}
        value={
          formik.values.to === null
            ? ""
            : formik.values.to === ""
            ? formik.values.to
            : new Date(+formik.values.to)
        }
        onChange={toDateHandler}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <MyButton
          disabled={clearActive}
          text="Clear"
          cancel
          type="button"
          onClick={clearHandler}
        />
        <MyButton
          disabled={submitActive}
          text="Filter"
          type="button"
          onClick={formik.handleSubmit}
        />
      </Box>
    </Box>
  );
};
