import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useGetPlayerBonuses } from "../../../hooks/player/playerBonuses/useGetBonuses";
import { queryCreator } from "../../../utils/queryToObj";
import { BonusItem } from "../../../modules/player/bonuses/bonusItem/bonusItem";
import { AlertContext } from "../../../hooks/notification/useAlert";
import { BonusFilter } from "../../../modules/player/bonuses/bonusFilter/bonusFilter";

export const PlayerBonusesPage = () => {
  const { alertHandler } = useContext(AlertContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [page, setPage] = useState(1);
  const [obj, setObj] = useState<any>({
    id,
    page,
    promo_code: "",
    status: undefined,
  });
  const [query, setQuery] = useState(
    queryCreator<{
      id: string;
      page: number;
      promo_code: "";
      status: undefined;
    }>(obj)
  );
  const { data, isFetching, error, isError } = useGetPlayerBonuses(query);

  const [bonuses, setBonuses] = useState<any[]>(data ? data.bonuses : []);
  useEffect(() => {
    if (data && data.bonuses && !isFetching) {
      setBonuses((prev: any) => {
        const arr = [...prev, ...data.bonuses];
        return arr.reduce((o, i) => {
          if (!o.find((v: any) => v.id === i.id)) {
            o.push(i);
          }
          return o;
        }, []);
      });
    }
  }, [isFetching]);

  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
    }
  }, [isError]);

  useEffect(() => {
    setQuery(queryCreator<{ id: string; page: number }>(obj));
  }, [obj]);

  useEffect(() => {
    if (inView) {
      if (data && data.count % 50 && Math.trunc(data.count / 50) === 0) {
        return;
      } else if (
        data &&
        data.count % 50 &&
        page <= Math.trunc(data.count / 50)
      ) {
        setPage((prev) => prev + 1);
      } else if (
        data &&
        data.count % 50 === 0 &&
        page < Math.trunc(data.count / 50)
      ) {
        setPage((prev) => prev + 1);
      }
    }
  }, [inView]);

  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 70px)", width: "100%" }}>
      <Box
        sx={{
          flex: "1 1 auto",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          padding: "10px 16px",
          margin: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: COLORS.PRIMARY,
            padding: "5px 16px",
            borderRadius: BORDERRADIUS.PRIMARY,
            fontSize: "13px",
            marginBottom: "10px",
            color: "black",
            gap: "10px",
            textAlign: "center",
          }}
        >
          <Box sx={{ width: "40px" }}>ID</Box>
          <Box sx={{ width: "70px" }}>Promocode</Box>
          <Box sx={{ width: "150px" }}>Title</Box>
          <Box sx={{ width: "40px" }}>Type</Box>
          <Box sx={{ width: "100px" }}>Status</Box>
          <Box sx={{ width: "80px" }}>Amount</Box>
          <Box sx={{ width: "100px" }}>Wagered</Box>
          <Box sx={{ width: "100px" }}>Wager</Box>
          <Box sx={{ width: "50px" }}>Wager X</Box>
          <Box sx={{ width: "50px" }}>Duration</Box>
          <Box sx={{ width: "70px" }}>Start date</Box>
          <Box sx={{ width: "70px" }}>End date</Box>
          <Box sx={{ width: "20px" }}></Box>
        </Box>
        <Box sx={{ height: "calc(100% - 45px)", overflowY: "auto" }}>
          {bonuses.map((bonus, index) => {
            if (index === bonuses.length - 4) {
              return (
                <div ref={ref} key={bonus.id}>
                  <BonusItem
                    bonus={bonus}
                    query={query}
                    alertHandler={alertHandler}
                    setBonuses={setBonuses}
                  />
                </div>
              );
            }
            return (
              <BonusItem
                bonus={bonus}
                key={bonus.id}
                query={query}
                alertHandler={alertHandler}
                setBonuses={setBonuses}
              />
            );
          })}
        </Box>
      </Box>
      <BonusFilter
        id={id}
        page={page}
        setObj={setObj}
        setPage={setPage}
        setBonuses={setBonuses}
      />
    </Box>
  );
};
