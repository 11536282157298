import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { queryStringToObject } from "../../utils/queryToObj";
import { EmployeeContext } from "../../hooks/auth/useAuth";
import { IAuthotizationResponse } from "../../types/employee.type";
import { Loader } from "../../components/loader/loader";

export const Authorization = () => {
  const employee = useContext(EmployeeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const query = queryStringToObject(location.search);
  useEffect(() => {
    async function ip() {
      try {
        const { data: authResponse } = await axios.get<IAuthotizationResponse>(
          `${process.env.REACT_APP_SERVER_URL_BO}/api/bo/auth`,
          {
            headers: {
              Authorization: "Bearer " + query.token,
            },
          }
        );
        employee.authorizate(authResponse);
        localStorage.setItem("token", authResponse.accessToken);
        navigate("/search");
      } catch (error) {
        console.log(error);
        navigate("/forbidded");
      }
    }
    ip();
  });

  return <Loader />;
};
