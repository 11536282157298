import React, { useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import "./Modal.css";
import { MyButton } from "../../../components/button/button";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: Record<string, any>;
}
export const Paymentmodal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  data,
}) => {
  if (!isOpen) return null;

  const renderValue = (value: any) => {
    if (typeof value === "object" && value !== null) {
      return value.name || value.id || JSON.stringify(value);
    }
    return String(value);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box id="modal-description" sx={{ mt: 2 }}>
          {data ? (
            <>
              {Object.keys(data).map((key) => (
                <Typography key={key}>
                  <strong>{key}:</strong> {renderValue(data[key])}
                </Typography>
              ))}
            </>
          ) : (
            <>
              <Typography>DATA NOT FOUND</Typography>
            </>
          )}
        </Box>
        <MyButton text="Close" onClick={onClose} />
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  maxHeight: "80vh",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
