import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../../errors/AlertError";
import { playerKycService } from "../../../services/playerKYC.service";

export const useArchive = (
  openHandler: (message: string, error?: string) => void,
  id: string
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (image: string) => playerKycService.archive(image, id),
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ["KYC", id],
      });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
