import { useQuery } from "@tanstack/react-query";
import { bonusService } from "../../services/bonusService";

export const useGetBonuses = (id?: number) => {
  return useQuery({
    queryFn: () => bonusService.getBonuses(id),
    queryKey: id ? ["bonus", id] : ["bonuses"],
    gcTime: 0,
  });
};
