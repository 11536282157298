import { Box, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ModalTemplate } from "../../../../components/modal/modal.template";
import { useChangeStatus } from "../../../../hooks/player/playerBonuses/useChangeStatus";
import { useCancelBonus } from "../../../../hooks/player/playerBonuses/useCancelBonus";
import { SimpleModal } from "../../../../components/modal/simple.modal";
import { InputSelect } from "../../../../components/inputSelect/input.select";

type IBonusItemProps = {
  bonus: any;
  query: string;
  alertHandler: (message: string, error?: string | undefined) => void;
  setBonuses: React.Dispatch<React.SetStateAction<any[]>>;
};

export const BonusItem = ({
  bonus,
  query,
  alertHandler,
  setBonuses,
}: IBonusItemProps) => {
  const successCallback = () => {
    setBonuses([]);
    closeModalHandler();
  };
  const { mutate: changeStatus } = useChangeStatus(
    alertHandler,
    query,
    successCallback
  );
  const { mutate: cancel } = useCancelBonus(
    alertHandler,
    query,
    successCallback
  );
  const [status, setStatus] = useState(bonus.status);
  const statusHandler = (value: string) => {
    setStatus(value);
  };
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [modal, setModal] = useState(false);
  const openModalHandler = () => {
    handleClose();
    setModal(true);
  };
  const closeModalHandler = () => {
    setModal(false);
  };
  const [cancelModal, setCancelModal] = useState(false);
  const openCancelModalHandler = () => {
    handleClose();
    setCancelModal(true);
  };
  const closeCancelModalHandler = () => {
    setCancelModal(false);
  };
  const [detailsModal, setDetailsModal] = useState(false);
  const openDetailsModalHandler = () => {
    handleClose();
    setDetailsModal(true);
  };
  const closeDetailsModalHandler = () => {
    setDetailsModal(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: COLORS.DARKGREY,
        padding: "0 16px",
      }}
    >
      <Box sx={{ width: "40px" }}>{bonus.id}</Box>
      <Box sx={{ width: "70px" }}>{bonus.bonus.promo_code.id}</Box>
      <Box
        sx={{
          width: "150px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {bonus.bonus.display_name}
      </Box>
      <Box sx={{ width: "40px", textAlign: "center" }}>{bonus.bonus_type}</Box>
      <Box
        sx={{
          width: "100px",
          border: "2px solid",
          borderRadius: BORDERRADIUS.PRIMARY,
          padding: "0px 7px",
          textAlign: "center",
          color:
            bonus.status === "deposit" ||
            bonus.status === "use" ||
            bonus.status === "wagering"
              ? COLORS.GREEN
              : bonus.status === "active"
              ? COLORS.BLUE
              : bonus.status === "wagered"
              ? COLORS.PURPLE
              : bonus.status === "fail"
              ? COLORS.RED
              : bonus.status === "expired"
              ? COLORS.DARKGREY
              : COLORS.ORANGE,
        }}
      >
        {bonus.status}
      </Box>
      <Box sx={{ width: "80px" }}>{bonus.bonus_amount}</Box>
      <Box sx={{ width: "100px" }}>{(+bonus.wagered).toFixed(2)}</Box>
      <Box sx={{ width: "100px" }}>{(+bonus.wager).toFixed(2)}</Box>
      <Box sx={{ width: "50px", textAlign: "center" }}>
        {bonus.bonus_type === "FS"
          ? bonus.bonus.additional_information.wager_fs
          : bonus.bonus.additional_information.wager_pers}
      </Box>
      <Box sx={{ width: "50px", textAlign: "center" }}>
        {bonus.bonus.additional_information.duration}
      </Box>
      <Box sx={{ width: "70px", textAlign: "center", fontSize: "12px" }}>
        {new Date(+bonus.start_date).toLocaleString()}
      </Box>
      <Box sx={{ width: "70px", textAlign: "center", fontSize: "12px" }}>
        {new Date(+bonus.end_date).toLocaleString()}
      </Box>
      <Box>
        <MoreVertIcon
          aria-controls={open ? "customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={{
            fontSize: "20px",
            ":hover": {
              backgroundColor: COLORS.SECONDARY,
              borderRadius: BORDERRADIUS.PRIMARY,
            },
          }}
          onClick={handleClick}
        />
        <Menu
          id="customized-menu"
          MenuListProps={{
            "aria-labelledby": "customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            " .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
              boxShadow: "3px 5px 10px -5px rgba(0,0,0,0.2)",
              backgroundColor: COLORS.PRIMARY_BG,
            },
          }}
        >
          <MenuItem onClick={openDetailsModalHandler}>Details</MenuItem>
          <MenuItem onClick={openModalHandler}>Change status</MenuItem>
          <MenuItem onClick={openCancelModalHandler}>Close bonus</MenuItem>
        </Menu>
      </Box>
      <ModalTemplate
        open={modal}
        close={closeModalHandler}
        confirm={() => {
          changeStatus({ id: bonus.id, status: status });
        }}
        heading="Change status"
      >
        <Box sx={{ width: "300px" }}>
          <InputSelect
            label={"Choose status"}
            dataArray={[
              { value: "active", text: "Active" },
              { value: "deposit", text: "Deposit" },
              { value: "use", text: "Use" },
              { value: "wagering", text: "Wagering" },
              { value: "wagered", text: "Wagered" },
              { value: "fail", text: "Fail" },
              { value: "canceled", text: "Canceled" },
              { value: "expired", text: "Expired" },
            ]}
            selectFunction={(value) => {
              statusHandler(value);
            }}
          />
        </Box>
      </ModalTemplate>
      <ModalTemplate
        open={detailsModal}
        close={closeDetailsModalHandler}
        heading="Details"
        confirmButton="OK"
        confirm={closeDetailsModalHandler}
      >
        <Box sx={{ width: "350px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>Title:</Box>
            <Box sx={{ color: COLORS.DARKGREY, fontWeight: 700 }}>
              {bonus.bonus.display_name}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>Description:</Box>
            <Box sx={{ color: COLORS.DARKGREY, fontWeight: 700 }}>
              {bonus.bonus.description}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>Duration:</Box>
            <Box sx={{ color: COLORS.DARKGREY, fontWeight: 700 }}>
              {bonus.bonus.additional_information.duration}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>Wager Freespins:</Box>
            <Box sx={{ color: COLORS.DARKGREY, fontWeight: 700 }}>
              {bonus.bonus.additional_information.wager_fs}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>Wager Persentage:</Box>
            <Box sx={{ color: COLORS.DARKGREY, fontWeight: 700 }}>
              {bonus.bonus.additional_information.wager_pers}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>Activation start date:</Box>
            <Box sx={{ color: COLORS.DARKGREY, fontWeight: 700 }}>
              {new Date(+bonus.bonus.promo_code.start_date).toLocaleString()}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>Activation end date:</Box>
            <Box sx={{ color: COLORS.DARKGREY, fontWeight: 700 }}>
              {new Date(+bonus.bonus.promo_code.end_date).toLocaleString()}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>Minimal deposit:</Box>
            <Box sx={{ color: COLORS.DARKGREY, fontWeight: 700 }}>
              {bonus.bonus.activation_rules.min_dep}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>Placement:</Box>
            <Box sx={{ color: COLORS.DARKGREY, fontWeight: 700 }}>
              {bonus.bonus.additional_information.place}
            </Box>
          </Box>
        </Box>
      </ModalTemplate>
      <SimpleModal
        open={cancelModal}
        close={closeCancelModalHandler}
        confirm={() => {
          cancel(bonus.id);
        }}
        heading={"Confirm"}
        mainText={
          "Please, confirm canceling bonus by clicking on apropriate button"
        }
      />
    </Box>
  );
};
