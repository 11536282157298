import React from "react";

export const useAlert = () => {
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState({
    error: "",
    message: "",
  });
  const closeHandler = () => {
    setErrorMessage({
      error: "",
      message: "",
    });
    setOpen(false);
  };
  const alertHandler = (message: string, error?: string) => {
    if (error) {
      setErrorMessage({ message, error });
      setOpen(true);
    }
    setTimeout(() => {
      closeHandler();
    }, 1000 * 10);
  };
  return { open, errorMessage, alertHandler, closeHandler };
};

export interface IAlertContext {
  open: boolean;
  errorMessage: {
    error: string;
    message: string;
  };
  alertHandler: (message: string, error?: string | undefined) => void;
  closeHandler: () => void;
}
const defaultContextState = {
  open: false,
  errorMessage: {
    error: "",
    message: "",
  },
  alertHandler: (message: string, error?: string) => {},
  closeHandler: () => {},
} as IAlertContext;

export const AlertContext = React.createContext(defaultContextState);

type AlertProviderProps = {
  alert: IAlertContext;
  children: React.ReactNode;
};

export default function AlertProvider({ alert, children }: AlertProviderProps) {
  return (
    <AlertContext.Provider value={alert}> {children} </AlertContext.Provider>
  );
}
