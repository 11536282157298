import { Box } from "@mui/material";
import React, { useState } from "react";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { Link } from "react-router-dom";

type IHistoryItemProps = {
  historyItem: any;
};

export const CasinoHistoryItem = ({ historyItem }: IHistoryItemProps) => {
  const [open, setOpen] = useState(false);
  const openHandler = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: COLORS.DARKGREY,
          padding: "0",
          borderBottom:
            historyItem.free_rounds.length > 0
              ? `1px solid ${COLORS.GREEN}`
              : "none",
          cursor: historyItem.free_rounds.length > 0 ? "pointer" : "auto",
        }}
        onClick={openHandler}
      >
        <Box
          sx={{
            width: "100px",
            cursor: "pointer",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          <Link to={`/player/${historyItem.user.id}/info`} target="_blank">
            {historyItem.user.id}
          </Link>
        </Box>
        <Box sx={{ width: "70px", textAlign: "center", fontSize: "12px" }}>
          {new Date(+historyItem.start_date).toLocaleString()}
        </Box>
        <Box sx={{ width: "260px", fontSize: "13px" }}>
          {historyItem.external_id}
        </Box>
        <Box
          sx={{
            width: "150px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {historyItem.game_name}
        </Box>
        <Box
          sx={{
            width: "100px",
            border: "2px solid",
            borderRadius: BORDERRADIUS.PRIMARY,
            padding: "0px 7px",
            textAlign: "center",
            color:
              historyItem.status === "closed"
                ? COLORS.GREEN
                : historyItem.status === "pending"
                ? COLORS.ORANGE
                : COLORS.RED,
          }}
        >
          {historyItem.status}
        </Box>
        <Box sx={{ width: "100px", textAlign: "center" }}>
          {(+historyItem.amount).toFixed(2)}
        </Box>
        <Box sx={{ width: "100px", textAlign: "center" }}>
          {(+historyItem.result).toFixed(2)}
        </Box>
        <Box sx={{ width: "100px", textAlign: "center" }}>
          {(+historyItem.profit).toFixed(2)}
        </Box>
        <Box sx={{ width: "60px", textAlign: "center" }}>
          {historyItem.currency.name}
        </Box>
        <Box sx={{ width: "70px", textAlign: "center", fontSize: "12px" }}>
          {historyItem.end_date
            ? new Date(+historyItem.end_date).toLocaleString()
            : ""}
        </Box>
      </Box>
      {open && historyItem.free_rounds.length > 0 && (
        <Box
          sx={{
            padding: "5px 16px 0",
            backgroundColor: COLORS.SECONDARY,
            borderRadius: BORDERRADIUS.PRIMARY,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: COLORS.PRIMARY,
              padding: "5px 16px",
              borderRadius: BORDERRADIUS.PRIMARY,
              fontSize: "13px",
              color: "black",
              gap: "10px",
              textAlign: "center",
            }}
          >
            <Box sx={{ width: "150px" }}>ID</Box>
            <Box sx={{ width: "100px" }}>Bonus ID</Box>
            <Box sx={{ width: "150px" }}>Freespins played</Box>
            <Box sx={{ width: "150px" }}>Win in round</Box>
            <Box sx={{ width: "150px" }}>Total win</Box>
          </Box>
          {historyItem.free_rounds
            .sort((a: any, b: any) => a.played - b.played)
            .map((round: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "2.5px 16px",
                    textAlign: "center",
                    color: COLORS.DARKGREY,
                    fontSize: "14px",
                  }}
                  key={round.id}
                >
                  <Box sx={{ width: "150px" }}>{round.id}</Box>
                  <Box sx={{ width: "100px" }}>{round.fs_id}</Box>
                  <Box sx={{ width: "150px" }}>{round.played}</Box>
                  <Box sx={{ width: "150px" }}>
                    {(+round.round_win).toFixed(2)}
                  </Box>
                  <Box sx={{ width: "150px" }}>{(+round.win).toFixed(2)}</Box>
                </Box>
              );
            })}
        </Box>
      )}
    </Box>
  );
};
