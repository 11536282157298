import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { bonusService } from "../../services/bonusService";

export const useCreateBonus = (
  openHandler: (message: string, error?: string) => void,
  callback: () => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (value: FormData) =>
      await bonusService.createBonus(value),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["bonuses"] });
      callback();
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
