import { AxiosError, AxiosResponse } from "axios";
import { HttpService, httpService } from "./httpService";
import { AlertError } from "../errors/AlertError";

class PlayerCasinoService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }
  async getCasino(query: string) {
    try {
      const response = await this.httpService.get<
        AxiosResponse<{ casino: any[]; count: number; games: string[] }>
      >(
        {
          url: `/api/bo/player/casino${query}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
}
export const playerCasinoService = new PlayerCasinoService(httpService);
