import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { queryCreator } from "../../../utils/queryToObj";
import { AlertContext } from "../../../hooks/notification/useAlert";
import { HistoryItem } from "../../../modules/player/casino/historyItem/historyItem";
import { CasinoFilter } from "../../../modules/player/casino/casinoFilter/casinoFilter";
import { useGetPlayerCasinoHistory } from "../../../hooks/player/playerCasino/useGetCasinoHistory";

export const PlayerCasinoHistoryPage = () => {
  const { alertHandler } = useContext(AlertContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [page, setPage] = useState(1);
  const [obj, setObj] = useState<any>({
    playerID: id,
    page,
    promo_code: "",
    status: undefined,
    game_name: undefined,
  });
  const [query, setQuery] = useState(
    queryCreator<{
      playerID: string;
      page: number;
      promo_code: "";
      status: undefined;
    }>(obj)
  );
  const { data, isFetching, error, isError } = useGetPlayerCasinoHistory(query);
  const [casino, setCasino] = useState<any[]>(data ? data.casino : []);
  const [games, setGames] = useState<string[]>(data ? data.games : []);
  useEffect(() => {
    if (data && data.casino && !isFetching) {
      setCasino((prev: any) => {
        const arr = [...prev, ...data.casino];
        return arr.reduce((o, i) => {
          if (!o.find((v: any) => v.id === i.id)) {
            o.push(i);
          }
          return o;
        }, []);
      });
      setGames(data?.games);
    }
  }, [isFetching]);

  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
    }
  }, [isError]);

  useEffect(() => {
    setQuery(queryCreator<{ playerID: string; page: number }>(obj));
  }, [obj]);

  useEffect(() => {
    if (inView) {
      if (data && data.count % 100 && Math.trunc(data.count / 100) === 0) {
        return;
      } else if (
        data &&
        data.count % 100 &&
        page <= Math.trunc(data.count / 100)
      ) {
        setPage((prev) => prev + 1);
      } else if (
        data &&
        data.count % 100 === 0 &&
        page < Math.trunc(data.count / 100)
      ) {
        setPage((prev) => prev + 1);
      }
    }
  }, [inView]);

  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 70px)", width: "100%" }}>
      <Box
        sx={{
          flex: "1 1 auto",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          padding: "10px 16px",
          margin: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: COLORS.PRIMARY,
            padding: "5px 16px",
            borderRadius: BORDERRADIUS.PRIMARY,
            fontSize: "13px",
            marginBottom: "10px",
            color: "black",
            gap: "10px",
            textAlign: "center",
          }}
        >
          <Box sx={{ width: "310px" }}>ID</Box>
          <Box sx={{ width: "70px" }}>Start date</Box>
          <Box sx={{ width: "150px" }}>Game</Box>
          <Box sx={{ width: "100px" }}>Status</Box>
          <Box sx={{ width: "100px" }}>Amount</Box>
          <Box sx={{ width: "100px" }}>Result</Box>
          <Box sx={{ width: "100px" }}>Profit</Box>
          <Box sx={{ width: "60px" }}>Currency</Box>
          <Box sx={{ width: "70px", marginRight: "20px" }}>End date</Box>
        </Box>
        <Box sx={{ height: "calc(100% - 45px)", overflowY: "auto" }}>
          {casino.map((historyItem, index) => {
            if (index === casino.length - 4) {
              return (
                <div ref={ref} key={historyItem.id}>
                  <HistoryItem historyItem={historyItem} />
                </div>
              );
            }
            return (
              <HistoryItem historyItem={historyItem} key={historyItem.id} />
            );
          })}
        </Box>
      </Box>
      <CasinoFilter
        playerID={id}
        page={page}
        games={games}
        setObj={setObj}
        setPage={setPage}
        setCasino={setCasino}
      />
    </Box>
  );
};
