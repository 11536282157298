import styled from "styled-components";
import { COLORS } from "../../consts";

export const HeaderStyled = styled.div`
  .header__link {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    color: ${COLORS.DARKGREY};
    font-size: 14px;
    font-weight: bold;
    &.active {
      background-color: ${COLORS.PRIMARY_BG};
    }
  }
`;
