export function queryStringToObject(queryString: string) {
  var splitedQueryString = decodeURIComponent(
    queryString.slice(1, queryString.length)
  ).split(/[=&]/);
  var myObj: { [key: string]: string } = {};
  var keys = [];
  var data = [];

  for (var i = 0; i < splitedQueryString.length; i++) {
    if (i % 2 !== 0) {
      data.push(splitedQueryString[i]);
    } else {
      keys.push(splitedQueryString[i]);
    }
    for (var j = 0; j < data.length; j++) {
      myObj[keys[j]] = data[j];
    }
  }
  return myObj;
}

export const queryCreator = <T>(obj: T) => {
  let i = 0;
  let query = "";
  for (const key in obj) {
    if (obj[key] === null || obj[key] === "" || obj[key] === undefined) {
      continue;
    }
    if (i === 0) {
      query += `?${key}=${obj[key]}`;
      i++;
      continue;
    }
    query += `&${key}=${obj[key]}`;
    i++;
  }
  return query;
};
