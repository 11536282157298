import { AxiosError, AxiosResponse } from "axios";
import { HttpService, httpService } from "./httpService";
import { AlertError } from "../errors/AlertError";

class PlayerFinanceService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }
  async getFinances(query: string) {
    try {
      const response = await this.httpService.get<
        AxiosResponse<{ finances: any[]; count: number }>
      >(
        {
          url: `/api/bo/player/finance${query}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
}
export const playerFinanceService = new PlayerFinanceService(httpService);
