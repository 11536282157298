import { useQuery } from "@tanstack/react-query";
import { bonusService } from "../../services/bonusService";

export const useGetPercentage = () => {
  return useQuery({
    queryFn: () => bonusService.getPercentage(),
    queryKey: ["percentage"],
    gcTime: 0,
  });
};
