import { useMutation, useQueryClient } from "@tanstack/react-query";
import { userService } from "../../../services/userService";
import { AlertError } from "../../../errors/AlertError";

export const useChangeRestrictions = (
  id: string,
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      withdrawal: boolean;
      deposits: boolean;
      slots: boolean;
      login: boolean;
    }) => userService.changeRestrictions(data, id),
    onSuccess: (data) => {
      if (data) {
        openHandler(data.message);
      }
      client.invalidateQueries({ queryKey: ["restrictions", id] });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
