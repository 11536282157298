import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { paymentsService } from "../../services/paymentsService";

export const useAddComments = (
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (text: string) => paymentsService.addRejectComment(text),
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ["comments"],
      });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
