import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { COLORS, BORDERRADIUS } from "../../../consts";
import { MyButton } from "../../../components/button/button";
import { IRole } from "../../../types/role.type";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { IPermission } from "../../../types/permision.type";

type RoleProps = {
  roles: IRole[];
  openRoleModal: () => void;
  openPermissionModal: () => void;
};

type IRoleItemProps = {
  name: string;
  id: number;
  permissions: IPermission[];
};

export const RoleItem = ({ name, id, permissions }: IRoleItemProps) => {
  const [expand, setExpand] = useState(false);

  return (
    <Box
      sx={{
        height: expand ? `${44 + 32 * permissions.length}px` : "44px",
        overflow: "hidden",
        backgroundColor: COLORS.PRIMARY_BG,
        borderBottom: `1px solid ${COLORS.SECONDARY}`,
        color: COLORS.DARKGREY,
        transition: "all 0.3s ease",
      }}
    >
      <Box
        sx={{
          height: "44px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          textTransform: "capitalize",
        }}
        onClick={() => {
          setExpand(!expand);
        }}
      >
        <KeyboardArrowRightIcon
          sx={{
            transform: expand ? "rotate(90deg)" : "rotate(0deg)",
            transition: "all 0.3s ease",
            cursor: "pointer",
          }}
        />
        <Link to={`/access/${id}`}>{name}</Link>
      </Box>
      {permissions.map((permission) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "32px",
              textTransform: "lowercase",
            }}
            key={permission.id}
          >
            <CheckBoxIcon
              sx={{ color: COLORS.GREEN, margin: "0 15px 0 10px" }}
            />
            {permission.name}
          </Box>
        );
      })}
    </Box>
  );
};

export const Role = ({
  roles,
  openRoleModal,
  openPermissionModal,
}: RoleProps) => {
  return (
    <Box
      sx={{
        flex: "1 0 350px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
      }}
    >
      <Box
        sx={{
          padding: "10px 16px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <MyButton cancel text="Add role" onClick={openRoleModal} />
          <MyButton
            cancel
            text="Add permission"
            onClick={openPermissionModal}
          />
        </Box>
        <Box
          sx={{
            height: "calc(100% - 44px)",
            width: "100%",
            backgroundColor: COLORS.SECONDARY,
          }}
        >
          {roles.map((role) => {
            return (
              <RoleItem
                name={role.name}
                id={role.id}
                permissions={role.permissions}
                key={role.id}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
