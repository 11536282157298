import React, { useEffect, useState } from "react";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { Box, Tooltip, Typography } from "@mui/material";
import { InputB } from "../../../components/input-b/inputB";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { IPermission } from "../../../types/permision.type";
import { useSetPermissionsToRole } from "../../../hooks/access/useSetPermissionsToRole";
import { useGetPermissions } from "../../../hooks/access/useGetPermissions";
import { MyButton } from "../../../components/button/button";
import { IRole } from "../../../types/role.type";

type IPermissionComponentProps = {
  alertHandler: (message: string, error?: string | undefined) => void;
  role?: IRole[];
};

export const PermissionsComponent = ({
  alertHandler,
  role,
}: IPermissionComponentProps) => {
  const { data: permissions, isLoading } = useGetPermissions();
  const [permissionsArray, setPermissionsArray] = useState<IPermission[]>([]);
  const { mutate: addPermissions } = useSetPermissionsToRole(
    alertHandler,
    role ? role[0].id : null
  );

  const [search, setSearch] = useState("");

  const [selected, setSelected] = useState<IPermission[]>([]);
  const addSelected = (permission: IPermission) => {
    const select = !!selected.find((per) => per.id === permission.id);
    if (select) {
      setSelected([...selected.filter((per) => per.id !== permission.id)]);
      return;
    }
    setSelected([...selected, permission]);
  };
  const addPermissionsHandler = () => {
    if (!role) {
      return;
    }
    addPermissions({ role: role[0], permissions: selected });
  };
  useEffect(() => {
    if (!permissions) {
      return;
    }
    setPermissionsArray(permissions);
  }, [isLoading]);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "10px 16px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "5px 0",
          backgroundColor: COLORS.DARKGREY,
          borderRadius: BORDERRADIUS.PRIMARY,
          textAlign: "center",
          color: COLORS.LIGHTGREY,
          fontWeight: 900,
          fontSize: "16px",
          marginBottom: "10px",
        }}
      >
        Permissions
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          gap: "5px",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            gap: "5px",
            width: "100%",
          }}
        >
          <InputB
            id={"permission-search"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value);
              if (e.target.value === "") {
                setPermissionsArray(permissions || []);
                return;
              }
              setPermissionsArray(
                permissions
                  ? permissions.filter((permission) => {
                      return permission.name
                        .toLowerCase()
                        .includes(e.target.value);
                    })
                  : []
              );
            }}
            value={search}
            label={"Search"}
          >
            <SearchIcon />
          </InputB>
          <MyButton text="Search" sx={{ marginBottom: "10px" }} />
        </Box>
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          overflowY: "auto",
          marginBottom: "10px",
        }}
      >
        {permissionsArray?.map((permission) => {
          const select = !!selected.find((item) => item.id === permission.id);
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "7px",
                padding: "5px 0px",
                borderRadius: BORDERRADIUS.PRIMARY,
                "&:hover": {
                  backgroundColor: COLORS.SECONDARY_BG,
                },
              }}
              key={permission.id}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    position: "relative",
                    backgroundColor: select
                      ? COLORS.GREEN
                      : COLORS.SECONDARY_BG,
                    border: `1px solid ${select ? COLORS.GREEN : COLORS.GREY}`,
                    borderRadius: "2px",
                    margin: "0 15px",
                  }}
                  onClick={() => {
                    addSelected(permission);
                  }}
                >
                  {select && (
                    <DoneIcon
                      sx={{
                        position: "absolute",
                        top: "-12.5%",
                        left: "-12.5%",
                        width: "125%",
                        height: "125%",
                        color: "white",
                      }}
                    />
                  )}
                </Box>
                <Box sx={{ lineHeight: "24px", color: COLORS.DARKGREY }}>
                  {permission.name}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  marginRight: "10px",
                }}
              >
                <Tooltip title={permission.description} placement="top" arrow>
                  <InfoOutlinedIcon sx={{ color: COLORS.ORANGE }} />
                </Tooltip>
                <ModeEditIcon sx={{ height: "22px", margintop: "1px" }} />
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>
          Selected: <Typography component="span">{selected.length}</Typography>
        </Typography>
        <MyButton
          text="Add to role"
          sx={{
            backgroundColor: COLORS.BLUE,
            textWrap: "nowrap",
            "&:hover": { backgroundColor: COLORS.BLUE + "b4" },
          }}
          disabled={!selected.length}
          onClick={addPermissionsHandler}
        ></MyButton>
      </Box>
    </Box>
  );
};
