import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { IUser } from "../../../../types/user.type";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AlertContext } from "../../../../hooks/notification/useAlert";
import { Loader } from "../../../../components/loader/loader";
import { useGetProfit } from "../../../../hooks/player/playerProfit/useGetProfit";
import { EmployeeContext } from "../../../../hooks/auth/useAuth";

export const Profit = () => {
  const navigator = useNavigate();
  const { logout } = useContext(EmployeeContext);
  const [user]: IUser[] = useOutletContext();
  const { alertHandler } = useContext(AlertContext);
  const { data, isLoading, error, isSuccess } = useGetProfit(user.id);
  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
      if (error.message === "Unathorized") {
        logout();
        navigator("/");
      }
    }
  }, [error]);
  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        position: "relative",
      }}
    >
      {isLoading && <Loader />}
      {isSuccess && data && (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Deposits: </Box>
            <Box sx={{ fontWeight: 500, color: COLORS.DARKGREY }}>
              {(+data.deposits).toFixed(2)}
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Withdrawals: </Box>
            <Box sx={{ fontWeight: 500, color: COLORS.DARKGREY }}>
              {(+data.withdrawals).toFixed(2)}
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Turnover: </Box>
            <Box sx={{ fontWeight: 500, color: COLORS.DARKGREY }}>
              {(+data.turnover).toFixed(2)}
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Profit: </Box>
            <Box sx={{ fontWeight: 500, color: COLORS.DARKGREY }}>
              {(+data.profit).toFixed(2)}
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Bonus amount: </Box>
            <Box sx={{ fontWeight: 500, color: COLORS.DARKGREY }}>
              {(+data.bonus_amount).toFixed(2)}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
