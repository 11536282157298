import { Box } from "@mui/material";
import { InputCheckbox } from "../../../../../components/inputcheckbox/inputcheckbox";
import { InputDate } from "../../../../../components/inputdate/inputdate";

interface IActivityProps {
  deposit:
    | {
        type: "less" | "more";
        date: string;
      }
    | undefined;
  game:
    | {
        type: "less" | "more";
        date: string;
      }
    | undefined;
  gameActivity: boolean;
  depositActivity: boolean;
  gameHandler: () => void;
  depositHandler: () => void;
  depositTypetHandler: (type: "less" | "more") => void;
  gameTypetHandler: (type: "less" | "more") => void;
  dateGameHandler: (date: Date) => void;
  dateDepositHandler: (date: Date) => void;
}

export const ActivityFields = ({
  deposit,
  game,
  gameActivity,
  depositActivity,
  gameHandler,
  depositHandler,
  depositTypetHandler,
  gameTypetHandler,
  dateGameHandler,
  dateDepositHandler,
}: IActivityProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
        width: "420px",
      }}
    >
      <InputCheckbox
        id={"deposit_activity"}
        label={"Deposit activity"}
        checked={depositActivity}
        onChange={depositHandler}
      />
      {depositActivity && (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            <InputCheckbox
              id={"deposit_activity_before"}
              label={"Before"}
              checked={deposit?.type === "less"}
              onChange={() => {
                depositTypetHandler("less");
              }}
            />
            <InputCheckbox
              id={"deposit_activity_after"}
              label={"After"}
              checked={deposit?.type === "more"}
              onChange={() => {
                depositTypetHandler("more");
              }}
            />
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <InputDate
              id={"deposit_date"}
              label="Date"
              disabled={false}
              value={
                deposit
                  ? deposit.date === ""
                    ? deposit.date
                    : new Date(+deposit.date)
                  : ""
              }
              onChange={dateDepositHandler}
            />
          </Box>
        </>
      )}
      <InputCheckbox
        id={"game_activity"}
        label={"Game activity"}
        checked={gameActivity}
        onChange={gameHandler}
      />
      {gameActivity && (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            <InputCheckbox
              id={"game_activity_before"}
              label={"Before"}
              checked={game?.type === "less"}
              onChange={() => {
                gameTypetHandler("less");
              }}
            />
            <InputCheckbox
              id={"game_activity_after"}
              label={"After"}
              checked={game?.type === "more"}
              onChange={() => {
                gameTypetHandler("more");
              }}
            />
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <InputDate
              id={"game_date"}
              label="Date"
              disabled={false}
              value={
                game
                  ? game.date === ""
                    ? game.date
                    : new Date(+game.date)
                  : ""
              }
              onChange={dateGameHandler}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
