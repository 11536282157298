import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../../errors/AlertError";
import { playerKycService } from "../../../services/playerKYC.service";

export const useVerify = (
  openHandler: (message: string, error?: string) => void,
  id: string
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (serialNumber: string) =>
      playerKycService.verify(serialNumber, id),
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] === "KYC_status" || query.queryKey[0] === "KYC") &&
          query.queryKey[1] === id,
      });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
