export function makeUniq<T extends { [key: string]: any }>(
  arr: T[],
  uniqKey: string
) {
  const seen: { [key: string]: any } = {};
  const result = [];

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    const key = `${uniqKey}_${item[uniqKey]}`;
    if (!seen[key]) {
      seen[key] = item[uniqKey];
      result.push(item);
    }
  }
  return result;
}
