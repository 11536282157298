import { Box, Typography } from "@mui/material";
import React from "react";
import { COLORS, BORDERRADIUS } from "../../../consts";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IEmployee } from "../../../types/employee.type";
import { Loader } from "../../../components/loader/loader";

type IEmployeeProps = {
  employees: IEmployee[] | undefined;
  isLoading: boolean;
};

export const Employee = ({ employees, isLoading }: IEmployeeProps) => {
  return (
    <Box
      sx={{
        flex: "1 0 calc(100% - 360px)",
        height: "100%",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        padding: "10px",
        overflowX: "auto",
        position: "relative",
      }}
    >
      {isLoading && <Loader />}
      <Box
        sx={{
          width: "100%",
          backgroundColor: COLORS.GREY,
          color: "#000",
          borderRadius: BORDERRADIUS.PRIMARY,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px 15px",
          marginBottom: "10px",
        }}
      >
        <Box sx={{ width: "30px", textAlign: "center" }}></Box>
        <Box sx={{ width: "225px", textAlign: "center" }}>Name</Box>
        <Box sx={{ width: "60px", textAlign: "center" }}>Status</Box>
        <Box sx={{ width: "200px", textAlign: "center" }}>Email</Box>
        <Box sx={{ width: "180px", textAlign: "center" }}>Role</Box>
        <Box sx={{ width: "100px", textAlign: "center" }}></Box>
      </Box>
      {!employees ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            height: "calc(100% - 45px)",
          }}
        >
          <PersonSearchIcon
            sx={{ display: "block", width: "100px", height: "100px" }}
          />
          <Typography
            variant="h5"
            sx={{ maxWidth: "170px", textAlign: "center" }}
          >
            Employees list is empty
          </Typography>
        </Box>
      ) : (
        employees.map((employee) => {
          return (
            <Box
              sx={{
                width: "100%",
                color: COLORS.DARKGREY,
                borderBottom: `1px solid ${COLORS.DARKGREY}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px 15px",
                marginBottom: "5px",
              }}
              key={employee.guid}
            >
              <Box
                sx={{
                  width: "30px",
                  textAlign: "center",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <img src={employee.picture} alt={employee.displayName} />
              </Box>
              <Box sx={{ width: "225px", textAlign: "center" }}>
                {employee.displayName}
              </Box>
              <Box sx={{ width: "60px", height: "24px", textAlign: "center" }}>
                {true ? (
                  <CheckCircleIcon sx={{ color: COLORS.GREEN }} />
                ) : (
                  <UnpublishedIcon sx={{ color: COLORS.DARKGREY }} />
                )}
              </Box>
              <Box sx={{ width: "200px", textAlign: "center" }}>
                {employee.email}
              </Box>
              <Box sx={{ width: "180px", textAlign: "center" }}>
                {employee.role ? employee.role.name : "Unassigned"}
              </Box>
              <Box
                sx={{
                  width: "100px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <MoreVertIcon sx={{ cursor: "pointer" }} />
              </Box>
            </Box>
          );
        })
      )}
    </Box>
  );
};
