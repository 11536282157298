import React from "react";
import { InputCheckboxStyled } from "./inputcheckbox.styled";
interface IInputCheckbox {
  id: string;
  label: string;
  onChange: () => void;
  checked?: boolean;
}
export const InputCheckbox = ({
  id,
  label,
  onChange,
  checked = false,
}: IInputCheckbox) => {
  return (
    <InputCheckboxStyled>
      <label htmlFor={id}>{label}</label>
      <input id={id} type="checkbox" checked={checked} onChange={onChange} />
      <span></span>
    </InputCheckboxStyled>
  );
};
