import React from "react";
import { MyInput } from "./inputB.styled";
import { Box } from "@mui/material";
import { COLORS } from "../../consts";
interface IInputProps {
  id: string;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  value: string | number;
  label: string;
  disabled?: boolean;
  children?: React.ReactNode;
  error?: string;
}
export const InputB = ({
  id,
  onChange,
  value,
  label,
  disabled,
  children,
  error,
}: IInputProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flex: "1 1 auto",
        marginBottom: "10px",
        flexDirection: "column",
      }}
    >
      <MyInput svg={children ? true : false}>
        {children ? children : null}
        <label htmlFor={id}>{label}</label>
        <input id={id} onChange={onChange} value={value} disabled={disabled} />
      </MyInput>
      {error && (
        <Box sx={{ fontSize: "10px", color: COLORS.RED, padding: "1px 16px" }}>
          {error}
        </Box>
      )}
    </Box>
  );
};
