import { useMutation, useQueryClient } from "@tanstack/react-query";
import { userService } from "../../../services/userService";
import { AlertError } from "../../../errors/AlertError";

export const useChangeContacts = (
  id: string,
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (data: { email: string; phone: string | null }) =>
      userService.changeContacts(data, id),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["user", id] });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
