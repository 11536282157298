export const COLORS = {
  PRIMARY: "#9c9c9c",
  PRIMARY_BG: "#E9EAEE",
  SECONDARY_BG: "#D1D2D4",
  SECONDARY: "#cecece",
  GREY: "#898989",
  DARKGREY: "#474646",
  LIGHTGREY: "#eaeaea",
  GREEN: "#5fc400",
  ORANGE: "#fe9300",
  RED: "#ed0000",
  BLUE: "#1976d2",
  SEMITRANPARENT: "#00000038",
  PURPLE: "#8900ed",
};
