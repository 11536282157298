import React from "react";
import { IBrands } from "../../types/brand.type";

const InitBrands: IBrands[] = [];

export const useBrands = () => {
  const [brands, setBrands] = React.useState(InitBrands);
  const updateState = (data: IBrands[]) => {
    setBrands(data);
  };

  return {
    brands,
    updateState,
  };
};

export interface IBrandsContext {
  brands: IBrands[];
  updateState: (data: IBrands[]) => void;
}

const defaultBrandContextState = {
  brands: [],
  updateState: (data: IBrands[]) => {},
} as IBrandsContext;

export const BrandContext = React.createContext(defaultBrandContextState);
