import { useQuery } from "@tanstack/react-query";
import { paymentsService } from "../../services/paymentsService";

export const useGetPendingCount = () => {
  return useQuery({
    queryFn: () => paymentsService.getCountPendingPayments(),
    queryKey: ["pendingCount"],
    staleTime: 0,
    gcTime: 0,
  });
};
