import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { InputB } from "../../../components/input-b/inputB";
import { InputDate } from "../../../components/inputdate/inputdate";
import { EditButton } from "../../../components/editbutton/editbutton";
import { Loader } from "../../../components/loader/loader";
import { MyButton } from "../../../components/button/button";
import { useOutletContext } from "react-router-dom";
import { IUser } from "../../../types/user.type";
import { AlertContext } from "../../../hooks/notification/useAlert";
import { useChangeProfile } from "../../../hooks/player/playerInfo/useChangeProfile";
import { useFormik } from "formik";
import { useGetKYC } from "../../../hooks/player/playerKyc/useGetKycImages";
import { useGetImage } from "../../../hooks/player/playerKyc/useGetImage";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { useVerify } from "../../../hooks/player/playerKyc/useVerify";
import { InputCheckbox } from "../../../components/inputcheckbox/inputcheckbox";
import { useArchive } from "../../../hooks/player/playerKyc/useArchive";
import { useUnarchive } from "../../../hooks/player/playerKyc/useUnarchive";

export const KYCPage = () => {
  const [edit, setEdit] = React.useState(false);
  const [archive, setArchive] = React.useState(false);
  const [images, setImages] = React.useState<string[]>([]);
  const [choose, setChoose] = React.useState(0);
  const [bigImage, setBigImage] = React.useState({ src: "", alt: "" });
  const [user]: IUser[] = useOutletContext();
  const { alertHandler } = React.useContext(AlertContext);
  const [serialNumber, setSerialNumber] = React.useState(
    user.person_info
      ? user.person_info.serial_number
        ? user.person_info.serial_number
        : ""
      : ""
  );
  const { data, isError, error } = useGetKYC(user.id);
  const { mutate: change, isPending } = useChangeProfile(user.id, alertHandler);
  const { mutate: verify } = useVerify(alertHandler, user.id);
  const { mutate: archivate, isPending: archivating } = useArchive(
    alertHandler,
    user.id
  );
  const { mutate: unarchivate, isPending: unarchivating } = useUnarchive(
    alertHandler,
    user.id
  );
  const switchArchiveHandler = () => {
    setArchive(!archive);
  };
  const initialValues = user.person_info
    ? {
        name: user.person_info.name,
        surname: user.person_info.surname,
        secondname: user.person_info.secondname,
        date: user.person_info.date,
        country: "country",
        city: "city",
        serial_number: user.person_info.serial_number,
      }
    : {
        name: "",
        surname: "",
        secondname: "",
        date: "",
        country: "",
        city: "",
      };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      change({
        name: values.name,
        surname: values.surname,
        secondname: values.secondname,
        date: values.date.toString(),
      });
      setEdit(false);
    },
  });
  const dateHandler = (date: Date) => {
    const newDate = date.valueOf();
    formik.setValues({ ...formik.values, date: newDate.toString() });
  };
  const closeHandler = () => {
    formik.setValues(initialValues);
    setEdit(false);
  };
  const openHandler = () => {
    setEdit(true);
  };
  const activeImageHandler = (src: string, alt: string) => {
    setBigImage({ src, alt });
  };
  const serialHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSerialNumber(e.target.value);
  };
  const verifyHandler = () => {
    verify(serialNumber);
  };
  const clickHandler = (image: string) => {
    if (!image || archivating || unarchivating) {
      return;
    }
    if (archive) {
      unarchivate(image);
      return;
    }
    archivate(image);
  };
  useEffect(() => {
    if (data) {
      if (archive) {
        setChoose(0);
        setImages(data.archived_imgs.split(","));
        return;
      }
      setChoose(0);
      setImages(data.verification_imgs.split(","));
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      if (archive) {
        if (data.archived_imgs) {
          setChoose(0);
          setImages(data.archived_imgs.split(","));
          return;
        }
        setChoose(0);
        setBigImage({ src: "", alt: "" });
        setImages([]);
        return;
      }
      setChoose(0);
      setImages(data.verification_imgs.split(","));
      return;
    }
    setImages([]);
  }, [archive]);
  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
    }
  }, [isError]);
  return (
    <Box
      sx={{
        display: "flex",
        height: "calc(100vh - 70px)",
        width: "100%",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          flex: "1 1 auto",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          position: "relative",
          padding: "10px 24px 60px",
          margin: "10px",
          maxWidth: "400px",
        }}
      >
        {isPending && <Loader />}
        <EditButton onClick={openHandler} />
        <form onSubmit={formik.handleSubmit}>
          <InputB
            id="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            label="Name"
            disabled={!edit}
          />
          <InputB
            id="surname"
            onChange={formik.handleChange}
            value={formik.values.surname}
            label="Surname"
            disabled={!edit}
          />
          <InputB
            id="secondname"
            onChange={formik.handleChange}
            value={formik.values.secondname}
            label="Secondname"
            disabled={!edit}
          />
          <InputDate
            id="date"
            label="Date"
            value={new Date(+formik.values.date)}
            onChange={dateHandler}
            disabled={!edit}
          />
          <InputB
            id="country"
            onChange={formik.handleChange}
            value={formik.values.country}
            label="Country"
            disabled={!edit}
          />
          <InputB
            id="city"
            onChange={formik.handleChange}
            value={formik.values.city}
            label="City"
            disabled={!edit}
          />
          {edit && (
            <Box
              sx={{
                display: "flex",
                gap: "14px",
                justifyContent: "flex-end",
                position: "absolute",
                bottom: "20px",
                right: "20px",
              }}
            >
              <MyButton
                type="button"
                text="Отмена"
                onClick={closeHandler}
                cancel
              />
              <MyButton type="submit" text="Сохранить" />
            </Box>
          )}
        </form>
      </Box>
      <Box
        sx={{
          flex: "1 1 auto",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          padding: "10px 16px",
          margin: "10px",
          height: "calc(100% - 24px)",
          position: "relative",
        }}
      >
        {images ? (
          <>
            <Box>
              <Box sx={{ height: "62px", display: "flex", gap: "20px" }}>
                <Box sx={{ width: "300px" }}>
                  <InputB
                    id="serialNumber"
                    onChange={serialHandler}
                    value={serialNumber}
                    label="Serial Number"
                  />
                </Box>
                <Box
                  sx={{
                    height: "51.5px",
                    flex: "1 1 auto",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "20px",
                    paddingTop: "18px",
                  }}
                >
                  <MyButton
                    type="submit"
                    text="Verify"
                    sx={{
                      backgroundColor: COLORS.GREEN,
                      ":hover": {
                        backgroundColor: COLORS.GREEN + "c4",
                      },
                    }}
                    onClick={verifyHandler}
                  />
                  <MyButton
                    type="button"
                    text="Reject"
                    sx={{
                      backgroundColor: COLORS.RED,
                      ":hover": {
                        backgroundColor: COLORS.RED + "c4",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "300px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Верификация: </Typography>
                  <Box
                    sx={{
                      padding: "3px 16px",
                      border: `1px solid ${
                        data?.verification ? COLORS.GREEN : COLORS.ORANGE
                      }`,
                      fontSize: "14px",
                      color: data?.verification ? COLORS.GREEN : COLORS.ORANGE,
                    }}
                  >
                    {data?.verification ? "Верифицирован" : "Не верифицирован"}
                  </Box>
                </Box>
                <Box sx={{ width: "200px" }}>
                  <InputCheckbox
                    id={"archive"}
                    label={"Switch to archive"}
                    checked={archive}
                    onChange={switchArchiveHandler}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: "calc(100% - 101px)",
                borderRadius: BORDERRADIUS.PRIMARY,
                backgroundColor: COLORS.SEMITRANPARENT,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "calc(100% - 130px)",
                  padding: "10px",
                }}
              >
                {bigImage.src && (
                  <KYCImage src={bigImage.src} alt={bigImage.alt} />
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    position: "absolute",
                    top: "20px",
                    right: "30px",
                    zIndex: 3,
                    padding: "5px 10px",
                    borderRadius: BORDERRADIUS.PRIMARY,
                    backgroundColor: COLORS.PRIMARY_BG,
                    fontSize: "12px",
                    color: COLORS.DARKGREY,
                    opacity: choose ? 1 : 0.5,
                    transition: "opacity 0.3s ease-out",
                  }}
                  onClick={() => {
                    clickHandler(images[choose]);
                  }}
                >
                  {archive ? "Unarchive" : "Archive"}
                  {archive ? (
                    <UnarchiveIcon sx={{ fontSize: "20px" }} />
                  ) : (
                    <ArchiveIcon sx={{ fontSize: "20px" }} />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  height: "130px",
                  backgroundColor: COLORS.SEMITRANPARENT,
                  borderBottomLeftRadius: BORDERRADIUS.PRIMARY,
                  borderBottomRightRadius: BORDERRADIUS.PRIMARY,
                  display: "flex",
                  justifyContent: "center",
                  padding: "15px 16px",
                  gap: "20px",
                }}
              >
                {images.map((image, index) => {
                  return (
                    <KycSmallImage
                      key={image}
                      image={image}
                      id={user.id}
                      active={index === choose}
                      setActive={activeImageHandler}
                      changeImage={() => {
                        setChoose(index);
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
          </>
        ) : (
          <Loader />
        )}
      </Box>
    </Box>
  );
};

export const KycSmallImage = ({
  image,
  id,
  active,
  setActive,
  changeImage,
}: {
  image: string;
  id: string;
  active: boolean;
  setActive: (src: string, alt: string) => void;
  changeImage: () => void;
}) => {
  const [src, setSrc] = useState<string | null>(null);
  const { data } = useGetImage(id, image);
  useEffect(() => {
    if (data) {
      setSrc(data);
      if (active) {
        setActive(data, image);
      }
    }
  }, [data]);
  useEffect(() => {
    if (active && src) {
      setActive(src, image);
    }
  }, [active]);
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        transform: `scale(${active ? "1.3" : "1"})`,
        transition: "transform 0.3s ease-out ",
      }}
      onClick={changeImage}
    >
      {src && <img src={src} alt={image} />}
    </Box>
  );
};

export const KYCImage = ({ src, alt }: { src: string; alt: string }) => {
  return <>{src && <img src={src} alt={alt} />}</>;
};
