import { useQuery } from "@tanstack/react-query";
import { paymentsService } from "../../services/paymentsService";

export const useGetPayments = (query: string) => {
  return useQuery({
    queryFn: () => paymentsService.getPayments(query),
    queryKey: ["payments", query],
    staleTime: 0,
    gcTime: 0,
    retry: 1,
  });
};
