import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BORDER, BORDERRADIUS, COLORS } from "../../../consts";
import DoneIcon from "@mui/icons-material/Done";
import { MyButton } from "../../../components/button/button";
import { useGetProvidedSlots } from "../../../hooks/lobby/useGetProvidedSlots";
import {
  IProvidedGame,
  IProvidedProvider,
  ISelectSlot,
} from "../../../types/lobby.type";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import ComputerIcon from "@mui/icons-material/Computer";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CancelIcon from "@mui/icons-material/Cancel";
import { ModalTemplate } from "../../../components/modal/modal.template";
import { InputImage } from "../../../components/inputImage/inputImage";
import { useSaveProvider } from "../../../hooks/lobby/useSaveProvider";
import { AlertContext } from "../../../hooks/notification/useAlert";
import { EmployeeContext } from "../../../hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { useSaveSlots } from "../../../hooks/lobby/useSaveSlots";

export const AllSlots = () => {
  const { data, error } = useGetProvidedSlots();
  const { alertHandler } = useContext(AlertContext);
  const { logout } = useContext(EmployeeContext);
  const navigator = useNavigate();
  const { mutate: saveSlots } = useSaveSlots(alertHandler);
  const { mutate: saveProvider } = useSaveProvider(alertHandler);
  const [selected, setSelected] = useState<{
    providerInternalId: number | null;
    slots: ISelectSlot[];
  }>({ providerInternalId: null, slots: [] });
  const [slots, setSlots] = useState<ISelectSlot[]>([]);
  const chooseProviderHandler = (
    providerID: number,
    games: IProvidedGame[]
  ) => {
    const selectSlots = games.map((game) => {
      return {
        ...game,
        popular: false,
        newGame: false,
        fruits: false,
        seven: false,
        books: false,
        buy_fs: false,
        recommended: false,
      };
    });
    setSlots(selectSlots);
    setSelected({ providerInternalId: providerID, slots: [] });
  };
  const selectAllHandler = () => {
    if (slots.length > 0) {
      setSelected({
        providerInternalId: selected.providerInternalId,
        slots: slots,
      });
    }
  };
  const clearHandler = () => {
    setSelected({ providerInternalId: null, slots: [] });
    setSlots([]);
  };
  const toggleOneHandler = (slot: ISelectSlot) => {
    if (selected.slots.some((select) => select.gm_bk_id === slot.gm_bk_id)) {
      setSelected({
        providerInternalId: selected.providerInternalId,
        slots: selected.slots.filter(
          (select) => select.gm_bk_id !== slot.gm_bk_id
        ),
      });
      return;
    }
    setSelected({
      providerInternalId: selected.providerInternalId,
      slots: [...selected.slots, slot],
    });
  };
  const toggleFilterHandler = (slot: ISelectSlot) => {
    if (selected.slots.some((select) => select.gm_bk_id === slot.gm_bk_id)) {
      setSlots(
        slots.map((slotItem) => {
          if (slotItem.gm_bk_id === slot.gm_bk_id) {
            return slot;
          }
          return slotItem;
        })
      );
      setSelected({
        providerInternalId: selected.providerInternalId,
        slots: selected.slots.map((slotItem) => {
          if (slotItem.gm_bk_id === slot.gm_bk_id) {
            return slot;
          }
          return slotItem;
        }),
      });
      return;
    }
    setSelected({
      providerInternalId: selected.providerInternalId,
      slots: [...selected.slots, slot],
    });
    setSlots(
      slots.map((slotItem) => {
        if (slotItem.gm_bk_id === slot.gm_bk_id) {
          return slot;
        }
        return slotItem;
      })
    );
  };
  const [provider, setProvider] = useState<{
    provider: IProvidedProvider | undefined;
    provider_image: File | null;
  }>({
    provider: undefined,
    provider_image: null,
  });
  const [modal, setModal] = useState(false);
  const openModalHandler = () => {
    setModal(true);
  };
  const closeModalHandler = () => {
    setModal(false);
  };

  const addProviderHandler = (provider: IProvidedProvider) => {
    openModalHandler();
    setProvider({ provider: provider, provider_image: null });
  };
  const imageUploadHandler = (value: FileList | null) => {
    if (value === null) {
      return;
    }
    setProvider({ ...provider, provider_image: value[0] });
  };
  const confirmHandler = () => {
    if (!provider.provider_image || !provider.provider) {
      return;
    }
    const formData = new FormData();
    formData.append(
      "provider",
      JSON.stringify({
        gr_id: provider.provider.gr_id,
        gr_title: provider.provider.gr_title,
      })
    );
    formData.append("provider_image", provider.provider_image);
    saveProvider(formData);
  };

  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
      if (error.message === "Unathorized") {
        logout();
        navigator("/");
      }
    }
  }, [error]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          padding: "10px 16px",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          marginBottom: "10px",
        }}
      >
        <Typography>Selected: {selected.slots.length}</Typography>
        <MyButton text={"Select all"} onClick={selectAllHandler} />
        <MyButton text={"Clear"} cancel onClick={clearHandler} />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <MyButton
            text={"Save"}
            disabled={selected.slots.length === 0}
            sx={{
              backgroundColor: COLORS.GREEN,
              ":hover": { backgroundColor: COLORS.GREEN + "d0" },
            }}
            onClick={() => {
              saveSlots(selected);
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          height: "calc(100% - 63px)",
          width: "100%",
        }}
      >
        <Box
          sx={{
            padding: "10px 16px",
            backgroundColor: COLORS.PRIMARY_BG,
            borderRadius: BORDERRADIUS.PRIMARY,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: COLORS.GREY,
              width: "100%",
              padding: "6px 16px",
              fontSize: "13px",
              color: COLORS.DARKGREY,
              borderRadius: BORDERRADIUS.PRIMARY,
              textAlign: "center",
            }}
          >
            <Box sx={{ width: "80px" }}>Internal ID</Box>
            <Box sx={{ width: "200px" }}>Name</Box>
            <Box sx={{ width: "80px" }}>Game count</Box>
            <Box sx={{ width: "150px", marginRight: "20px" }}></Box>
          </Box>
          <Box
            sx={{
              height: "calc(100% - 32px)",
              overflow: "auto",
            }}
          >
            {data &&
              data.map((provider) => {
                return (
                  <ProviderItem
                    provider={provider}
                    buttonHandler={addProviderHandler}
                    chooseHandler={chooseProviderHandler}
                    key={provider.gr_id}
                  />
                );
              })}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            overflow: "auto",
            padding: "10px 16px",
            backgroundColor: COLORS.PRIMARY_BG,
            borderRadius: BORDERRADIUS.PRIMARY,
          }}
        >
          {slots.length === 0 ? (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: COLORS.SEMITRANPARENT }} variant="h3">
                Select provider to view games
              </Typography>
            </Box>
          ) : (
            slots.map((game) => {
              return (
                <ProvidedGameItem
                  selectOne={toggleOneHandler}
                  game={game}
                  key={game.gm_bk_id}
                  select={selected.slots.some(
                    (select) => select.gm_bk_id === game.gm_bk_id
                  )}
                  toggleFilter={toggleFilterHandler}
                />
              );
            })
          )}
        </Box>
        <ModalTemplate
          open={modal}
          close={closeModalHandler}
          confirm={confirmHandler}
          heading={`Save ${provider.provider?.gr_title}`}
        >
          <Typography
            variant="h6"
            sx={{ color: COLORS.GREY, marginBottom: "10px" }}
          >
            For saving {provider.provider?.gr_title} provider, please, upload
            provider image
          </Typography>
          <InputImage
            id={"provider_image"}
            label={"Provider Image"}
            upload={imageUploadHandler}
          />
          <Box
            sx={{ marginBottom: "10px", fontSize: "12px", textAlign: "center" }}
          >
            {provider.provider_image && provider.provider_image.name}
          </Box>
        </ModalTemplate>
      </Box>
    </Box>
  );
};

interface IProviderItemProps {
  provider: IProvidedProvider;
  chooseHandler: (providerID: number, games: IProvidedGame[]) => void;
  buttonHandler: (provider: IProvidedProvider) => void;
}

export const ProviderItem = ({
  provider,
  chooseHandler,
  buttonHandler,
}: IProviderItemProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 16px",
        textAlign: "center",
        color: COLORS.DARKGREY,
        borderRadius: BORDERRADIUS.PRIMARY,
        cursor: "pointer",
        ":hover": {
          backgroundColor: "#FFFFFF4d",
        },
      }}
      onClick={() => {
        chooseHandler(provider.gr_id, provider.games);
      }}
    >
      <Box sx={{ width: "80px" }}>{provider.gr_id}</Box>
      <Box sx={{ width: "200px" }}>{provider.gr_title}</Box>
      <Box sx={{ width: "80px" }}>{provider.games.length}</Box>
      <Box sx={{ width: "150px" }}>
        <MyButton
          text="Add provider"
          sx={{
            backgroundColor: COLORS.GREEN,
            "&:hover": { backgroundColor: COLORS.GREEN + "d0" },
          }}
          //@ts-ignore
          onClick={(e: any) => {
            e.stopPropagation();
            buttonHandler(provider);
          }}
        />
      </Box>
    </Box>
  );
};

interface IProvidedGameItemProps {
  game: ISelectSlot;
  select: boolean;
  selectOne: (game: ISelectSlot) => void;
  toggleFilter: (slot: ISelectSlot) => void;
}

export const ProvidedGameItem = ({
  game,
  select,
  selectOne,
  toggleFilter,
}: IProvidedGameItemProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        padding: "5px 10px",
        height: "90px",
      }}
    >
      <Box
        sx={{
          border: BORDER.PRIMARY,
          borderColor: select ? COLORS.GREEN : COLORS.GREY,
          borderRadius: "2px",
          backgroundColor: select ? COLORS.GREEN : COLORS.SECONDARY,
          position: "relative",
          width: "14px",
          height: "14px",
          marginRight: "15px",
          flexShrink: 0,
          cursor: "pointer",
        }}
        onClick={() => {
          selectOne(game);
        }}
      >
        {select && (
          <DoneIcon
            sx={{
              position: "absolute",
              top: "-12.5%",
              left: "-12.5%",
              width: "125%",
              height: "125%",
              color: "white",
            }}
          />
        )}
      </Box>
      <Box sx={{ width: "50px" }}>{game.gm_bk_id}</Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "100px",
          overflow: "hidden",
        }}
      >
        <img
          style={{
            width: "100%",
            height: "73.66px",
            objectFit: "cover",
            borderRadius: BORDERRADIUS.PRIMARY,
          }}
          src={`https://int.apiforb2b.com/game/icons/${
            game.icons.filter((i) => i.ic_w === 300 || i.ic_w === 220)[0]
              .ic_name
          }`}
          alt={
            game.icons.filter((i) => i.ic_w === 300 || i.ic_w === 220)[0]
              .ic_name
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "200px",
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {game.gm_title}
      </Box>
      <Box
        sx={{
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {game.gm_is_pc && game.gm_is_mobile ? (
          <>
            <MobileFriendlyIcon />
            <ComputerIcon sx={{ fontSize: "28px" }} />
          </>
        ) : game.gm_is_pc ? (
          <ComputerIcon />
        ) : (
          <MobileFriendlyIcon />
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "50px",
          flex: "1 1 auto",
          textAlign: "center",
        }}
      >
        {game.gm_ln}
      </Box>
      <Box
        sx={{
          width: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {game.gm_is_fs ? <CardGiftcardIcon /> : <CancelIcon />}
      </Box>
      <Box
        sx={{ width: "100%", display: "flex", gap: "10px", flexWrap: "wrap" }}
      >
        <MyButton
          text="popular"
          sx={{
            backgroundColor: game.popular ? COLORS.PURPLE : COLORS.SECONDARY,
            ":hover": {
              backgroundColor: game.popular
                ? COLORS.PURPLE + "d0"
                : COLORS.SECONDARY + "d0",
            },
          }}
          onClick={() => {
            toggleFilter({ ...game, popular: !game.popular });
          }}
        />
        <MyButton
          text="new"
          sx={{
            backgroundColor: game.newGame ? COLORS.PURPLE : COLORS.SECONDARY,
            ":hover": {
              backgroundColor: game.newGame
                ? COLORS.PURPLE + "d0"
                : COLORS.SECONDARY + "d0",
            },
          }}
          onClick={() => {
            toggleFilter({ ...game, newGame: !game.newGame });
          }}
        />
        <MyButton
          text="recommended"
          sx={{
            backgroundColor: game.recommended
              ? COLORS.PURPLE
              : COLORS.SECONDARY,
            ":hover": {
              backgroundColor: game.recommended
                ? COLORS.PURPLE + "d0"
                : COLORS.SECONDARY + "d0",
            },
          }}
          onClick={() => {
            toggleFilter({ ...game, recommended: !game.recommended });
          }}
        />
        <MyButton
          text="fruits"
          sx={{
            backgroundColor: game.fruits ? COLORS.PURPLE : COLORS.SECONDARY,
            ":hover": {
              backgroundColor: game.fruits
                ? COLORS.PURPLE + "d0"
                : COLORS.SECONDARY + "d0",
            },
          }}
          onClick={() => {
            toggleFilter({ ...game, fruits: !game.fruits });
          }}
        />
        <MyButton
          text="777"
          sx={{
            backgroundColor: game.seven ? COLORS.PURPLE : COLORS.SECONDARY,
            ":hover": {
              backgroundColor: game.seven
                ? COLORS.PURPLE + "d0"
                : COLORS.SECONDARY + "d0",
            },
          }}
          onClick={() => {
            toggleFilter({ ...game, seven: !game.seven });
          }}
        />
        <MyButton
          text="books"
          sx={{
            backgroundColor: game.books ? COLORS.PURPLE : COLORS.SECONDARY,
            ":hover": {
              backgroundColor: game.books
                ? COLORS.PURPLE + "d0"
                : COLORS.SECONDARY + "d0",
            },
          }}
          onClick={() => {
            toggleFilter({ ...game, books: !game.books });
          }}
        />
        <MyButton
          text="freespins buy"
          sx={{
            backgroundColor: game.buy_fs ? COLORS.PURPLE : COLORS.SECONDARY,
            ":hover": {
              backgroundColor: game.buy_fs
                ? COLORS.PURPLE + "d0"
                : COLORS.SECONDARY + "d0",
            },
          }}
          onClick={() => {
            toggleFilter({ ...game, buy_fs: !game.buy_fs });
          }}
        />
      </Box>
    </Box>
  );
};
