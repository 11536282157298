import React from "react";
import { Button, ButtonOwnProps } from "@mui/material";
import { COLORS } from "../../consts";
interface IMyButton extends ButtonOwnProps {
  text: string;
  type?: "button" | "submit";
  disabled?: boolean;
  cancel?: boolean;
  onClick?: () => void;
}
export const MyButton = (props: IMyButton) => {
  const { type, text, disabled, cancel, onClick, sx }: IMyButton = props;
  return (
    <Button
      sx={{
        padding: "5px 16px",
        fontSize: "13px",
        color: "#f8f8f8",
        backgroundColor: cancel
          ? COLORS.SECONDARY_BG + " !important"
          : COLORS.ORANGE + " !important",
        height: "100%",
        ...sx,
      }}
      type={type || "submit"}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
