import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { InputCheckbox } from "../../../../components/inputcheckbox/inputcheckbox";
import { useGetRestrictions } from "../../../../hooks/player/playerInfo/useGetRestrictions";
import { useOutletContext } from "react-router-dom";
import { AlertContext } from "../../../../hooks/notification/useAlert";
import { IUser } from "../../../../types/user.type";
import { useChangeRestrictions } from "../../../../hooks/player/playerInfo/useChangeRestrictions";
import { Loader } from "../../../../components/loader/loader";

export const Restrictions = () => {
  const [user]: IUser[] = useOutletContext();
  const { alertHandler } = useContext(AlertContext);
  const { data, isLoading } = useGetRestrictions(user.id);
  // console.log(data);
  const [restrictions, setRestrictions] = useState(
    data
      ? {
          withdrawal: data.withdrawal,
          deposits: data.deposits,
          slots: data.slots,
          login: data.login,
        }
      : { withdrawal: false, deposits: false, slots: false, login: false }
  );
  const { mutate: block, isPending } = useChangeRestrictions(
    user.id,
    alertHandler
  );
  useEffect(() => {
    if (data) {
      setRestrictions(data);
    }
  }, [data]);
  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        position: "relative",
      }}
    >
      {(isLoading || isPending) && <Loader />}
      <InputCheckbox
        id="rectict-withdraw"
        label="Запрет на вывод"
        onChange={() => {
          block({ ...restrictions, withdrawal: !restrictions.withdrawal });
        }}
        checked={restrictions.withdrawal}
      />
      <InputCheckbox
        id="rectict-deposit"
        label="Запрет на депозит"
        onChange={() => {
          block({ ...restrictions, deposits: !restrictions.deposits });
        }}
        checked={restrictions.deposits}
      />
      <InputCheckbox
        id="rectict-game"
        label="Запрет на слоты"
        onChange={() => {
          block({ ...restrictions, slots: !restrictions.slots });
        }}
        checked={restrictions.slots}
      />
      <InputCheckbox
        id="rectict-login"
        label="Запрет на вход"
        onChange={() => {
          block({ ...restrictions, login: !restrictions.login });
        }}
        checked={restrictions.login}
      />
    </Box>
  );
};
