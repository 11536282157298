import { Box } from "@mui/material";
import React from "react";
import BonusesListItem from "./BonusesItem/BonusesListItem";
import { IBonusesListItem } from "./BonusesItem/typing/BonusesListItem.interface";

export const DesktopItem = ({
  active,
  title,
  sub_title,
  type,
  sub_type,
  min_dep,
  wager_fs,
  wager_pers,
  bg_img,
  sub_img,
}: IBonusesListItem) => {
  return (
    <Box
      sx={{
        width: "526px",
        height: "100%",
        maxHeight: "300px",
        display: "flex",
        flexDirection: "column",
        marginBottom: "20px",
      }}
    >
      <BonusesListItem
        active={active}
        sub_title={sub_title}
        title={title}
        type={type}
        sub_type={sub_type}
        min_dep={min_dep}
        wager_fs={wager_fs}
        wager_pers={wager_pers}
        promo_code="12345"
        bg_img={bg_img}
        sub_img={sub_img}
      />
    </Box>
  );
};
