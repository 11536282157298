import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BORDERRADIUS, COLORS } from "../../consts";
import { AppMenu } from "../../components/menu/menu";
import { InputB } from "../../components/input-b/inputB";
import SearchIcon from "@mui/icons-material/Search";
import { MyButton } from "../../components/button/button";
import { useFormik } from "formik";
import { useGetBonuses } from "../../hooks/bonuses/useGetBonuses";
import SourceIcon from "@mui/icons-material/Source";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IBonusExample } from "../../types/bonus.type";
import { ModalTemplate } from "../../components/modal/modal.template";
import { InputDate } from "../../components/inputdate/inputdate";
import { useDublicateBonus } from "../../hooks/bonuses/useDublicateBonus";
import { AlertContext } from "../../hooks/notification/useAlert";

export const Bonuses = () => {
  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: () => {},
  });
  const { alertHandler } = useContext(AlertContext);
  const { data: bonuses, error, isError } = useGetBonuses();
  const navigate = useNavigate();
  const createBonus = () => {
    navigate("/bonuses/create");
  };
  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
    }
  }, [isError]);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <AppMenu />
      </Box>
      <Box
        sx={{
          margin: "10px",
          width: "calc(100% - 20px)",
          height: "calc(100vh - 54px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            padding: "10px 16px",
            backgroundColor: COLORS.PRIMARY_BG,
            borderRadius: BORDERRADIUS.PRIMARY,
            marginBottom: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              width: "500px",
              gap: "5px",
            }}
          >
            <InputB
              id={"search"}
              onChange={formik.handleChange}
              value={formik.values.search}
              label={"Search"}
            >
              <SearchIcon />
            </InputB>
            <MyButton text="Search" sx={{ marginBottom: "10px" }}></MyButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <MyButton
              type="button"
              cancel
              text="Create bonus"
              sx={{ marginBottom: "10px" }}
              onClick={createBonus}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 16px",
            backgroundColor: COLORS.PRIMARY_BG,
            borderRadius: BORDERRADIUS.PRIMARY,
            marginBottom: "10px",
            height: "calc(100% - 10px - 81.5px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              backgroundColor: COLORS.GREY,
              width: "100%",
              padding: "6px 0",
              fontSize: "13px",
              color: COLORS.DARKGREY,
              borderRadius: BORDERRADIUS.PRIMARY,
              textAlign: "center",
            }}
          >
            <Box sx={{ width: "40px" }}>ID</Box>
            <Box sx={{ width: "80px" }}>Start Date</Box>
            <Box sx={{ width: "80px" }}>End Date</Box>
            <Box sx={{ width: "100px" }}>Promo code</Box>
            <Box sx={{ width: "150px" }}>Title</Box>
            <Box sx={{ width: "120px" }}>Freespins</Box>
            <Box sx={{ width: "150px" }}>Persantage</Box>
            <Box sx={{ width: "100px" }}>Min Dep</Box>
            <Box sx={{ width: "80px" }}>Type</Box>
            <Box sx={{ width: "36px" }}></Box>
          </Box>
          <Box sx={{ height: "100%", overflowY: "auto" }}>
            {bonuses ? (
              bonuses.map((bonus) => {
                return <BonusItem bonus={bonus} key={bonus.id} />;
              })
            ) : (
              <Box
                sx={{
                  height: "100%",
                  flex: "1 1 auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <SourceIcon sx={{ fontSize: "64px", marginBottom: "15px" }} />
                <Typography variant="h4">Bonuses is empty</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

interface BonusItemProps {
  bonus: IBonusExample;
}

export const BonusItem = ({ bonus }: BonusItemProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const [dublicateModal, setDublicateModal] = useState(false);
  const dublicateModalOpenHandler = () => {
    handleClose();
    setDublicateModal(true);
  };
  const dublicateModalCloseHandler = () => {
    setDublicateModal(false);
  };
  const { alertHandler } = useContext(AlertContext);
  const { mutate: dublicate } = useDublicateBonus(alertHandler);
  const formik = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
    },
    onSubmit: (values) => {
      dublicate({
        ...bonus,
        promo_code: {
          ...bonus.promo_code,
          start_date: values.start_date,
          end_date: values.end_date,
        },
      });
    },
  });
  const startDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({
        ...formik.values,
        start_date: date.valueOf().toString(),
      });
      return;
    }
    formik.setValues({ ...formik.values, start_date: "" });
  };
  const endDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({
        ...formik.values,
        end_date: (date.valueOf() + 86340000).toString(),
      });
      return;
    }
    formik.setValues({ ...formik.values, end_date: "" });
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        fontSize: "14px",
        textAlign: "center",
        borderRadius: BORDERRADIUS.PRIMARY,
        padding: "3px 0px",
        color: COLORS.DARKGREY,
        "&:hover": {
          backgroundColor: "#ffffff4a",
        },
      }}
    >
      <Box sx={{ width: "40px" }}>{bonus.id}</Box>
      <Box
        sx={{
          maxWidth: "80px",
          fontSize: "12px",
        }}
      >
        {new Date(+bonus.promo_code.start_date).toLocaleString()}
      </Box>
      <Box
        sx={{
          maxWidth: "80px",
          fontSize: "12px",
        }}
      >
        {new Date(+bonus.promo_code.end_date).toLocaleString()}
      </Box>
      <Box sx={{ width: "100px" }}>{bonus.promo_code.id}</Box>
      <Box sx={{ width: "150px" }}>{bonus.display_name}</Box>
      <Box sx={{ width: "120px" }}>
        {bonus.fs
          ? `${bonus.fs?.count_fs} in ${bonus.fs?.game_name}`
          : "No freespins"}
      </Box>
      <Box sx={{ width: "150px" }}>
        {bonus.pers ? `${bonus.pers?.count_pers} % to deposit` : "No percents"}
      </Box>
      <Box sx={{ width: "100px" }}>
        {bonus.activation_rules.min_dep
          ? bonus.activation_rules.min_dep.map(
              (min_dep) => `${min_dep.min_dep}${min_dep.currency_name} `
            )
          : "Without deposit"}
      </Box>
      <Box sx={{ width: "80px" }}>{bonus.type}</Box>
      <Box>
        <MoreVertIcon
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={{
            fontSize: "20px",
            marginRight: "16px",
            cursor: "pointer",
            transform: "translate(0, 3px)",
          }}
          onClick={handleClick}
        />
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            " .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
              boxShadow: "3px 5px 10px -5px rgba(0,0,0,0.2)",
              backgroundColor: COLORS.PRIMARY_BG,
            },
          }}
        >
          <MenuItem
            onClick={() => {
              navigate(`/bonuses/edit/${bonus.id}`);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem onClick={dublicateModalOpenHandler}>Dublicate</MenuItem>
          <MenuItem>Delete</MenuItem>
        </Menu>
      </Box>
      <ModalTemplate
        open={dublicateModal}
        close={dublicateModalCloseHandler}
        confirm={formik.handleSubmit}
        heading={"Dublicate Bonus"}
        confirmButton="Dublicate"
      >
        <Typography sx={{ color: COLORS.GREY, marginBottom: "20px" }}>
          Please, specify start date and end date for generating new promocode
          for new bonus
        </Typography>
        <InputDate
          id="start_date"
          label={"Start Date"}
          disabled={false}
          value={
            formik.values.start_date === ""
              ? formik.values.start_date
              : new Date(+formik.values.start_date)
          }
          onChange={startDateHandler}
        />
        <InputDate
          id="end_date"
          label={"End Date"}
          disabled={false}
          value={
            formik.values.end_date === ""
              ? formik.values.end_date
              : new Date(+formik.values.end_date)
          }
          onChange={endDateHandler}
        />
      </ModalTemplate>
    </Box>
  );
};
