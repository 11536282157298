import { useQuery } from "@tanstack/react-query";
import { brandService } from "../../services/brand.service";

export const useGetBrands = (id?: number) => {
  return useQuery({
    queryFn: () => brandService.getBrands(id),
    queryKey: id ? ["brands", id] : ["brands"],
    gcTime: 0,
    retry: 1,
  });
};
