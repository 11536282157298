import { Box } from "@mui/material";
import React from "react";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { InputDate } from "../../../components/inputdate/inputdate";
import { InputSelect } from "../../../components/inputSelect/input.select";
import { InputCheckbox } from "../../../components/inputcheckbox/inputcheckbox";
import { useFormik } from "formik";
import { MyButton } from "../../../components/button/button";
import { IBrands } from "../../../types/brand.type";
import { makeUniq } from "../../../utils/makeUniqArray";

export interface IInitialValues {
  from: null | string;
  to: null | string;
  brand: string | undefined;
  currency: number | undefined;
}

const initialValues: IInitialValues = {
  from: "",
  to: "",
  brand: undefined,
  currency: undefined,
};

type IStatsFiltersProps = {
  period: number;
  brands: IBrands[];
  setPeriod: React.Dispatch<React.SetStateAction<number>>;
  setFilter: React.Dispatch<IInitialValues>;
};

export const StatsFilters = ({
  period,
  brands,
  setPeriod,
  setFilter,
}: IStatsFiltersProps) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setFilter(values);
    },
  });
  const fromDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({ ...formik.values, from: date.valueOf().toString() });
      return;
    }
    formik.setValues({ ...formik.values, from: "" });
  };
  const toDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({
        ...formik.values,
        to: (
          new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
          ).valueOf() +
          24 * 60 * 60 * 1000 -
          1000
        ).toString(),
      });
      return;
    }
    formik.setValues({ ...formik.values, to: "" });
  };
  const clearHandler = () => {
    formik.setValues(initialValues);
    setPeriod(24 * 60 * 60 * 1000);
    setFilter(initialValues);
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: "350px",
        padding: "10px 16px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
      }}
    >
      <Box sx={{ marginBottom: "10px" }}>
        <InputCheckbox
          id={"per_day"}
          label={"Per day"}
          onChange={() => {
            setPeriod(24 * 60 * 60 * 1000);
          }}
          checked={period === 24 * 60 * 60 * 1000}
        />
        <InputCheckbox
          id={"per_week"}
          label={"Per week"}
          onChange={() => {
            setPeriod(7 * 24 * 60 * 60 * 1000);
          }}
          checked={period === 7 * 24 * 60 * 60 * 1000}
        />
        <InputCheckbox
          id={"per_month"}
          label={"Per month"}
          onChange={() => {
            setPeriod(30 * 24 * 60 * 60 * 1000);
          }}
          checked={period === 30 * 24 * 60 * 60 * 1000}
        />
      </Box>
      <InputDate
        id={"from"}
        label={"From"}
        disabled={false}
        value={
          formik.values.from === null
            ? ""
            : formik.values.from === ""
            ? formik.values.from
            : new Date(+formik.values.from)
        }
        onChange={fromDateHandler}
      />
      <InputDate
        id={"to"}
        label={"To"}
        disabled={false}
        value={
          formik.values.to === null
            ? ""
            : formik.values.to === ""
            ? formik.values.to
            : new Date(+formik.values.to)
        }
        onChange={toDateHandler}
      />
      <Box sx={{ marginBottom: "10px" }}>
        <InputSelect
          label={"Brand"}
          dataArray={brands.map((brand) => ({
            value: brand.brand_name,
            text: brand.brand_name,
          }))}
          selectFunction={(value: any) => {
            formik.setValues({
              ...formik.values,
              brand: value,
            });
          }}
        />
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <InputSelect
          label={"Currency"}
          dataArray={makeUniq(
            brands
              .map((brand) => brand.currencies)
              .flat(1)
              .map((currency) => ({
                value: currency.name,
                text: currency.name,
              })),
            "value"
          )}
          selectFunction={(value: any) => {
            formik.setValues({
              ...formik.values,
              currency: value,
            });
          }}
        />
      </Box>
      <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
        <MyButton cancel text="Clear" onClick={clearHandler} />
        <MyButton text="Filter" onClick={formik.handleSubmit} />
      </Box>
    </Box>
  );
};
