import { AxiosResponse } from "axios";
import { HttpService, httpService } from "./httpService";
import { IMessage } from "../types/message.type";
import { ICasinoRound } from "../types/casino.type";

class CasinoService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  async getGameHistory(query?: string) {
    const response = await this.httpService.get<
      AxiosResponse<{ data: ICasinoRound[]; count: number }>
    >(
      {
        url: `/api/bo/casino/game-history/${query ? query : ""}`,
      },
      true
    );
    return response.data;
  }

  async editMirror(data: { [key: string]: boolean | string | number }) {
    const response = await this.httpService.post<AxiosResponse<IMessage>>(
      {
        url: "/api/bo/mirrors/",
        data,
      },
      true
    );
    return response.data;
  }
}

export const casinoService = new CasinoService(httpService);
