import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { roleService } from "../../services/role.service";

export const useCreateRole = (
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (name: string) => roleService.createRole(name),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["roles"] });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
