import styled from "styled-components";
import { COLORS } from "../../consts";

export const EditButtonStyled = styled.div`
  position: relative;
  height: 20px;
  & button {
    position: absolute;
    top: 0;
    right: -15px;
    background-color: transparent;
    border: none;
    & svg {
      color: ${COLORS.GREY};
      width: 20px;
    }
  }
`;
