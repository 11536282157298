import { AxiosError, AxiosResponse } from "axios";
import { HttpService, httpService } from "./httpService";
import { AlertError } from "../errors/AlertError";
import { IPayment, IPaymentComment } from "../types/payment.type";
import { IMessage } from "../types/message.type";

class PaymentsService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  async getPayments(query: string) {
    try {
      const response = await this.httpService.get<AxiosResponse<IPayment[]>>(
        {
          url: `/api/bo/payments${query}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
      throw error;
    }
  }

  async getPendingPayments() {
    try {
      const response = await this.httpService.get<AxiosResponse<IPayment[]>>(
        {
          url: `/api/bo/payments/queue`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async getCountPendingPayments() {
    try {
      const response = await this.httpService.get<AxiosResponse<number>>(
        {
          url: `/api/bo/payments/queue/count`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
  async getPlayerPayments(query: string) {
    try {
      const response = await this.httpService.get<AxiosResponse<any>>(
        {
          url: `/api/bo/player/payments${query}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
  async takePendingPayments() {
    try {
      const response = await this.httpService.get<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/payments/queue/take`,
          data: {},
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async getComments() {
    try {
      const response = await this.httpService.get<
        AxiosResponse<{ comments: IPaymentComment[] }>
      >(
        {
          url: `/api/bo/payments/comments`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async addRejectComment(text: string) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/payments/comments`,
          data: {
            text,
          },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async approvePayment(payments: IPayment[]) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/payments/approve`,
          data: {
            payments,
          },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async rejectPayments(payments: IPayment[]) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/payments/reject`,
          data: {
            payments,
          },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async holdPayments(payments: IPayment[]) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/payments/hold`,
          data: {
            payments,
          },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async getExternalPaymentData(paymentid: string) {
    try {
      // debugger;
      const response = await this.httpService.post<AxiosResponse<any>>(
        {
          url: `/api/bo/externalpayment`,
          data: {
            paymentid,
          },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
  async getPaymentMethods() {
    try {
      // debugger;
      const response = await this.httpService.get<AxiosResponse<any>>(
        {
          url: `/api/bo/payment/method`,
        },
        true
      );

      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
  async getPaymentSystemBalance() {
    try {
      // debugger;
      const response = await this.httpService.get<AxiosResponse<any>>(
        {
          url: `/api/bo/payment/balance`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
}

export const paymentsService = new PaymentsService(httpService);
