import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { roleService } from "../../services/role.service";
import { IRole } from "../../types/role.type";
import { IPermission } from "../../types/permision.type";

export const useSetPermissionsToRole = (
  openHandler: (message: string, error?: string) => void,
  id: number | null
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({
      role,
      permissions,
    }: {
      role: IRole;
      permissions: IPermission[];
    }) => roleService.setPermissionsToRole(role, permissions),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["roles", id] });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
