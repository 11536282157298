import { Box, Typography } from "@mui/material";
import Select from "react-select";
import { COLORS } from "../../consts";
type Option = { value: any; text: string };

type InputOptionProps = {
  label: string;
  dataArray: Option[];
  selectFunction: (value: any) => void;
  defaultValue?: any;
  defaultInputValue?: string;
};

export const InputSelect = ({
  label,
  dataArray,
  selectFunction,
  defaultValue = undefined,
  defaultInputValue = undefined,
}: InputOptionProps) => {
  const chooseHandler = (value: Option) => {
    selectFunction(value.value);
  };
  const colorOptions = dataArray.map((data) => {
    return { value: data.value, label: data.text };
  });
  return (
    <Box>
      <Typography variant="body1" sx={{ fontSize: "12px" }}>
        {label}
      </Typography>
      <Select
        defaultValue={defaultValue}
        defaultInputValue={defaultInputValue}
        options={colorOptions}
        theme={(theme) => ({
          ...theme,
          colors: { ...theme.colors, primary: COLORS.GREEN },
        })}
        onChange={(elem) => {
          if (elem) {
            chooseHandler({ value: elem.value, text: elem.label });
          }
        }}
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: "#f8f8f8",
            color: COLORS.DARKGREY,
            border: "none",
            borderColor: "red",
            fontSize: "13px",
            lineHeight: "24px",
            minHeight: "33.5px",
          }),
          option: (styles, { isDisabled, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? COLORS.GREEN + "75"
                : undefined,
              fontWeight: isSelected ? "bold" : "inherit",
              color: isSelected ? "white" : COLORS.DARKGREY,
              cursor: isDisabled ? "not-allowed" : "default",
              ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                  ? isSelected
                    ? COLORS.GREEN + "de"
                    : undefined
                  : undefined,
              },
            };
          },
        }}
      />
    </Box>
  );
};
