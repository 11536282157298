import React from "react";
import { IUser } from "../../../../types/user.type";
import { useFormik } from "formik";
import { useOutletContext } from "react-router-dom";
import { Box } from "@mui/material";
import { EditButton } from "../../../../components/editbutton/editbutton";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { InputB } from "../../../../components/input-b/inputB";
import { MyButton } from "../../../../components/button/button";
import { AlertContext } from "../../../../hooks/notification/useAlert";
import { useActivate } from "../../../../hooks/player/playerInfo/useActivate";
import { useChangeContacts } from "../../../../hooks/player/playerInfo/useChangeContacts";

export const Contacts = () => {
  const [edit, setEdit] = React.useState(false);
  const [user]: IUser[] = useOutletContext();
  const { alertHandler } = React.useContext(AlertContext);
  const { mutate: activate } = useActivate(user.id, alertHandler);
  const { mutate: change } = useChangeContacts(user.id, alertHandler);
  const initialValues = user.contacts
    ? {
        email: user.contacts.email,
        phone: user.contacts.phone,
      }
    : {
        email: "",
        phone: "",
      };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      change({ email: values.email, phone: values.phone });
      setEdit(false);
    },
  });

  const closeHandler = () => {
    formik.setValues(initialValues);
    setEdit(false);
  };
  const openHandler = () => {
    setEdit(true);
  };

  return (
    <Box
      sx={{
        padding: "5px 20px 60px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        position: "relative",
      }}
    >
      <EditButton onClick={openHandler} />
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <InputB
            id="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            label="Email"
            disabled={!edit}
          />
          {user.contacts.emailConfirmation ? (
            <Box
              sx={{
                color: COLORS.GREEN,
                marginTop: "10px",
                backgroundColor: COLORS.GREEN + "1c",
                padding: "5px 16px",
                borderRadius: BORDERRADIUS.PRIMARY,
              }}
            >
              Confimed
            </Box>
          ) : (
            <>
              <MyButton
                type="button"
                text="Подтвердить"
                sx={{ marginTop: "10px" }}
                onClick={activate}
              />
              <Box
                sx={{
                  color: COLORS.RED,
                  marginTop: "10px",
                  backgroundColor: COLORS.RED + "1c",
                  padding: "5px 16px",
                  borderRadius: BORDERRADIUS.PRIMARY,
                }}
              >
                Unconfimed
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <InputB
            id="phone"
            onChange={formik.handleChange}
            value={formik.values.phone ? formik.values.phone : ""}
            label="Phone"
            disabled={!edit}
          />
          {user.contacts.phoneConfirmation ? (
            <Box
              sx={{
                color: COLORS.GREEN,
                marginTop: "10px",
                backgroundColor: COLORS.GREEN + "1c",
                padding: "5px 16px",
                borderRadius: BORDERRADIUS.PRIMARY,
              }}
            >
              Confimed
            </Box>
          ) : (
            <Box
              sx={{
                color: COLORS.RED,
                marginTop: "10px",
                backgroundColor: COLORS.RED + "1c",
                padding: "5px 16px",
                borderRadius: BORDERRADIUS.PRIMARY,
              }}
            >
              Unconfimed
            </Box>
          )}
        </Box>
        {edit && (
          <Box
            sx={{
              display: "flex",
              gap: "14px",
              justifyContent: "flex-end",
              position: "absolute",
              bottom: "20px",
              right: "20px",
            }}
          >
            <MyButton
              type="button"
              text="Отмена"
              onClick={closeHandler}
              cancel
            />
            <MyButton type="submit" text="Сохранить" />
          </Box>
        )}
      </form>
    </Box>
  );
};
