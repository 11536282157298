import React, { useContext, useEffect } from "react";
import { ModalTemplate } from "../../../../components/modal/modal.template";
import { Box, Typography } from "@mui/material";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { InputB } from "../../../../components/input-b/inputB";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCreateFS } from "../../../../hooks/bonuses/useCreateFS";
import { AlertContext } from "../../../../hooks/notification/useAlert";
import { IBrands } from "../../../../types/brand.type";
import { InputSelect } from "../../../../components/inputSelect/input.select";
import { ISlot } from "../../../../types/lobby.type";
import { queryCreator } from "../../../../utils/queryToObj";
import { useGetMySlots } from "../../../../hooks/lobby/useGetMySlots";

interface ICreateFSInitValues {
  bet: number;
  multiple: number;
  cp: {
    currency_name: string;
    cp: string;
  }[];
  count_fs: number;
  game: ISlot | undefined;
  brand: IBrands | undefined;
}

const InitialValues: ICreateFSInitValues = {
  bet: 1,
  count_fs: 0,
  multiple: 0,
  cp: [],
  game: undefined,
  brand: undefined,
};

const FreespinsSchema = Yup.object().shape({
  bet: Yup.number().required().positive().integer(),
  count_fs: Yup.number().required().positive().integer(),
  multiple: Yup.number().required().positive().round(),
  cp: Yup.array<
    {
      currency_name: string;
      cp: string;
    }[]
  >().required(),
  game: Yup.object<ISlot>().required(),
});

type ICreateFreespinsModalProps = {
  brands: IBrands[];
  open: boolean;
  close: () => void;
};
export const CreateFreespinsModal = ({
  brands,
  open,
  close,
}: ICreateFreespinsModalProps) => {
  const formikFS = useFormik({
    initialValues: InitialValues,
    validationSchema: FreespinsSchema,
    onSubmit: (values) => {
      create(values);
      formikFS.setValues(InitialValues);
    },
  });
  const { alertHandler } = useContext(AlertContext);
  const { data } = useGetMySlots(queryCreator({ id: "DESC" }));
  const { mutate: create, isSuccess } = useCreateFS(alertHandler);
  const cpInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    cp_currency: { currency_name: string; cp: string }
  ) => {
    const reg = /^[0-9]*[.]?[0-9]+$/;
    const reg2 = /^[0-9]*[.]?$/;
    const value = e.target.value;
    if (
      reg.test(value) ||
      value === "" ||
      (value[value.length - 1] === "." && reg2.test(value))
    ) {
      formikFS.setValues({
        ...formikFS.values,
        cp: formikFS.values.cp.map((newCP) => {
          if (newCP.currency_name === cp_currency.currency_name) {
            newCP.cp = value;
            return newCP;
          }
          return newCP;
        }),
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      formikFS.setValues(InitialValues);
      alertHandler("Successfully created");
      close();
    }
  }, [isSuccess]);
  useEffect(() => {
    if (formikFS.values.brand) {
      formikFS.setValues({
        ...formikFS.values,
        cp: formikFS.values.brand.currencies.map((currency) => ({
          currency_name: currency.name,
          cp: "0",
        })),
      });
    }
  }, [formikFS.values.brand]);
  return (
    <ModalTemplate
      open={open}
      close={close}
      confirm={formikFS.handleSubmit}
      heading={"Create Freespins"}
      confirmButton="Create"
    >
      <Box
        sx={{
          borderRadius: BORDERRADIUS.PRIMARY,
          border: `1px solid ${COLORS.ORANGE}`,
          display: "flex",
          gap: "10px",
          alignItems: "center",
          color: COLORS.ORANGE,
          padding: "10px",
          marginBottom: "10px",
        }}
      >
        <ErrorOutlineRoundedIcon />
        <Typography>
          To create freespins fill the form.
          <br />
          All numbers should be integer and Denomination can be float
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <InputSelect
          label={"Brand"}
          dataArray={brands.map((brand) => ({
            value: brand,
            text: brand.brand_name,
          }))}
          selectFunction={(value) => {
            formikFS.setValues({ ...formikFS.values, brand: value });
          }}
        />
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <InputSelect
          label="Game"
          dataArray={
            data ? data.map((game) => ({ value: game, text: game.title })) : []
          }
          selectFunction={(value) => {
            formikFS.setValues({ ...formikFS.values, game: value });
          }}
        />
      </Box>
      <Box>
        {formikFS.values.brand &&
          formikFS.values.cp.map((cp_currency, index) => {
            return (
              <InputB
                key={`cp_${cp_currency.currency_name}_${index}`}
                id={`cp_${cp_currency.currency_name}`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  cpInputHandler(event, cp_currency);
                }}
                value={cp_currency.cp}
                label={`Denomination for ${cp_currency.currency_name}`}
              />
            );
          })}
      </Box>
      <InputB
        id="bet"
        onChange={formikFS.handleChange}
        value={formikFS.values.bet}
        label="Bet"
        error={formikFS.errors.bet}
      />
      <InputB
        id="count_fs"
        onChange={formikFS.handleChange}
        value={formikFS.values.count_fs}
        label="Freespins count"
        error={formikFS.errors.count_fs}
      />
      <InputB
        id="multiple"
        onChange={formikFS.handleChange}
        value={formikFS.values.multiple}
        label="Multiplier"
        error={formikFS.errors.multiple}
      />
    </ModalTemplate>
  );
};
