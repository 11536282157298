import { useQuery } from "@tanstack/react-query";
import { roleService } from "../../services/role.service";

export const useGetRoles = (id?: number) => {
  return useQuery({
    queryFn: () => roleService.getRoles(id),
    queryKey: id ? ["roles", id] : ["roles"],
    gcTime: 0,
  });
};
