import { Box, Typography } from "@mui/material";
import React from "react";
import { MyButton } from "../../../../../components/button/button";
import { COLORS } from "../../../../../consts";
import { IInitialValues } from "../../typing/initialValues";
import { FormikErrors } from "formik";

interface IActionFieldsProps {
  values: IInitialValues;
  setValues: (
    values: React.SetStateAction<IInitialValues>,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IInitialValues>>;
  openFSModal: () => void;
  openPersModal: () => void;
  openPreviewModal: () => void;
  cancelHandler: () => void;
  submitHandler: () => void;
  submitButtonText?: string;
}

export const ActionFields = ({
  values,
  setValues,
  openFSModal,
  openPersModal,
  openPreviewModal,
  cancelHandler,
  submitHandler,
  submitButtonText,
}: IActionFieldsProps) => {
  const typeHandler = (
    value: "all" | "individual" | "birthday" | "for_partner"
  ) => {
    setValues({ ...values, type: value });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "19px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <MyButton
            sx={{
              backgroundColor: COLORS.BLUE,
              "&:hover": { backgroundColor: COLORS.BLUE + "d0" },
            }}
            text="Add Freespins"
            onClick={openFSModal}
          />
          <MyButton
            sx={{
              backgroundColor: COLORS.BLUE,
              "&:hover": { backgroundColor: COLORS.BLUE + "d0" },
            }}
            text="Add Percentage"
            onClick={openPersModal}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <MyButton
            text="Preview"
            onClick={openPreviewModal}
            sx={{
              backgroundColor: COLORS.DARKGREY,
              "&:hover": { backgroundColor: COLORS.DARKGREY + "d0" },
            }}
          />
          <MyButton
            text="Cancel"
            cancel
            onClick={cancelHandler}
            sx={{
              backgroundColor: COLORS.RED,
              "&:hover": { backgroundColor: COLORS.RED + "d0" },
            }}
          />
          <MyButton
            text={submitButtonText ? submitButtonText : "Create Bonus"}
            onClick={submitHandler}
            sx={{
              backgroundColor: COLORS.GREEN,
              "&:hover": { backgroundColor: COLORS.GREEN + "d0" },
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{ fontSize: "13px" }}>Type</Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <MyButton
            sx={{
              backgroundColor:
                values.type === "all" ? COLORS.GREEN : COLORS.DARKGREY,
              "&:hover": { backgroundColor: COLORS.GREEN },
            }}
            onClick={() => {
              typeHandler("all");
            }}
            text="All"
          />
          <MyButton
            sx={{
              backgroundColor:
                values.type === "individual" ? COLORS.GREEN : COLORS.DARKGREY,
              "&:hover": { backgroundColor: COLORS.GREEN },
            }}
            onClick={() => {
              typeHandler("individual");
            }}
            text="Individual"
          />
          <MyButton
            sx={{
              backgroundColor:
                values.type === "birthday" ? COLORS.GREEN : COLORS.DARKGREY,
              "&:hover": { backgroundColor: COLORS.GREEN },
            }}
            onClick={() => {
              typeHandler("birthday");
            }}
            text="Birthday"
          />
          <MyButton
            sx={{
              backgroundColor:
                values.type === "for_partner" ? COLORS.GREEN : COLORS.DARKGREY,
              "&:hover": { backgroundColor: COLORS.GREEN },
            }}
            onClick={() => {
              typeHandler("for_partner");
            }}
            text="Partner"
          />
        </Box>
      </Box>
    </>
  );
};
