import { AxiosError, AxiosResponse } from "axios";
import { HttpService, httpService } from "./httpService";
import { AlertError } from "../errors/AlertError";
import { IMessage } from "../types/message.type";

type IVerification = {
  id: number;
  verification_imgs: string;
  archived_imgs: string;
  verification: string;
  verification_stage: "pending" | "success" | "rejected" | "expectation";
};

class PlayerKYCService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  async getImages(id: string) {
    try {
      const response = await this.httpService.get<AxiosResponse<IVerification>>(
        {
          url: `/api/bo/player/verification?id=${id}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async getImage(image: string, id: string) {
    const response = await fetch(
      `${
        process.env.REACT_APP_SERVER_URL_BO || ""
      }/api/bo/player/verification/${image}?id=${id}`,
      {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem(process.env.REACT_APP_TOKEN_BO),
        },
      }
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    return url;
  }
  async archive(image: string, id: string) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/player/verification/archive`,
          data: { image, id },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
  async unarchive(image: string, id: string) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/player/verification/unarchive`,
          data: { image, id },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
  async verify(serialNumber: string, id: string) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/player/verification`,
          data: { serialNumber, id },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
}

export const playerKycService = new PlayerKYCService(httpService);
