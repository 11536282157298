import { AxiosError, AxiosResponse } from "axios";
import { HttpService, httpService } from "./httpService";
import { AlertError } from "../errors/AlertError";
import { IMessage } from "../types/message.type";

class PlayerBonusesService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }
  async getBonuses(query: string) {
    try {
      const response = await this.httpService.get<
        AxiosResponse<{ bonuses: any[]; count: number }>
      >(
        {
          url: `/api/bo/player/bonuses${query}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async changeStatus(id: number, status: string) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/player/bonuses`,
          data: {
            id,
            status,
          },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async cancel(id: number) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/player/bonuses/cancel`,
          data: {
            id,
          },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
}
export const playerBonusesService = new PlayerBonusesService(httpService);
