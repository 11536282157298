import { Box } from "@mui/material";
import React, { useState } from "react";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { MyButton } from "../../../../components/button/button";
import { IPayment } from "../../../../types/payment.type";
import { useApprovePayment } from "../../../../hooks/payments/useApprovePayment";
import { useGetComments } from "../../../../hooks/payments/useGetComments";
import { useHoldPayment } from "../../../../hooks/payments/useHoldPayments";
import { useRejectPayment } from "../../../../hooks/payments/useRejectPayments";
import { InputSelect } from "../../../../components/inputSelect/input.select";

type IActionQueueProps = {
  selected: IPayment[];
  alertHandler: (message: string, error?: string | undefined) => void;
  clearSelected: () => void;
};

export const ActionQueue = ({
  selected,
  alertHandler,
  clearSelected,
}: IActionQueueProps) => {
  const { mutate: approve } = useApprovePayment(alertHandler);
  const { mutate: hold } = useHoldPayment(alertHandler);
  const { mutate: reject } = useRejectPayment(alertHandler);
  const { data: comments } = useGetComments();
  const [value, setValue] = useState("");
  const selectChangeHandler = (value: string) => {
    setValue(value);
  };
  const rejectHandler = () => {
    const payments = selected.map((p) => {
      const card =
        p.credentials.slice(0, 4) +
        "******" +
        p.credentials.slice(p.credentials.length - 5, p.credentials.length - 1);
      const amount = (+p.amount).toFixed(2);
      // console.log(card, amount);
      const elem = {
        ...p,
        comment: value.replace("{card}", card).replace("{amount}", amount),
      };
      return elem;
    });
    reject(payments);
    clearSelected();
  };
  const approveHandler = () => {
    approve(selected);
    clearSelected();
  };

  const holdHandler = () => {
    hold(selected);
    clearSelected();
  };
  return (
    <Box
      sx={{
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        padding: "10px 16px",
      }}
    >
      <Box>
        <InputSelect
          label="Reject comment"
          dataArray={[
            { value: "", text: "-" },
            ...(comments?.comments.map((item) => {
              return { value: item.comment, text: item.comment };
            }) || []),
          ]}
          selectFunction={selectChangeHandler}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <MyButton
            disabled={!selected.length}
            text="Hold"
            sx={{
              backgroundColor: COLORS.GREY,
              "&:hover": { backgroundColor: COLORS.GREY + "ba" },
            }}
            onClick={holdHandler}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <MyButton
            disabled={!selected.length}
            text="Approve"
            sx={{
              backgroundColor: COLORS.GREEN,
              "&:hover": { backgroundColor: COLORS.GREEN + "ba" },
            }}
            onClick={approveHandler}
          />
          <MyButton
            disabled={!selected.length}
            text="Reject"
            onClick={rejectHandler}
            sx={{
              backgroundColor: COLORS.RED,
              "&:hover": { backgroundColor: COLORS.RED + "ba" },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
