import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { InputCheckbox } from "../../../components/inputcheckbox/inputcheckbox";
import { Loader } from "../../../components/loader/loader";
import { AlertContext } from "../../../hooks/notification/useAlert";
import { useGetMirrors } from "../../../hooks/brand/useGetMirrors";
import { useEditMirror } from "../../../hooks/brand/useEditMirror";
import { NavigateFunction } from "react-router-dom";

type IMirrorsProps = {
  brandId: number;
  logout: () => void;
  navigator: NavigateFunction;
};

export const Mirrors = ({ brandId, logout, navigator }: IMirrorsProps) => {
  const { alertHandler } = useContext(AlertContext);
  const { data: mirrors, isLoading, error } = useGetMirrors(brandId);
  const { mutate: edit, isPending } = useEditMirror(alertHandler);
  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
      if (error.message === "Unathorized") {
        logout();
        navigator("/");
      }
    }
  }, [error]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 36px)",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "100%",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          margin: "10px 10px 10px 0 ",
          padding: "10px 16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            backgroundColor: COLORS.GREY,
            width: "100%",
            padding: "6px 0",
            fontSize: "13px",
            color: COLORS.DARKGREY,
            borderRadius: BORDERRADIUS.PRIMARY,
            textAlign: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>Domain</Box>
          <Box sx={{ maxWidth: "120px", width: "100%" }}>Active</Box>
          <Box sx={{ maxWidth: "120px", width: "100%" }}>Blocked</Box>
        </Box>
        <Box
          sx={{
            height: "calc(100% - 40px)",
            overflowY: "auto",
            position: "relative",
          }}
        >
          {mirrors ? (
            mirrors.length !== 0 ? (
              mirrors.map((mirror, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      padding: "7px 0",
                    }}
                    key={"mirror_" + mirror.id}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                        color: COLORS.DARKGREY,
                      }}
                    >
                      {mirror.domain}
                    </Box>
                    <Box sx={{ maxWidth: "120px", width: "100%" }}>
                      <InputCheckbox
                        id={"active_" + index}
                        label={"Active"}
                        onChange={() => {
                          edit({ id: mirror.id, active: !mirror.active });
                        }}
                        checked={mirror.active}
                      />
                    </Box>
                    <Box sx={{ maxWidth: "120px", width: "100%" }}>
                      <InputCheckbox
                        id={"blocked_" + index}
                        label={"Blocked"}
                        onChange={() => {
                          edit({ id: mirror.id, blocked: !mirror.blocked });
                        }}
                        checked={mirror.blocked}
                      />
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box>Brand does not have mirrors</Box>
            )
          ) : (
            <Box>Mirrors not found</Box>
          )}
          {(isPending || isLoading) && <Loader></Loader>}
        </Box>
      </Box>
    </Box>
  );
};
