import { Box } from "@mui/material";
import React, { useState } from "react";
import { BORDERRADIUS, COLORS } from "../../../../consts";
type IFinanceCard = {
  finance: any;
  first: boolean;
};
export const FinanceCard = ({ finance, first }: IFinanceCard) => {
  const [beforeOpen, setBeforeOpen] = useState(false);
  const openBeforeHandler = () => {
    setBeforeOpen(true);
  };
  const closeBeforeHandler = () => {
    setBeforeOpen(false);
  };
  const [afterOpen, setAfterOpen] = useState(false);
  const openAfterHandler = () => {
    setAfterOpen(true);
  };
  const closeAfterHandler = () => {
    setAfterOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: BORDERRADIUS.PRIMARY,
        fontSize: "13px",
        marginBottom: "5px",
        textAlign: "center",
        color: "black",
        gap: "10px",
      }}
    >
      <Box sx={{ width: "100px" }}>{finance.id}</Box>
      <Box sx={{ width: "70px", fontSize: "11px" }}>
        {new Date(+finance.date).toLocaleString()}
      </Box>
      <Box sx={{ width: "250px" }}>{finance.transaction_id}</Box>
      <Box sx={{ width: "100px" }}>{finance.amount}</Box>
      <Box sx={{ width: "100px" }}>{finance.type}</Box>
      <Box
        sx={{ width: "100px", position: "relative" }}
        onMouseOver={openBeforeHandler}
        onMouseLeave={closeBeforeHandler}
      >
        {(
          +finance.main_before +
          +finance.bonus_before +
          +finance.reserved_before
        ).toFixed(2)}
        {beforeOpen && (
          <Box
            sx={{
              width: "200px",
              position: "absolute",
              bottom: first ? "-220%" : "100%",
              left: "-50%",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              backgroundColor: COLORS.PRIMARY_BG,
              borderRadius: BORDERRADIUS.PRIMARY,
              boxShadow: `3px 3px 8px ${COLORS.DARKGREY + "4c"}`,
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <p>Main balance:</p>
              <p>{(+finance.main_before).toFixed(2)}</p>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <p>Bonus balance:</p>
              <p>{(+finance.bonus_before).toFixed(2)}</p>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <p>Reserved balance:</p>
              <p>{(+finance.reserved_before).toFixed(2)}</p>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{ width: "100px", position: "relative" }}
        onMouseOver={openAfterHandler}
        onMouseLeave={closeAfterHandler}
      >
        {(
          +finance.main_after +
          +finance.bonus_after +
          +finance.reserved_after
        ).toFixed(2)}
        {afterOpen && (
          <Box
            sx={{
              width: "200px",
              position: "absolute",
              bottom: first ? "-220%" : "100%",
              left: "-100%",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              backgroundColor: COLORS.PRIMARY_BG,
              borderRadius: BORDERRADIUS.PRIMARY,
              boxShadow: `3px 3px 8px ${COLORS.DARKGREY + "4c"}`,
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <p>Main balance:</p>
              <p>{(+finance.main_after).toFixed(2)}</p>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <p>Bonus balance:</p>
              <p>{(+finance.bonus_after).toFixed(2)}</p>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <p>Reserved balance:</p>
              <p>{(+finance.reserved_after).toFixed(2)}</p>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
