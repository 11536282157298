import styled from "styled-components";
import { COLORS } from "../../consts";

export const InputCheckboxStyled = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 2.5px;
  & label {
    color: ${COLORS.DARKGREY};
    font-size: 14px;
    flex: 1 1 auto;
  }

  & span {
    display: block;
    width: 30px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid;
    background-color: ${COLORS.SECONDARY};
    position: relative;
    left: -25px;
    pointer-events: none;
    &::before {
      position: absolute;
      top: 1px;
      left: 1px;
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #ffffff;
      transition: all 0.4s ease;
      cursor: pointer;
    }
  }
  & input {
    position: relative;
    opacity: 0;
    width: 25px;
    padding: 0px 5px;
    cursor: pointer;
    &:checked ~ span {
      background-color: ${COLORS.GREEN};
      transition: all 0.3s ease;
    }
    &:checked ~ span::before {
      transform: translate(12px, 0);
        transition: all 0.3s ease;
      }
    }
  }
`;
