import { useQuery } from "@tanstack/react-query";
import { lobbyService } from "../../services/lobby.service";

export const useGetProvidedSlots = () => {
  return useQuery({
    queryFn: () => lobbyService.getProvidedSlots(),
    queryKey: ["all"],
    staleTime: 0,
    gcTime: 0,
    retry: 1,
  });
};
