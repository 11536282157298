import { Box } from "@mui/material";
import React, { useState } from "react";

import { AlertContext } from "../../../hooks/notification/useAlert";
import { useGetPendingCount } from "../../../hooks/payments/useGetPendingCount";
import { useGetPendingPayments } from "../../../hooks/payments/useGetPendingPayments";
import { useTakePendingPayments } from "../../../hooks/payments/useTakePendingPayments";
import { IPayment } from "../../../types/payment.type";

import { PaymentContainer } from "../../../components/paymentContainer/payment.container";
import { QueueHeader } from "../../../modules/payments/queue/payments/header";
import { PendingPayments } from "../../../modules/payments/queue/payments/pendingPayments";
import { ActionQueue } from "../../../modules/payments/queue/payments/action";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { QueueColumns } from "../../../modules/payments/queue/payments/queue.columns";
import { PaymentSystem } from "../../../modules/payments/queue/payment_system/paymentSystem";

export const PaymentQueue = () => {
  const { alertHandler } = React.useContext(AlertContext);
  const [selected, setSelected] = useState<IPayment[]>([]);
  const { data: pendingCount } = useGetPendingCount();
  const { data: payments, isSuccess } = useGetPendingPayments();
  const { mutate: take } = useTakePendingPayments(alertHandler);
  const selectHandler = (payment: IPayment) => {
    const select = !!selected.find((p) => p.id === payment.id);
    if (!select) {
      setSelected([...selected, payment]);
      return;
    }
    setSelected([...selected.filter((p) => p.id !== payment.id)]);
  };
  const clearSelectedHandler = () => {
    setSelected([]);
  };
  const selectAllHandler = (payments: IPayment[]) => {
    setSelected(payments);
  };

  return (
    <PaymentContainer>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          padding: "0 16px",
        }}
      >
        <Box>
          <QueueHeader
            count={pendingCount}
            selected={selected.length}
            take={take}
            clear={clearSelectedHandler}
            alertHandler={alertHandler}
          />
          <QueueColumns
            selected={selected}
            payments={payments}
            selectAllHandler={selectAllHandler}
          />
        </Box>
        <PendingPayments
          isSuccess={isSuccess}
          payments={payments}
          selected={selected}
          selectHandler={selectHandler}
        />
      </Box>
      <ActionQueue
        selected={selected}
        alertHandler={alertHandler}
        clearSelected={clearSelectedHandler}
      />
      <PaymentSystem />
    </PaymentContainer>
  );
};
