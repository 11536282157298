import React from "react";
import { IEmailBonus } from "../../../../../types/bonus.type";
import { IFreespinsExample } from "../../../../../types/freespins.type";
import { IPercentageExample } from "../../../../../types/perscentage.type";
import { Box } from "@mui/material";

type IEmailPreviewProps = {
  bonus: {
    fs: IFreespinsExample | undefined;
    pers: IPercentageExample | undefined;
    email: IEmailBonus | undefined;
    min_dep: {
      currency_name: string;
      min_dep: string;
    }[];
  };
  images: {
    [key: string]: File | null;
    bg_image: File | null;
    sub_image: File | null;
    email_image: File | null;
  };
};

export const EmailPreview = ({ bonus, images }: IEmailPreviewProps) => {
  console.log(images);
  const image = images.email_image
    ? URL.createObjectURL(images.email_image)
    : "";
  const isFs = bonus.fs != null;
  const isPers = bonus.pers != null;
  const isMinDep = +bonus.min_dep[0].min_dep > 0;
  return (
    <div
      style={{
        fontFamily: "'Montserrat', sans-serif",
        backgroundColor: "#ffffff",
        margin: "0",
        padding: "0",
        minWidth: "600px",
      }}
    >
      <table
        role="presentation"
        width="100%"
        cellSpacing={0}
        cellPadding={0}
        border={0}
      >
        <tr>
          <td>
            <table
              role="presentation"
              width="600"
              align="center"
              cellSpacing={0}
              cellPadding={0}
              border={0}
              style={{
                width: "100%",
                maxWidth: "600px",
                margin: "0 auto",
                backgroundColor: "#131a2f",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
                color: "#f8f8f8",
              }}
            >
              <tr>
                <td>
                  <table
                    role="presentation"
                    width="100%"
                    cellSpacing={0}
                    cellPadding={0}
                    border={0}
                    style={{
                      minHeight: "337px",
                      padding: "10px",
                      backgroundImage: `url(${image})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <tr>
                      <td
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: "25px",
                          fontWeight: "700",
                          color: "#df0043",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td style={{ height: "15vh" }}></td>
                    </tr>
                    <tr>
                      <td style={{}}>
                        <a
                          href="a"
                          style={{
                            backgroundColor: "#df0043",
                            color: "#f8f8f8",
                            textDecoration: "none",
                            padding: "15px 25px",
                            borderRadius: "5px",
                            fontSize: "18px",
                          }}
                        >
                          ЗАБРАТЬ СЕЙЧАС
                        </a>
                      </td>
                    </tr>
                  </table>
                  <table
                    role="presentation"
                    width="100%"
                    cellSpacing={0}
                    cellPadding={0}
                    border={0}
                  >
                    <tr>
                      <td
                        style={{
                          lineHeight: "1.5",
                          fontSize: "20px",
                          padding: "20px",
                          color: "#f8f8f8",
                        }}
                      >
                        <div style={{ margin: "20px 0" }}>
                          <h3 style={{ fontSize: "20px", color: "#f8f8f8" }}>
                            {bonus.email?.title}
                          </h3>
                          {bonus.email ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: bonus.email.description,
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <ul
                          style={{
                            fontSize: "16px",
                            lineHeight: "1.5",
                            margin: "10px 0",
                            fontWeight: "bold",
                          }}
                        >
                          {isPers ? (
                            <li>
                              {`Бонус ${bonus.pers?.count_pers}%
                                    `}
                            </li>
                          ) : (
                            ""
                          )}

                          {isFs ? (
                            <li>
                              {`${bonus.fs?.count_fs} бесплатних вращений (FS)`}
                            </li>
                          ) : (
                            ""
                          )}

                          {isMinDep ? (
                            <li>
                              {`Мин. депозит - ${bonus.min_dep
                                .map(
                                  (min_dep) =>
                                    `${min_dep.min_dep}${min_dep.currency_name}`
                                )
                                .join("/")}`}
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                        <div style={{ textAlign: "center", margin: "20px 0" }}>
                          <a
                            href="a"
                            style={{
                              backgroundColor: "#df0043",
                              color: "#f8f8f8",
                              textDecoration: "none",
                              padding: "15px 25px",
                              borderRadius: "5px",
                              fontSize: "18px",
                            }}
                          >
                            ЗАБРАТЬ БОНУС
                          </a>
                        </div>
                        <div style={{ margin: "20px 0" }}>
                          {bonus.email ? (
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: bonus.email.footer,
                              }}
                              sx={{
                                "& p": { marginBottom: "20px" },
                                "& ul": { marginBottom: "20px" },
                                "& li": { fontWeight: "bold" },
                              }}
                            ></Box>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
};
