import { AxiosError, AxiosResponse } from "axios";
import { HttpService, httpService } from "./httpService";
import { AlertError } from "../errors/AlertError";
import { IMessage } from "../types/message.type";
import { IEmployee } from "../types/employee.type";

class EmployeeService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  async getEmployees(id?: number) {
    try {
      const response = await this.httpService.get<AxiosResponse<IEmployee[]>>(
        {
          url: id ? `/api/bo/employee?id=${id}` : `/api/bo/employee`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async createEmployee(values: { email: string; access?: string }) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/employee`,
          data: {
            email: values.email,
            access: values.access,
          },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async checkToken() {
    try {
      const response = await this.httpService.get<AxiosResponse<IEmployee>>(
        {
          url: `/api/bo/auth/checkToken`,
        },
        true
      );
      return response;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
}

export const employeeService = new EmployeeService(httpService);
