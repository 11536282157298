import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../../components/loader/loader";
import { useGetBonuses } from "../../../hooks/bonuses/useGetBonuses";
import { EmployeeContext } from "../../../hooks/auth/useAuth";
import { AlertContext } from "../../../hooks/notification/useAlert";
import { IBonusExample } from "../../../types/bonus.type";
import { BonusEdit } from "../../../modules/bonuses/edit/BonusEdit";

export const BonusesEdit = () => {
  const { id } = useParams();
  const { alertHandler } = useContext(AlertContext);
  const { logout } = useContext(EmployeeContext);

  const [bonus, setBonus] = useState<IBonusExample | null>(null);
  const [origionalImages, setOrigionalImages] = useState<{
    sub_image: null | string;
    bg_image: null | string;
  }>({ sub_image: null, bg_image: null });
  const {
    data: bonuses,
    isLoading,
    isError,
    error,
  } = useGetBonuses(id ? +id : undefined);
  useEffect(() => {
    if (isError) {
      //@ts-ignore
      if (+error.code === 401) {
        logout();
        return;
      }
      //@ts-ignore
      alertHandler(error.message, error.code);
    }
  }, [error]);
  useEffect(() => {
    if (bonuses) {
      setBonus(bonuses[0]);
    }
  }, [bonuses]);
  useEffect(() => {
    const imageFetcher = async (
      image_name: string,
      type: "sub_image" | "bg_image"
    ) => {
      const file = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/bonus/image?image_name=${image_name}`
      );
      const blob = await file.blob();
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          if (type === "bg_image") {
            setOrigionalImages((state) => {
              const copy = { ...state };
              //@ts-ignore
              copy.bg_image = e.target.result.toString();
              return copy;
            });
          } else {
            setOrigionalImages((state) => {
              const copy = { ...state };
              //@ts-ignore
              copy.sub_image = e.target.result.toString();
              return copy;
            });
          }
        }
      };
      reader.readAsDataURL(blob);
    };
    if (bonus) {
      for (const key in bonus.additional_information) {
        if (key === "desktop_bg_image") {
          if (bonus.additional_information.desktop_bg_image) {
            imageFetcher(
              bonus.additional_information.desktop_bg_image,
              "bg_image"
            );
          }
        }
        if (key === "desktop_sub_image") {
          if (bonus.additional_information.desktop_sub_image) {
            imageFetcher(
              bonus.additional_information.desktop_sub_image,
              "sub_image"
            );
          }
        }
      }
    }
  }, [bonus]);
  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
      }}
    >
      {isLoading && <Loader />}
      {bonus && <BonusEdit bonus={bonus} origionalImages={origionalImages} />}
    </Box>
  );
};
