import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { paymentsService } from "../../services/paymentsService";
import { IPayment } from "../../types/payment.type";

export const useApprovePayment = (
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (payments: IPayment[]) =>
      paymentsService.approvePayment(payments),
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ["pendingPayments"],
      });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
