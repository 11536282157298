import { AxiosError, AxiosResponse } from "axios";
import { HttpService, httpService } from "./httpService";
import { IProvidedProvider, ISelectSlot, ISlot } from "../types/lobby.type";
import { AlertError } from "../errors/AlertError";
import { IMessage } from "../types/message.type";

class LobbyService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  async getProvidedSlots() {
    try {
      const response = await this.httpService.get<
        AxiosResponse<IProvidedProvider[]>
      >(
        {
          url: "/api/bo/provided-slots",
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
      throw error;
    }
  }

  async saveProvider(value: FormData) {
    const response = await fetch(`/api/bo/save/provider`, {
      method: "POST",
      body: value,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          process.env.REACT_APP_TOKEN_BO
        )}`,
      },
    });
    if (!response.ok) {
      const data = await response.json();
      throw new AlertError(data.message, response.status.toString());
    }
  }

  async saveSlots(value: {
    providerInternalId: number | null;
    slots: ISelectSlot[];
  }) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: "/api/bo/save/slots",
          data: { providerId: value.providerInternalId, slots: value.slots },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
      throw error;
    }
  }

  async getMySlots(query: string) {
    try {
      const response = await this.httpService.get<AxiosResponse<ISlot[]>>(
        {
          url: `/api/bo/slots${query || ""}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
      throw error;
    }
  }

  async changeFilter(
    id: number,
    key:
      | "popular"
      | "new"
      | "fruits"
      | "seven"
      | "books"
      | "animals"
      | "buy_fs"
      | "recommended"
  ) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: "/api/bo/slots/filter",
          data: { id: id, key: key },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
      throw error;
    }
  }
  async changeCounter(id: number, counter: number) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: "/api/bo/slots/counter",
          data: { id: id, counter: counter },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
      throw error;
    }
  }

  async changeMinBet(id: number, min_bet: number) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: "/api/bo/slots/min_bet",
          data: { id, min_bet },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
      throw error;
    }
  }
  async changeReview(id: number, reviewed: boolean) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: "/api/bo/slots/reviewed",
          data: { id, reviewed },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
      throw error;
    }
  }
}

export const lobbyService = new LobbyService(httpService);
