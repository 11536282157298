import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { lobbyService } from "../../services/lobby.service";

export const useChangeFilter = (
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (data: {
      id: number;
      key:
        | "popular"
        | "new"
        | "recommended"
        | "fruits"
        | "books"
        | "seven"
        | "animals"
        | "buy_fs";
    }) => await lobbyService.changeFilter(data.id, data.key),
    onSuccess: (data) => {
      openHandler(data.message);
      client.invalidateQueries({ queryKey: ["mySlots"] });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
