import { Box, Typography } from "@mui/material";
import React from "react";
import { BORDER, COLORS } from "../../../../consts";
type IPaymentCardProps = {
  item: any;
};
export const PaymentCard = ({ item }: IPaymentCardProps) => {
  return (
    <Box
      sx={{
        padding: "4px 16px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderBottom: BORDER.PRIMARY,
        color: COLORS.DARKGREY,
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          maxWidth: "65px",
          fontSize: "12px",
          lineHeight: "12px",
          textAlign: "center",
        }}
      >
        {new Date(+item.payment_date).toLocaleString()}
      </Typography>
      <Box sx={{ width: "170px" }}>{item.credentials}</Box>
      <Typography
        variant="subtitle2"
        sx={{
          width: "220px",
          textAlign: "center",
          fontWeight: 400,
          textDecoration: "underline",
          color: COLORS.BLUE,
        }}
      >
        {item.id}
      </Typography>
      <Box sx={{ width: "110px" }}>{item.method_id?.name}</Box>
      <Box sx={{ width: "110px" }}>
        <Typography variant="subtitle2">{(+item.amount).toFixed(2)}</Typography>
      </Box>
      <Typography variant="subtitle2" sx={{ width: "70px" }}>
        {item.currency.name}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          color: item.type === "refill" ? COLORS.GREEN : COLORS.ORANGE,
          border: `1px solid ${
            item.type === "refill" ? COLORS.GREEN : COLORS.ORANGE
          }`,
          width: "100px",
          textAlign: "center",
        }}
      >
        {item.type === "refill" ? "Депозит" : "Вывод"}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          width: "100px",
          textAlign: "center",
          textTransform: "capitalize",
          color:
            item.status === "success" || item.status === "approved"
              ? COLORS.GREEN
              : item.status === "error" ||
                item.status === "failed" ||
                item.status === "rejected"
              ? COLORS.RED
              : COLORS.ORANGE,
          border: `1px solid ${
            item.status === "success" || item.status === "approved"
              ? COLORS.GREEN
              : item.status === "error" ||
                item.status === "failed" ||
                item.status === "rejected"
              ? COLORS.RED
              : COLORS.ORANGE
          }`,
        }}
      >
        {item.status}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          maxWidth: "65px",
          fontSize: "12px",
          lineHeight: "12px",
          textAlign: "center",
        }}
      >
        {new Date(+item.payment_date).toLocaleString()}
      </Typography>
    </Box>
  );
};
