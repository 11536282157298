import styled, { keyframes } from "styled-components";
import { BORDERRADIUS } from "../../consts";

const loaderAnimation = keyframes`
    0%{
        height: 20px;
    }
    20%{        
        height: 60px;
    }
    40%{
        height: 20px;
    }
    100%{
        height: 20px;
    }
`;

export const LoaderStyled = styled.div<{ transparent?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.transparent ? "transparent" : "#ffffffde"};
  border-radius: ${BORDERRADIUS.PRIMARY};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  z-index: 1000;
  .animation0 {
    height: 20px;
    animation: ${loaderAnimation} 1s ease-in 0s infinite;
  }
  .animation1 {
    height: 20px;
    animation: ${loaderAnimation} 1s ease-in 0.1s infinite;
  }
  .animation2 {
    height: 20px;
    animation: ${loaderAnimation} 1s ease-in 0.2s infinite;
  }
  .animation3 {
    height: 20px;
    animation: ${loaderAnimation} 1s ease-in 0.3s infinite;
  }
  .animation4 {
    height: 20px;
    animation: ${loaderAnimation} 1s ease-in 0.4s infinite;
  }
  .animation5 {
    height: 20px;
    animation: ${loaderAnimation} 1s ease-in 0.5s infinite;
  }
  .animation6 {
    height: 20px;
    animation: ${loaderAnimation} 1s ease-in 0.6s infinite;
  }
`;
