import styled from "styled-components";
import { createGlobalStyle } from "styled-components";
import { COLORS } from "./consts";

export const AppContainer = styled.div`
  display: grid;
  grid:
    "header header"
    "column1 column2 "
    "column3 column4" / 30% 30%;
  gap: 10px;
  & > div {
    &:nth-child(1) {
      grid-area: header;
      height: 50px;
    }
    &:nth-child(2) {
      grid-area: column1;
    }
    &:nth-child(3) {
      grid-area: column2;
    }
    &:nth-child(4) {
      grid-area: column3;
    }
    &:nth-child(5) {
      grid-area: column4;
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ul,
  ol {
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  body {
    font-family: Roboto, sans-serif;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    background-color: ${COLORS.SECONDARY_BG};
    color: ${COLORS.PRIMARY};
  }

  ul,
  ol {
    list-style: none;
  }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  img {
    max-width: 100%;
    display: block;
  }

  article > * + * {
    margin-top: 1em;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  a, a:visited {
    text-decoration: none;
    color: inherit;
  }
  `;
