import React from "react";
import { RouterProvider } from "react-router-dom";
import { routers } from "./router";
import { Portal } from "./components/portal/portal";
import { MyAlert } from "./components/alert/alert";
import AlertProvider, { useAlert } from "./hooks/notification/useAlert";
import { useAuth, EmployeeContext } from "./hooks/auth/useAuth";
import { BrandContext, useBrands } from "./hooks/brand/useBrand";

export const App = () => {
  const alert = useAlert();
  const employee = useAuth();
  const brand = useBrands();
  return (
    <AlertProvider alert={alert}>
      <EmployeeContext.Provider value={employee}>
        <BrandContext.Provider value={brand}>
          <RouterProvider router={routers} />
          {alert.open && (
            <Portal>
              <MyAlert
                message={alert.errorMessage.message}
                error={alert.errorMessage.error}
                closeHandler={alert.closeHandler}
              />
            </Portal>
          )}
        </BrandContext.Provider>
      </EmployeeContext.Provider>
    </AlertProvider>
  );
};
