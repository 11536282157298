import React from "react";
import { Box } from "@mui/material";
import { COLORS, BORDERRADIUS } from "../../../consts";
import { InputB } from "../../../components/input-b/inputB";
import SearchIcon from "@mui/icons-material/Search";
import { MyButton } from "../../../components/button/button";
import { useFormik } from "formik";

type AccessSearchProps = {
  openModal: () => void;
};

export const AccessSearch = ({ openModal }: AccessSearchProps) => {
  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: () => {},
  });
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        padding: "10px 16px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          width: "500px",
          gap: "5px",
        }}
      >
        <InputB
          id={"search"}
          onChange={formik.handleChange}
          value={formik.values.search}
          label={"Search"}
        >
          <SearchIcon />
        </InputB>
        <MyButton text="Search" sx={{ marginBottom: "10px" }}></MyButton>
      </Box>
      <MyButton
        type="button"
        cancel
        text="Add new user"
        sx={{ marginBottom: "10px" }}
        onClick={openModal}
      />
    </Box>
  );
};
