import { AxiosError, AxiosResponse } from "axios";
import { HttpService, httpService } from "./httpService";
import { IUser, IUsersProfit, IVerificationStatus } from "../types/user.type";
import { AlertError } from "../errors/AlertError";
import { IMessage } from "../types/message.type";

class UserService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  async getUser(userId: string) {
    try {
      const response = await this.httpService.get<AxiosResponse<IUser>>(
        {
          url: `/api/bo/${userId}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
  async searchUsers(query: string) {
    // const newQuery = new URLSearchParams();
    // for (const key in search) {
    //   if (search[key] !== undefined) {
    //     // console.log(search[key]);
    //     //@ts-ignore
    //     newQuery.append(key, search[key]);
    //   }
    // }
    try {
      const response = await this.httpService.get<
        AxiosResponse<{
          count: number;
          page: number;
          perPage: number;
          users: IUser[];
        }>
      >(
        {
          url: `/api/bo/${query}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
  async activate(id: string) {
    try {
      const response = await this.httpService.post<
        AxiosResponse<{
          message: string;
        }>
      >(
        {
          url: `/api/bo/activate`,
          data: { id },
        },
        true
      );
      return response.data.message;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
  async changeContacts(
    data: { email: string; phone: string | null },
    id: string
  ) {
    try {
      const response = await this.httpService.post<
        AxiosResponse<{
          message: string;
        }>
      >(
        {
          url: `/api/bo/change-contacts`,
          data: { contacts: { ...data }, id },
        },
        true
      );
      return response.data.message;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
  async changeProfile(
    data: { name: string; surname: string; secondname: string; date: string },
    id: string
  ) {
    try {
      const response = await this.httpService.post<
        AxiosResponse<{
          message: string;
        }>
      >(
        {
          url: `/api/bo/change-profile`,
          data: { profile: { ...data }, id },
        },
        true
      );
      return response.data.message;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async getRestrictions(id: string) {
    try {
      const response = await this.httpService.get<
        AxiosResponse<{
          id: number;
          withdrawal: boolean;
          deposits: boolean;
          slots: boolean;
          login: boolean;
        }>
      >(
        {
          url: `/api/bo/restrictions?id=${id}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async changeRestrictions(
    data: {
      withdrawal: boolean;
      deposits: boolean;
      slots: boolean;
      login: boolean;
    },
    id: string
  ) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/restrictions`,
          data: { restrictions: data, id },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
  async verification_status(id: string) {
    try {
      const response = await this.httpService.get<
        AxiosResponse<IVerificationStatus>
      >(
        {
          url: `/api/bo/player/verification/status?id=${id}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async getProfit(id: string) {
    try {
      const response = await this.httpService.get<AxiosResponse<IUsersProfit>>(
        {
          url: `/api/bo/player/profit?id=${id}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
}

export const userService = new UserService(httpService);
