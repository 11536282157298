import { useQuery } from "@tanstack/react-query";
import { paymentsService } from "../../services/paymentsService";

export const useGetPendingPayments = () => {
  return useQuery({
    queryFn: () => paymentsService.getPendingPayments(),
    queryKey: ["pendingPayments"],
    staleTime: 0,
    gcTime: 0,
  });
};
