import React from "react";
import { Box } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { BORDER, BORDERRADIUS, COLORS } from "../../../../consts";
import { IPayment } from "../../../../types/payment.type";

type IQueueColumnsProps = {
  selected: IPayment[];
  payments: IPayment[] | undefined;
  selectAllHandler: (payments: IPayment[]) => void;
};

export const QueueColumns = ({
  selected,
  payments,
  selectAllHandler,
}: IQueueColumnsProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: COLORS.PRIMARY,
        padding: "5px 0",
        borderRadius: BORDERRADIUS.PRIMARY,
        fontSize: "13px",
        marginBottom: "10px",
        textAlign: "center",
        color: "black",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
          border: BORDER.PRIMARY,
          borderColor:
            selected.length === payments?.length ? COLORS.GREEN : COLORS.GREY,
          borderRadius: "2px",
          backgroundColor:
            selected.length === payments?.length
              ? COLORS.GREEN
              : COLORS.SECONDARY,
          position: "relative",
          width: "12px",
          height: "12px",
          marginLeft: "15px",
          flexShrink: 0,
        }}
        onClick={() => selectAllHandler(payments || [])}
      >
        {selected.length === payments?.length && (
          <DoneIcon
            sx={{
              position: "absolute",
              top: "-12.5%",
              left: "-12.5%",
              width: "125%",
              height: "125%",
              color: "white",
            }}
          />
        )}
      </Box>
      <Box sx={{ width: "100px" }}>ID</Box>
      <Box sx={{ width: "150px" }}>Transaction ID</Box>
      <Box sx={{ width: "80px" }}>Amount</Box>
      {/* <Box sx={{ width: "70px" }}>Currency</Box> */}
      <Box sx={{ width: "100px" }}>Type</Box>
      <Box sx={{ width: "100px" }}>Status</Box>
      <Box sx={{ width: "100px" }}>System</Box>
      <Box sx={{ width: "100px" }}>Method</Box>
      <Box sx={{ width: "230px", marginRight: "20px" }}>Credentials</Box>
      <Box sx={{ width: "65px" }}>Date</Box>
    </Box>
  );
};
