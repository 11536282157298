import React from "react";
import { IInitialValues } from "../typing/initialValues";
import { BonusPreview } from "./preview/BonusPreview";
import { ModalTemplate } from "../../../../components/modal/modal.template";
import { EmailPreview } from "./preview/EmailPreview";
import { Box } from "@mui/material";
import { IEditInitialValues } from "../../edit/typing/initialValues";

type IPreviewModal = {
  values: IInitialValues | IEditInitialValues;
  images: {
    [key: string]: File | null;
    bg_image: File | null;
    sub_image: File | null;
    email_image: File | null;
  };
  open: boolean;
  close: () => void;
};

export const PreviewModal = ({
  open,
  close,
  values,
  images,
}: IPreviewModal) => {
  return (
    <ModalTemplate
      open={open}
      close={close}
      confirm={() => {}}
      heading={"Preview"}
      maxWidth="calc(100% - 60px)"
    >
      <Box
        sx={{
          display: "flex",
          gap: "40px",
          overflow: "auto",
          maxHeight: "70vh",
        }}
      >
        <BonusPreview
          active={true}
          sub_title={values.description}
          title={values.display_name}
          type={values.type}
          sub_type={values.sub_type}
          min_dep={values.min_dep}
          wager_fs={values.wager_fs}
          wager_pers={values.wager_pers}
          bg_img={{
            name: "desktop_bg",
            src: images.bg_image ? URL.createObjectURL(images.bg_image) : "",
          }}
          sub_img={{
            name: "desktop_sub",
            src: images.sub_image ? URL.createObjectURL(images.sub_image) : "",
          }}
        />
        <EmailPreview bonus={values} images={images} />
      </Box>
    </ModalTemplate>
  );
};
