import { Box } from "@mui/material";
import React from "react";
import { BORDERRADIUS, COLORS } from "../../../consts";

export const EmployeesComponent = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "10px 16px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "5px 0",
          backgroundColor: COLORS.DARKGREY,
          borderRadius: BORDERRADIUS.PRIMARY,
          textAlign: "center",
          color: COLORS.LIGHTGREY,
          fontWeight: 900,
          fontSize: "16px",
          marginBottom: "10px",
        }}
      >
        Employees
      </Box>
    </Box>
  );
};
