import styled from "styled-components";

export const InfoContainerStyled = styled.div`
  height: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 0.7fr repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "info verification restrictions"
    "info comments contacts "
    "profits partner asd";
  padding: 10px;
  & > div:nth-child(1) {
    grid-area: info;
    align-self: flex-start;
  }
  & > div:nth-child(2) {
    grid-area: verification;
  }
  & > div:nth-child(3) {
    grid-area: restrictions;
  }
  & > div:nth-child(4) {
    grid-area: contacts;
  }
  & > div:nth-child(5) {
    grid-area: comments;
  }
  & > div:nth-child(6) {
    grid-area: profits;
  }
  & > div:nth-child(7) {
    grid-area: partners;
  }
`;
