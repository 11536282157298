import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../../errors/AlertError";
import { playerBonusesService } from "../../../services/playerBonuses.service";

export const useChangeStatus = (
  alertHandler: (message: string, error?: string) => void,
  query: string,
  callback: () => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({ id, status }: { id: number; status: string }) =>
      playerBonusesService.changeStatus(id, status),
    onSuccess: (data) => {
      callback();
      client.invalidateQueries({
        queryKey: ["playerBonuses", query],
      });
      alertHandler(data ? data.message : "");
    },
    onError: (err: AlertError) => {
      alertHandler(err.message, err.code);
    },
  });
};
