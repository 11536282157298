import React from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import { BORDERRADIUS, COLORS } from "../../consts";
import { InputB } from "../../components/input-b/inputB";
import { MyButton } from "../../components/button/button";
import { InputDate } from "../../components/inputdate/inputdate";
import { IUser } from "../../types/user.type";
import { SearchedList } from "../../modules/searchedList/searchedList";
import { AppMenu } from "../../components/menu/menu";
import { InputSelect } from "../../components/inputSelect/input.select";
import { useGetBrands } from "../../hooks/brand/useGetBrands";
import { queryCreator } from "../../utils/queryToObj";

const initialValues: {
  search: string;
  from: string;
  to: string;
  brand_id: number | undefined;
} = {
  search: "",
  from: "",
  to: "",
  brand_id: undefined,
};

export const Search = () => {
  const [shouldFetch, setShouldFetch] = React.useState(false);
  const [fetch, setFetch] = React.useState<{
    search: string;
    reg_from?: string;
    reg_to?: string;
    brand_id?: number;
  }>({ search: "" });
  const [list, setList] = React.useState<IUser[]>([]);
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setFetch({
        search: values.search,
        reg_from: values.from || undefined,
        reg_to: values.to || undefined,
        brand_id: values.brand_id || undefined,
      });
      setShouldFetch(true);
    },
  });
  const fromHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({ ...formik.values, from: date.valueOf().toString() });
      return;
    }
    formik.setValues({ ...formik.values, from: "" });
  };
  const toHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({
        ...formik.values,
        to: (date.valueOf() + 86400000).toString(),
      });
      return;
    }
    formik.setValues({ ...formik.values, to: "" });
  };
  const { data: brands } = useGetBrands();

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <AppMenu />
      </Box>
      <Box
        sx={{
          margin: "2.5% 5%",
          backgroundColor: COLORS.PRIMARY_BG,
          padding: "20px 40px",
          borderRadius: BORDERRADIUS.PRIMARY,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              gap: "10px",
            }}
          >
            <InputB
              id="search"
              onChange={formik.handleChange}
              value={formik.values.search}
              label="Search"
            />
            <MyButton text="Search" sx={{ marginBottom: "10px" }}></MyButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              gap: "10px",
            }}
          >
            <InputDate
              id="from"
              label="Registration from"
              disabled={false}
              value={
                formik.values.from === ""
                  ? formik.values.from
                  : new Date(+formik.values.from)
              }
              onChange={fromHandler}
            />
            <InputDate
              id="to"
              label="Registration to"
              disabled={false}
              value={
                formik.values.to === ""
                  ? formik.values.to
                  : new Date(+formik.values.to)
              }
              onChange={toHandler}
            />
            {brands && (
              <Box
                sx={{
                  maxWidth: "182px",
                  width: "100%",
                  paddingBottom: "10px",
                }}
              >
                <InputSelect
                  label={"Brand"}
                  dataArray={brands.map((brand) => ({
                    value: brand.id,
                    text: brand.brand_name,
                  }))}
                  selectFunction={(value: any) => {
                    formik.setValues({
                      ...formik.values,
                      brand_id: +value,
                    });
                  }}
                />
              </Box>
            )}
          </Box>
        </form>
      </Box>
      {shouldFetch && (
        <SearchedList
          query={queryCreator(fetch)}
          list={list}
          setList={setList}
        />
      )}
    </Box>
  );
};
