import React from "react";
import { Box, TextareaAutosize } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { useFormik } from "formik";
import { EditButton } from "../../../../components/editbutton/editbutton";
import { MyButton } from "../../../../components/button/button";
import { IUser } from "../../../../types/user.type";

import { BORDERRADIUS, COLORS } from "../../../../consts";

export const Comments = () => {
  const [edit, setEdit] = React.useState(false);
  const [user]: IUser[] = useOutletContext();
  const initialValues = user.contacts
    ? {
        comment: "",
      }
    : {
        comment: "",
      };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      // console.log("first");
    },
  });
  const closeHandler = () => {
    formik.setValues(initialValues);
    setEdit(false);
  };
  const openHandler = () => {
    setEdit(true);
  };
  return (
    <Box
      sx={{
        padding: "5px 20px 60px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        position: "relative",
      }}
    >
      <EditButton onClick={openHandler} />
      <form>
        <TextareaAutosize
          id="comment"
          style={{
            marginTop: "10px",
            width: "100%",
            height: "80px",
            padding: "6px 10px",
            backgroundColor: COLORS.PRIMARY_BG,
            borderRadius: BORDERRADIUS.PRIMARY,
            resize: "none",
            fontSize: "13px",
          }}
          disabled={!edit}
          value={formik.values.comment}
          onChange={formik.handleChange}
        ></TextareaAutosize>
        {edit && (
          <Box
            sx={{
              display: "flex",
              gap: "14px",
              justifyContent: "flex-end",
              position: "absolute",
              bottom: "20px",
              right: "20px",
            }}
          >
            <MyButton
              type="button"
              text="Отмена"
              onClick={closeHandler}
              cancel
            />
            <MyButton type="submit" text="Сохранить" />
          </Box>
        )}
      </form>
    </Box>
  );
};
