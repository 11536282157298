import { Box } from "@mui/material";
import React, { useState } from "react";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { MyButton } from "../../../../components/button/button";
import { IPayment } from "../../../../types/payment.type";
import { useApprovePayment } from "../../../../hooks/payments/useApprovePayment";
import { useGetComments } from "../../../../hooks/payments/useGetComments";
import { useHoldPayment } from "../../../../hooks/payments/useHoldPayments";
import { useRejectPayment } from "../../../../hooks/payments/useRejectPayments";
import { InputSelect } from "../../../../components/inputSelect/input.select";
import { useGetPaymentSystemBalance } from "../../../../hooks/payments/useGetPaymentSystemBalance";

type IPaymentSystemProps = {};

export const PaymentSystem = ({}: IPaymentSystemProps) => {
  const { data: balance } = useGetPaymentSystemBalance();

  if (!balance) return <></>;
  return (
    <Box
      sx={{
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        padding: "10px 16px",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "10px",
          }}
        >
          {balance?.data.map((el: any) => (
            <Box key={el.system}>
              <Box>System: {el.system}</Box>
              <Box>Available Balance: {el.available}</Box>
              <Box>Hold Balance: {el.hold}</Box>
              <Box>Currency: {el.currency}</Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
