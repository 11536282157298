import React, { useState } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { IPayment } from "../../../../types/payment.type";
import { BORDER, BORDERRADIUS, COLORS } from "../../../../consts";
import DoneIcon from "@mui/icons-material/Done";
import { Paymentmodal } from "../../modal";

type IPendingPaymentsProps = {
  isSuccess: boolean;
  payments: IPayment[] | undefined;
  selected: IPayment[];
  selectHandler: (payments: IPayment) => void;
};
export const PendingPayments = ({
  isSuccess,
  payments,
  selected,
  selectHandler,
}: IPendingPaymentsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<Record<string, any>>({});

  const handleOpenModal = (data: Record<string, any>) => {
    setModalData(data);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 122.25px)",
        width: "100%",
        overflowY: "auto",
        textAlign: "center",
      }}
    >
      {isSuccess &&
        payments?.map((payment) => {
          const select = !!selected.find((item) => item.id === payment.id);
          return (
            <Box
              key={payment.id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                padding: "5px 0",
                borderRadius: BORDERRADIUS.PRIMARY,
                fontSize: "14px",
                color: COLORS.DARKGREY,
                borderBottom: `1px solid ${COLORS.SECONDARY}`,
              }}
            >
              <Box
                sx={{
                  border: BORDER.PRIMARY,
                  borderColor: select ? COLORS.GREEN : COLORS.GREY,
                  borderRadius: "2px",
                  backgroundColor: select ? COLORS.GREEN : COLORS.SECONDARY,
                  position: "relative",
                  width: "12px",
                  height: "12px",
                  marginLeft: "15px",
                  flexShrink: 0,
                  cursor: "pointer",
                }}
                onClick={() => selectHandler(payment)}
              >
                {select && (
                  <DoneIcon
                    sx={{
                      position: "absolute",
                      top: "-12.5%",
                      left: "-12.5%",
                      width: "125%",
                      height: "125%",
                      color: "white",
                    }}
                  />
                )}
              </Box>
              <Link to={`/player/${payment.user.id}/info`} target="_blank">
                <Box
                  sx={{
                    fontWeight: 700,
                    width: "100px",
                    "&:hover": {
                      backgroundColor: COLORS.SECONDARY_BG,
                      cursor: "pointer",
                    },
                  }}
                >
                  {payment.user.id}
                </Box>
              </Link>

              <Box
                sx={{
                  width: "150px",
                  textAlign: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textWrap: "nowrap",
                  "&:hover": {
                    backgroundColor: COLORS.SECONDARY_BG,
                    cursor: "pointer",
                  },
                }}
                onDoubleClick={() => handleOpenModal(payment)}
              >
                {payment.id}
              </Box>
              <Box
                sx={{
                  width: "80px",
                  textAlign: "center",
                }}
              >
                {(+payment.amount).toFixed(2)} {payment.currency.name}
              </Box>
              {/* <Box sx={{ width: "70px", textAlign: "center" }}>
                {payment.currency.name}
              </Box> */}
              <Box
                sx={{
                  width: "100px",
                  textAlign: "center",
                  color: COLORS.ORANGE,
                  border: `1px solid ${COLORS.ORANGE}`,
                  padding: "0 3px",
                }}
              >
                {payment.type}
              </Box>
              <Box
                sx={{
                  width: "100px",
                  textAlign: "center",
                  color: COLORS.ORANGE,
                  border: `1px solid ${COLORS.ORANGE}`,
                  padding: "0 3px",
                }}
              >
                {payment.status}
              </Box>
              <Box sx={{ width: "100px", textAlign: "center" }}>
                {payment.payment_system?.name}
              </Box>
              <Box sx={{ width: "100px", textAlign: "center" }}>
                {payment.method_id?.name}
              </Box>
              <Box
                sx={{
                  width: "210px",
                  textAlign: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textWrap: "nowrap",
                  marginRight: "20px",
                }}
              >
                {payment.credentials}
              </Box>
              <Box
                sx={{
                  width: "75px",
                  textAlign: "center",
                  fontSize: "12px",
                }}
              >
                {new Date(+payment.payment_date).toLocaleString()}
              </Box>
            </Box>
          );
        })}
      <Paymentmodal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        data={modalData}
      />
    </Box>
  );
};
