import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { AppMenu } from "../../components/menu/menu";
import { COLORS } from "../../consts";
import { MyButton } from "../../components/button/button";
import { useSearchParams } from "react-router-dom";
import { AllSlots } from "../../modules/lobby/All/AllSlots";
import { MySlots } from "../../modules/lobby/MySlots/MySlots";

export const Lobby = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const switchHandler = (button: string) => {
    setSearchParams((search) => {
      search.delete(button === "myslots" ? "allslots" : "myslots");
      search.append(button, "true");
      return search;
    });
  };

  useEffect(() => {
    if (!searchParams.get("myslots") && !searchParams.get("allslots")) {
      setSearchParams((search) => {
        search.append("myslots", "true");
        return search;
      });
    }
  }, []);
  return (
    <Box sx={{ display: "flex", height: "100vh", flexDirection: "column" }}>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <AppMenu />
        <MyButton
          text={"My slots"}
          sx={{
            padding: "0",
            color: COLORS.PRIMARY,
            backgroundColor: COLORS.PRIMARY_BG,
            "&:hover": { backgroundColor: COLORS.PRIMARY_BG + "d0" },
          }}
          disabled={!!searchParams.get("myslots")}
          onClick={() => {
            switchHandler("myslots");
          }}
        />
        <MyButton
          text={"Get All"}
          sx={{
            padding: "0",
            color: COLORS.PRIMARY,
            backgroundColor: COLORS.PRIMARY_BG,
            "&:hover": { backgroundColor: COLORS.PRIMARY_BG + "d0" },
          }}
          disabled={!!searchParams.get("allslots")}
          onClick={() => {
            switchHandler("allslots");
          }}
        />
      </Box>
      <Box
        sx={{
          flex: "1 1 auto",
          margin: "10px",
          height: "calc(100% - 56px)",
        }}
      >
        {!!searchParams.get("myslots") && <MySlots />}
        {!!searchParams.get("allslots") && <AllSlots />}
      </Box>
    </Box>
  );
};
