import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BORDER, BORDERRADIUS, COLORS } from "../../consts";
import { AppMenu } from "../../components/menu/menu";
import { MyButton } from "../../components/button/button";
import { BrandModal } from "../../modules/brand/brandModal/brand.modal";
import { useGetBrands } from "../../hooks/brand/useGetBrands";
import { AlertContext } from "../../hooks/notification/useAlert";
import { Mirrors } from "../../modules/brand/mirrors/Mirrors";
import { EmployeeContext } from "../../hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";

export const Brand = () => {
  const [createModal, setCreateModal] = useState(false);
  const [choosedBrandId, setChoosedBrandId] = useState<number | undefined>(
    undefined
  );
  const { logout } = useContext(EmployeeContext);
  const navigator = useNavigate();
  const { alertHandler } = useContext(AlertContext);
  const { data: brands, error } = useGetBrands();
  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
      if (error.message === "Unathorized") {
        logout();
        navigator("/");
      }
    }
  }, [error]);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <AppMenu />
      </Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 36px)",
            width: "100%",
          }}
        >
          <Box
            sx={{
              margin: "10px 10px",
              padding: "10px 16px",
              backgroundColor: COLORS.PRIMARY_BG,
              borderRadius: BORDERRADIUS.PRIMARY,
              display: "flex",
              gap: "10px",
            }}
          >
            <MyButton
              text="Add Brand"
              onClick={() => {
                setCreateModal(true);
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              backgroundColor: COLORS.PRIMARY_BG,
              borderRadius: BORDERRADIUS.PRIMARY,
              margin: "0 10px 10px",
              padding: "10px 16px",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
                backgroundColor: COLORS.GREY,
                width: "100%",
                padding: "6px 0",
                fontSize: "13px",
                color: COLORS.DARKGREY,
                borderRadius: BORDERRADIUS.PRIMARY,
                textAlign: "center",
              }}
            >
              <Box sx={{ width: "200px" }}>Brand name</Box>
              <Box sx={{ width: "200px" }}>Hostname</Box>
              <Box sx={{ width: "200px" }}>Mirror hostname</Box>
              <Box sx={{ width: "500px" }}>Currencies</Box>
            </Box>

            {brands?.map((brand) => {
              return (
                <Box
                  key={brand.id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "6px 0",
                    fontSize: "16px",
                    color: COLORS.DARKGREY,
                    textAlign: "center",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: COLORS.SECONDARY_BG,
                    },
                  }}
                  onClick={() => {
                    setChoosedBrandId(brand.id);
                  }}
                >
                  <Box sx={{ width: "200px" }}>{brand.brand_name}</Box>
                  <Box sx={{ width: "200px" }}>{brand.hostname}</Box>
                  <Box sx={{ width: "200px" }}>{brand.mirror_hostname}</Box>
                  <Box sx={{ width: "500px", display: "flex" }}>
                    {brand.currencies.map((currency) => {
                      return (
                        <Box
                          key={currency.name + "_" + currency.id}
                          sx={{
                            border: BORDER.PRIMARY,
                            borderColor: currency.default
                              ? COLORS.GREEN
                              : "transparent",
                            padding: "0 5px",
                          }}
                        >
                          {currency.name}
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        {choosedBrandId && (
          <Mirrors
            brandId={choosedBrandId}
            logout={logout}
            navigator={navigator}
          />
        )}
      </Box>
      <BrandModal createModal={createModal} setCreateModal={setCreateModal} />
    </Box>
  );
};
