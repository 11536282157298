import React, { useContext } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import { AlertContext } from "../../../hooks/notification/useAlert";
import { AppMenu } from "../../../components/menu/menu";
import { COLORS } from "../../../consts";

import { useGetRoles } from "../../../hooks/access/useGetRoles";
import { RoleComponent } from "../../../modules/access/roleID/role";
import { PermissionsComponent } from "../../../modules/access/roleID/permissions";
import { EmployeesComponent } from "../../../modules/access/roleID/employees";

export const RoleId = () => {
  const { alertHandler } = useContext(AlertContext);
  const { roleId } = useParams();
  const { data: role } = useGetRoles(roleId ? +roleId : undefined);

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <AppMenu />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          margin: "10px",
          height: "calc(100vh - 56px)",
        }}
      >
        <Box sx={{ width: "100%" }}>
          {role?.map((role) => {
            return (
              <RoleComponent
                role={role}
                alertHandler={alertHandler}
                key={role.id}
              />
            );
          })}
        </Box>
        <PermissionsComponent role={role} alertHandler={alertHandler} />
        <EmployeesComponent />
      </Box>
    </Box>
  );
};
