import React from "react";
import ReactDOM from "react-dom/client";
import * as Styled from "./App.styled";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { App } from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const client = new QueryClient();
root.render(
  <QueryClientProvider client={client}>
    <App />
    <Styled.GlobalStyles />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
