import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Modal, Typography } from "@mui/material";
import { BORDER, BORDERRADIUS, COLORS } from "../../../consts";
import { MyButton } from "../../../components/button/button";
import { IPayment } from "../../../types/payment.type";
import { Paymentmodal } from "../modal";
import { useGetExternalPaymentsData } from "../../../hooks/payments/useGetExternalPaymentData";

type IAllPaymentsListProps = {
  data: IPayment[];
  showMore: () => void;
  orderHandler: (key: string) => void;
};

export const AllPaymentsList = ({
  data,
  showMore,
  orderHandler,
}: IAllPaymentsListProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<Record<string, any>>({});
  const [externalData, setExternalData] = useState<Record<string, any>>({});
  const [query, setQuery] = useState("");

  const { mutate, data: responseData } = useGetExternalPaymentsData(query);
  const handleOpenModal = (data: Record<string, any>) => {
    setModalData(data);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFetchExternalData = (id: string) => {
    setQuery(id);
    mutate(id);
  };

  useEffect(() => {
    if (responseData) {
      setExternalData(responseData.data);
      handleOpenModal(responseData.data);
    }
  }, [responseData]);

  return (
    <Box
      sx={{
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        padding: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: COLORS.PRIMARY,
          padding: "5px 16px",
          borderRadius: BORDERRADIUS.PRIMARY,
          fontSize: "13px",
          marginBottom: "10px",
          color: "black",
          gap: "10px",
          textAlign: "center",
        }}
      >
        <Box sx={{ width: "81px" }}>User ID</Box>

        <Box
          sx={{ width: "110px", cursor: "pointer" }}
          onClick={() => {
            orderHandler("amount");
          }}
        >
          Amount
        </Box>
        {/* <Box sx={{ width: "70px" }}>Currency</Box> */}
        <Box sx={{ width: "220px" }}>Tansaction ID</Box>
        <Box sx={{ width: "220px" }}>Payment Method</Box>
        <Box sx={{ width: "100px" }}>Type</Box>
        <Box sx={{ width: "100px" }}>Internal Status</Box>
        <Box sx={{ width: "100px" }}>External Status</Box>
        <Box
          sx={{ width: "65px", cursor: "pointer" }}
          onClick={() => {
            orderHandler("payment_date");
          }}
        >
          Date
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: COLORS.SECONDARY,
          height: "calc(100% - 75.5px)",
          overflow: "auto",
        }}
      >
        {data &&
          data.map((item: any) => {
            const baseColor =
              item.status === "success" || item.status === "approved"
                ? COLORS.GREEN
                : item.status === "error" ||
                  item.status === "failed" ||
                  item.status === "rejected"
                ? COLORS.RED
                : COLORS.ORANGE;

            const hoverColor =
              baseColor === COLORS.GREEN
                ? "#388e3c"
                : baseColor === COLORS.RED
                ? "#c62828"
                : "#f57c00";

            return (
              <Box
                sx={{
                  padding: "4px 16px",
                  backgroundColor: COLORS.PRIMARY_BG,
                  borderBottom: BORDER.PRIMARY,
                  color: COLORS.DARKGREY,
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
                key={item.id}
              >
                <Link to={`/player/${item.user.id}/info`} target="_blank">
                  <Typography
                    variant="subtitle2"
                    sx={{
                      height: "100%",
                      width: "100%",
                      "&:hover": {
                        backgroundColor: COLORS.SECONDARY_BG,
                        cursor: "pointer",
                      },
                    }}
                  >
                    {item.user.id}
                  </Typography>
                </Link>
                <Box sx={{ width: "110px" }}>
                  <Typography variant="subtitle2">
                    {(+item.amount).toFixed(2)} {item?.currency?.name}
                  </Typography>
                </Box>
                {/* <Typography
                  variant="subtitle2"
                  sx={{ width: "70px", textAlign: "center" }}
                >{item.currency.name}</Typography> */}
                <Typography
                  variant="subtitle2"
                  sx={{
                    width: "220px",
                    textAlign: "center",
                    fontWeight: 400,
                    textDecoration: "underline",
                    color: COLORS.BLUE,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: COLORS.SECONDARY_BG,
                      cursor: "pointer",
                    },
                  }}
                  onDoubleClick={() => handleOpenModal(item)}
                >
                  {item.id}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    cursor: "pointer",
                    width: "220px",
                    textAlign: "center",
                    fontWeight: 400,
                    textDecoration: "underline",
                    color: COLORS.BLUE,
                    "&:hover": {
                      backgroundColor: COLORS.SECONDARY_BG,
                      cursor: "pointer",
                    },
                  }}
                  onDoubleClick={() => handleOpenModal(item.method_id)}
                >
                  {item?.method_id?.name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color:
                      item.type === "refill" ? COLORS.GREEN : COLORS.ORANGE,
                    border: `1px solid ${
                      item.type === "refill" ? COLORS.GREEN : COLORS.ORANGE
                    }`,
                    width: "100px",
                    textAlign: "center",
                  }}
                >
                  {item.type === "refill" ? "Депозит" : "Вывод"}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    width: "100px",
                    textAlign: "center",
                    textTransform: "capitalize",
                    color: baseColor,
                    border: `1px solid ${baseColor}`,
                  }}
                >
                  {item.status}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    width: "100px",
                    textAlign: "center",
                    textTransform: "capitalize",
                    "&:hover": {
                      color: hoverColor,
                      borderColor: hoverColor,
                      backgroundColor: COLORS.SECONDARY_BG,
                      cursor: "pointer",
                    },
                    color: baseColor,
                    border: `1px solid ${baseColor}`,
                  }}
                  onDoubleClick={() => handleFetchExternalData(item.id)}
                >
                  {item.external_status}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    maxWidth: "80px",
                    fontSize: "12px",
                    lineHeight: "12px",
                    textAlign: "center",
                  }}
                >
                  {new Date(+item.payment_date).toLocaleString()}
                </Typography>
              </Box>
            );
          })}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", padding: "5px" }}>
        <MyButton text={"Show more"} onClick={showMore} />
      </Box>
      <Paymentmodal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        data={modalData}
      />
    </Box>
  );
};
