import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputCheckbox } from "../../../../components/inputcheckbox/inputcheckbox";
import { InputB } from "../../../../components/input-b/inputB";
import { MyButton } from "../../../../components/button/button";
import { BORDERRADIUS, COLORS } from "../../../../consts";
import { useFormik } from "formik";
import { InputDate } from "../../../../components/inputdate/inputdate";
import { InputSelect } from "../../../../components/inputSelect/input.select";

type ICasinoFilterProps = {
  playerID: string;
  page: number;
  games: string[];
  setObj: React.Dispatch<any>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setCasino: React.Dispatch<React.SetStateAction<any[]>>;
};
interface InitialValues {
  [key: string]: string | number | boolean | null | undefined;
  id: string;
  from: null | string;
  to: null | string;
  game_name?: string;
  status?: "close" | "pending";
}

const initialValues: InitialValues = {
  id: "",
  from: "",
  to: "",
  game_name: undefined,
  status: undefined,
};
export const CasinoFilter = ({
  playerID,
  page,
  games,
  setObj,
  setPage,
  setCasino,
}: ICasinoFilterProps) => {
  const [clearActive, setClearActive] = useState(true);
  const [submitActive, setSubmitActive] = useState(true);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      setClearActive(false);
      setSubmitActive(true);
      setPage(1);
      setCasino([]);
      setObj({
        playerID,
        page,
        ...formik.values,
      });
    },
  });

  const clearHandler = () => {
    setClearActive(true);
    formik.setValues(initialValues);
    setCasino([]);
    setPage(1);
    setObj({ playerID, page, ...initialValues });
  };

  const fromDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({ ...formik.values, from: date.valueOf().toString() });
      return;
    }
    formik.setValues({ ...formik.values, from: "" });
  };
  const toDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({ ...formik.values, to: date.valueOf().toString() });
      return;
    }
    formik.setValues({ ...formik.values, to: "" });
  };

  useEffect(() => {
    setObj({
      playerID,
      page,
      ...formik.values,
    });
  }, [page]);

  useEffect(() => {
    for (const key in formik.values) {
      if (formik.values[key] !== initialValues[key]) {
        setSubmitActive(false);
      }
    }
  }, [formik.values]);
  return (
    <Box
      sx={{
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
        padding: "10px 16px",
        margin: "10px",
        width: "400px",
      }}
    >
      <InputB
        id={"id"}
        onChange={formik.handleChange}
        value={formik.values.id}
        label={"Transaction ID"}
      />
      <InputDate
        id="from"
        label="From date"
        disabled={false}
        time
        value={
          formik.values.from === null
            ? ""
            : formik.values.from === ""
            ? formik.values.from
            : new Date(+formik.values.from)
        }
        onChange={fromDateHandler}
      />
      <InputDate
        id="to"
        label="To date"
        disabled={false}
        time
        value={
          formik.values.to === null
            ? ""
            : formik.values.to === ""
            ? formik.values.to
            : new Date(+formik.values.to)
        }
        onChange={toDateHandler}
      />
      <InputSelect
        label={"Status"}
        dataArray={[
          { value: undefined, text: "None" },
          { value: "close", text: "Close" },
          { value: "pending", text: "Pending" },
        ]}
        selectFunction={(value) => {
          formik.setValues({ ...formik.values, status: value });
        }}
      />
      <InputSelect
        label={"Game name"}
        dataArray={[
          { value: undefined, text: "None" },
          ...games.map((game) => {
            return { value: game, text: game };
          }),
        ]}
        selectFunction={(value) => {
          formik.setValues({ ...formik.values, game_name: value });
        }}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <MyButton
          disabled={clearActive}
          text="Clear"
          cancel
          type="button"
          onClick={clearHandler}
        />
        <MyButton
          disabled={submitActive}
          text="Filter"
          type="button"
          onClick={formik.handleSubmit}
        />
      </Box>
    </Box>
  );
};
