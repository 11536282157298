import { Box } from "@mui/material";
import { FormikErrors } from "formik";
import React, { useState } from "react";
import { ActivityFields } from "./activityFields/activityFields";
import { ImageUpload } from "./imageUpload/ImageUpload";
import { ActionFields } from "./actionFields/ActionFields";
import { IInitialValues } from "../typing/initialValues";
import { EmailContent } from "./emailContent/emailContent";
import { InputCheckbox } from "../../../../components/inputcheckbox/inputcheckbox";

interface IAdditionalFieldsProps {
  values: IInitialValues;
  images: {
    [key: string]: File | null;
    bg_image: File | null;
    sub_image: File | null;
  };
  openFSModal: () => void;
  openPersModal: () => void;
  openPreviewModal: () => void;
  imagesHandler: (
    key: "bg_image" | "sub_image" | "email_image",
    file: File | null
  ) => void;
  setValues: (
    values: React.SetStateAction<IInitialValues>,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IInitialValues>>;
  submitHandler: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  cancelHandler: () => void;
}

export const AdditionalFields = ({
  values,
  imagesHandler,
  setValues,
  openFSModal,
  openPersModal,
  openPreviewModal,
  submitHandler,
  cancelHandler,
}: IAdditionalFieldsProps) => {
  const [depositActivity, setDepositActivity] = useState(false);
  const [gameActivity, setGameActivity] = useState(false);
  const [email, setEmail] = useState(false);
  const dateDepositHandler = (date: Date) => {
    if (date !== null) {
      setValues({
        ...values,
        additional_items: {
          ...values.additional_items,
          deposit: values.additional_items.deposit
            ? {
                ...values.additional_items.deposit,
                date: (date.valueOf() + 86400000).toString(),
              }
            : undefined,
        },
      });
      return;
    }
    setValues({
      ...values,
      additional_items: {
        ...values.additional_items,
        deposit: values.additional_items.deposit
          ? {
              ...values.additional_items.deposit,
              date: "",
            }
          : undefined,
      },
    });
  };
  const dateGameHandler = (date: Date) => {
    if (date !== null) {
      setValues({
        ...values,
        additional_items: {
          ...values.additional_items,
          game: values.additional_items.game
            ? {
                ...values.additional_items.game,
                date: (date.valueOf() + 86400000).toString(),
              }
            : undefined,
        },
      });
      return;
    }
    setValues({
      ...values,
      additional_items: {
        ...values.additional_items,
        game: values.additional_items.game
          ? {
              ...values.additional_items.game,
              date: "",
            }
          : undefined,
      },
    });
  };
  const gameHandler = () => {
    setGameActivity(!gameActivity);
    setValues({
      ...values,
      additional_items: {
        ...values.additional_items,
        game: !gameActivity
          ? {
              type: "less",
              date: "",
            }
          : undefined,
      },
    });
  };
  const depositHandler = () => {
    setDepositActivity(!depositActivity);
    setValues({
      ...values,
      additional_items: {
        ...values.additional_items,
        deposit: !depositActivity
          ? {
              type: "less",
              date: "",
            }
          : undefined,
      },
    });
  };
  const emailHandler = () => {
    if (email) {
      setValues({ ...values, email: undefined });
    }
    setEmail(!email);
  };
  const depositTypetHandler = (type: "less" | "more") => {
    setValues({
      ...values,
      additional_items: {
        ...values.additional_items,
        deposit: values.additional_items.deposit
          ? {
              ...values.additional_items.deposit,
              type: type,
            }
          : undefined,
      },
    });
  };
  const gameTypetHandler = (type: "less" | "more") => {
    setValues({
      ...values,
      additional_items: {
        ...values.additional_items,
        game: values.additional_items.game
          ? {
              ...values.additional_items.game,
              type: type,
            }
          : undefined,
      },
    });
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <ActionFields
        values={values}
        setValues={setValues}
        openFSModal={openFSModal}
        openPersModal={openPersModal}
        openPreviewModal={openPreviewModal}
        cancelHandler={cancelHandler}
        submitHandler={submitHandler}
      />
      {values.type === "individual" && (
        <ActivityFields
          deposit={values.additional_items.deposit}
          game={values.additional_items.game}
          gameActivity={gameActivity}
          depositActivity={depositActivity}
          gameHandler={gameHandler}
          depositHandler={depositHandler}
          depositTypetHandler={depositTypetHandler}
          gameTypetHandler={gameTypetHandler}
          dateGameHandler={dateGameHandler}
          dateDepositHandler={dateDepositHandler}
        />
      )}
      <ImageUpload imagesHandler={imagesHandler} />
      <Box sx={{ maxWidth: "420px" }}>
        <InputCheckbox
          id={"email"}
          label={"Email"}
          checked={email}
          onChange={emailHandler}
        />
      </Box>
      {email && (
        <EmailContent
          imagesHandler={imagesHandler}
          setValues={setValues}
          initialValues={values}
        />
      )}
    </Box>
  );
};
