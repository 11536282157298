import { IUser } from "../types/user.type";

export function getBalances(user: IUser) {
  const copy = [...user.wallets].sort((a, b) => a.id - b.id);
  const balances: (string | { balance: string; active: boolean })[] = [];
  copy.forEach((wallet) => {
    if (wallet.active === true)
      balances.push(
        `${(+wallet.balance.main_balance).toFixed(2)} ${wallet.currency.name}`
      );
  });

  copy.forEach((wallet) => {
    balances.push({
      balance:
        (
          +wallet.balance.main_balance +
          +wallet.balance.bonus_balance +
          +wallet.balance.reserved_balance
        )
          .toFixed(2)
          .toString() +
        " " +
        wallet.currency.name,
      active: wallet.active,
    });
  });

  return balances;
}
