import React from "react";
import { IEmployee, IEmployeeState } from "../../types/employee.type";

const InitEmployee: IEmployeeState = {
  displayName: "",
  email: "",
  guid: "",
  id: "",
  name: "",
  picture: "",
  surname: "",
  role: null,
  auth: false,
};

export const useAuth = () => {
  const [employee, setEmployee] = React.useState(InitEmployee);
  const authorizate = (data: IEmployee) => {
    if (data.guid === null) {
      return;
    }
    setEmployee({
      ...data,
      displayName: `${data.name} ${data.surname}`,
      auth: true,
    });
  };
  const logout = () => {
    setEmployee(InitEmployee);
  };
  return {
    employee,
    authorizate,
    logout,
  };
};

export interface IAuthEmployeeContext {
  employee: IEmployeeState;
  authorizate: (data: IEmployee) => void;
  logout: () => void;
}

const defaultEmployeeContextState = {
  employee: InitEmployee,
  authorizate: (data: IEmployee) => {},
  logout: () => {},
} as IAuthEmployeeContext;

export const EmployeeContext = React.createContext(defaultEmployeeContextState);
