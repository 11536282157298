import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Paymentmodal } from "../modal";
import { COLORS } from "../../../consts";
interface IStatistics {
  totalTransactions: number;
  totalAmount: Record<string, number>;
  amountByCurrencyAndMethod: Record<
    string,
    { amount: number; currency: string }
  >;
  averageCommission: number;
  paymentsByStatusAndMethod: Record<
    string,
    { count: number; amount: number; currency: string }
  >;
}

export const Statistics = ({
  totalTransactions,
  totalAmount,
  amountByCurrencyAndMethod,
  averageCommission,
  paymentsByStatusAndMethod,
}: IStatistics) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<Record<string, any>>({});

  const handleOpenModal = (data: Record<string, any>) => {
    setModalData(data);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      {/* <Typography variant="h6">Statistics</Typography> */}
      <Typography
        sx={{
          cursore: "pointer",
          "&:hover": {
            backgroundColor: COLORS.SECONDARY_BG,
            cursor: "pointer",
          },
        }}
        onClick={() => handleOpenModal(totalAmount)}
      >
        Total Transactions: {totalTransactions}
      </Typography>
      {/* <Typography>Total Amount: {totalAmount.toFixed(2)}</Typography> */}

      {/* <Typography>Average Commission: {averageCommission.toFixed(2)}</Typography> */}

      <Typography
        sx={{
          cursore: "pointer",
          "&:hover": {
            backgroundColor: COLORS.SECONDARY_BG,
            cursor: "pointer",
          },
        }}
        onClick={() => handleOpenModal(amountByCurrencyAndMethod)}

        // style={{ marginTop: "16px" }}
      >
        Amount by Currency and Method
      </Typography>

      <Typography
        sx={{
          cursore: "pointer",
          "&:hover": {
            backgroundColor: COLORS.SECONDARY_BG,
            cursor: "pointer",
          },
        }}
        onClick={() => handleOpenModal(paymentsByStatusAndMethod)}
      >
        Payments by Status and Method
      </Typography>

      <Paymentmodal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        data={modalData}
      />
    </Box>
  );
};
