import React from "react";
import { EditButtonStyled } from "./editbutton.styled";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
interface IEditButtonProps {
  onClick: () => void;
}
export const EditButton = ({ onClick }: IEditButtonProps) => {
  return (
    <EditButtonStyled>
      <button type="button" onClick={onClick}>
        <ModeEditIcon />
      </button>
    </EditButtonStyled>
  );
};
