import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IPaymentMethod } from "../../../types/payment.type";
import { httpService } from "../../../services/httpService";

interface PaymentMethodsListProps {
  paymentMethods: IPaymentMethod[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

const PaymentMethodsList: React.FC<PaymentMethodsListProps> = ({
  paymentMethods,
  onEdit,
  onDelete,
}) => {
  const [localPaymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>(
    []
  );

  useEffect(() => {
    setPaymentMethods(paymentMethods);
  }, [paymentMethods]);

  const [editingId, setEditingId] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    currency: "",
    type: "",
    payment_type: "",
    min: 0,
    max: 0,
    // payment_system_name: "",
    // brand_name: "",
    active: false,
  });

  const handleEditClick = (method: any) => {
    setEditingId(method.id);
    setFormData({
      name: method.name,
      currency: method.currency,
      type: method.type,
      payment_type: method.payment_type,
      min: method.min,
      max: method.max,
      // payment_system_name: method.payment_system.name,
      // brand_name: method.payment_system.brand.brand_name,
      active: Boolean(method.active),
    });
  };

  const handleUpdate = async () => {
    try {
      debugger;
      const response = await httpService.put(
        {
          url: `/api/bo/payment/method/${editingId}`,
          data: formData,
        },
        true
      );
      console.log("Updated successfully:", response);

      setPaymentMethods((prevMethods) =>
        prevMethods.map((method) =>
          method.id === editingId ? { ...method, ...formData } : method
        )
      );

      setEditingId(null);
    } catch (error) {}
  };

  const handleDeactivate = async (id: number) => {
    try {
      debugger;
      const response = await httpService.delete(
        {
          url: `/api/bo/payment/method`,
          data: {
            method_id: id,
          },
        },
        true
      );
      console.log("Deactivated successfully:", response);
      setPaymentMethods((prevMethods) =>
        prevMethods.map((method) =>
          method.id === id
            ? { ...method, active: false } // Деактивуємо метод
            : method
        )
      );
      // Optionally update the local state or refetch data from the backend
      // setEditingId(null);
      debugger;
    } catch (error) {}
  };

  const handleInputChange = (e: any) => {
    const { name, type, checked, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Payment Type</TableCell>
            <TableCell>Min</TableCell>
            <TableCell>Max</TableCell>
            <TableCell>Payment System</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {localPaymentMethods
            .sort((a, b) => a.id - b.id)
            .map((method) => (
              <TableRow key={method.id}>
                <TableCell>
                  <img
                    src={method.image.src}
                    alt={method.name}
                    style={{
                      width: "100px",
                      height: "50px",
                      objectFit: "cover",
                    }}
                  />
                </TableCell>
                <TableCell>
                  {editingId === method.id ? (
                    <TextField
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  ) : (
                    method.name
                  )}
                </TableCell>
                <TableCell>
                  {editingId === method.id ? (
                    <TextField
                      name="currency"
                      value={formData.currency}
                      onChange={handleInputChange}
                    />
                  ) : (
                    method.currency
                  )}
                </TableCell>
                <TableCell>
                  {editingId === method.id ? (
                    <TextField
                      name="type"
                      value={formData.type}
                      onChange={handleInputChange}
                    />
                  ) : (
                    method.type
                  )}
                </TableCell>
                <TableCell>
                  {editingId === method.id ? (
                    <TextField
                      name="payment_type"
                      value={formData.payment_type}
                      onChange={handleInputChange}
                    />
                  ) : (
                    method.payment_type
                  )}
                </TableCell>
                <TableCell>
                  {editingId === method.id ? (
                    <TextField
                      name="min"
                      value={formData.min}
                      onChange={handleInputChange}
                    />
                  ) : (
                    method.min
                  )}
                </TableCell>
                <TableCell>
                  {editingId === method.id ? (
                    <TextField
                      name="max"
                      value={formData.max}
                      onChange={handleInputChange}
                    />
                  ) : (
                    method.max
                  )}
                </TableCell>
                <TableCell>{method.payment_system.name}</TableCell>
                <TableCell>{method.payment_system.brand.brand_name}</TableCell>
                <TableCell>
                  {editingId === method.id ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.active}
                          onChange={handleInputChange}
                          name="active"
                        />
                      }
                      label=""
                    />
                  ) : method.active ? (
                    "Yes"
                  ) : (
                    "No"
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: "125px" }}>
                  {editingId === method.id ? (
                    <>
                      <Button onClick={handleUpdate} color="primary">
                        Save
                      </Button>
                      <Button
                        onClick={() => setEditingId(null)}
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={() => handleEditClick(method)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeactivate(method.id)}
                        color="secondary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentMethodsList;
