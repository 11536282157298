import React from "react";
import { Portal } from "../portal/portal";
import { Box, Typography } from "@mui/material";
import { BORDERRADIUS, COLORS } from "../../consts";
import { MyButton } from "../button/button";

type ISimpleModalProps = {
  open: boolean;
  close: () => void;
  confirm: () => void;
  heading: string;
  children: React.ReactNode;
  confirmButton?: string;
  closeButton?: string;
  maxWidth?: string;
};

export const ModalTemplate = ({
  open,
  close,
  confirm,
  heading,
  children,
  confirmButton,
  closeButton,
  maxWidth,
}: ISimpleModalProps) => {
  return (
    <>
      {open && (
        <Portal>
          <Box
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100vw",
              height: "100vh",
              backgroundColor: COLORS.SEMITRANPARENT,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
            onClick={close}
          >
            <Box
              sx={{
                padding: "20px 30px",
                backgroundColor: COLORS.PRIMARY_BG,
                borderRadius: BORDERRADIUS.PRIMARY,
                display: "flex",
                flexDirection: "column",
                maxWidth: maxWidth ? maxWidth : "500px",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 900,
                  color: COLORS.DARKGREY,
                  marginBottom: "10px",
                }}
              >
                {heading}
              </Typography>
              {children}
              <Box
                sx={{
                  widht: "100%",
                  height: "2px",
                  borderRadius: "1px",
                  backgroundColor: COLORS.SECONDARY_BG,
                  marginBottom: "10px",
                }}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <MyButton
                  cancel
                  text={closeButton || "close"}
                  onClick={close}
                />
                <MyButton
                  text={confirmButton || "confirm"}
                  onClick={() => {
                    confirm();
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Portal>
      )}
    </>
  );
};
