import React, { useContext, useEffect, useState } from "react";
import { PaymentContainer } from "../../../components/paymentContainer/payment.container";
import { useGetPayments } from "../../../hooks/payments/useGetPayments";
import { AllPaymentsList } from "../../../modules/payments/all-payments";
import {
  InitialValues,
  PaymentFilter,
} from "../../../modules/payments/all-payments/filter";
import { IPayment } from "../../../types/payment.type";
import { queryCreator } from "../../../utils/queryToObj";
import { useNavigate } from "react-router-dom";
import { EmployeeContext } from "../../../hooks/auth/useAuth";
import { AlertContext } from "../../../hooks/notification/useAlert";

export const AllPayments = () => {
  const navigator = useNavigate();
  const { logout } = useContext(EmployeeContext);
  const { alertHandler } = useContext(AlertContext);
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState("");
  const [order, setOrder] = useState<{ [key: string]: "ASC" | "DESC" }>({
    payment_date: "DESC",
  });
  const [filters, setFilters] = useState<InitialValues>({
    from: "",
    to: "",
    user: "",
    id: null,
    status: undefined,
    type: undefined,
    currency: "RUB",
  });
  const [payments, setPayments] = useState<IPayment[]>([]);
  const { data, error } = useGetPayments(query);
  const orderHandler = (key: string) => {
    if (order[key] === "DESC") {
      setOrder({ [key]: "ASC" });
      return;
    }
    setOrder({ [key]: "DESC" });
  };
  useEffect(() => {
    if (data) {
      setPayments((prev) => [...prev, ...data]);
    }
  }, [data]);

  useEffect(() => {
    if (payments.length === 0) {
      if (data) {
        setPayments(data);
      }
    }
  }, [payments]);
  useEffect(() => {
    const queryString = queryCreator({
      ...order,
      ...filters,
      page: 1,
    });
    setPage(1);
    setPayments([]);
    setQuery(queryString);
  }, [order, filters]);
  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
      if (error.message === "Unathorized") {
        logout();
        navigator("/");
      }
    }
  }, [error]);
  return (
    <PaymentContainer>
      <AllPaymentsList
        data={payments}
        showMore={() => {
          setPage((prev) => prev + 1);
        }}
        orderHandler={orderHandler}
      />
      <PaymentFilter
        setPage={setPage}
        setFilter={setFilters}
        setPayments={setPayments}
      />
    </PaymentContainer>
  );
};
