import React, { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useGetPlayerPayments } from "../../../hooks/player/playerPayments/useGetPlayerPayments";
import { PaymentFilter } from "../../../modules/player/payments/paymentFilter/paymentFilter";
import { Box } from "@mui/material";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { PaymentCard } from "../../../modules/player/payments/paymentCard/paymentCard";
import { queryCreator } from "../../../utils/queryToObj";
import { AlertContext } from "../../../hooks/notification/useAlert";
import { IUser } from "../../../types/user.type";
import { EmployeeContext } from "../../../hooks/auth/useAuth";

interface InitialValues {
  [key: string]: string | number | null | undefined;
  id: string;
  from: null | string;
  to: null | string;
  status?:
    | "pending"
    | "success"
    | "error"
    | "failed"
    | "rejected"
    | "approved"
    | "hold";
  type?: "refill" | "withdrawals";
  currencyID?: number;
}

interface IQuery extends InitialValues {
  playerId: string;
  page: number;
}

export const PlayerPayments = () => {
  const [user]: IUser[] = useOutletContext();
  const { alertHandler } = useContext(AlertContext);
  const location = useLocation();
  const navigator = useNavigate();
  const { logout } = useContext(EmployeeContext);
  const id = location.pathname.split("/")[2];
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [page, setPage] = useState(1);
  const [obj, setObj] = useState<IQuery>({
    playerId: id,
    page,
    id: "",
    from: "",
    to: "",
    status: undefined,
    type: undefined,
    currencyID: undefined,
  });
  const [query, setQuery] = useState(queryCreator<IQuery>(obj));
  const { data, isFetching, error } = useGetPlayerPayments(id, query);
  const [payments, setPayments] = useState<any[]>(data ? data.payments : []);
  const [order, setOrder] = useState<{ [key: string]: "ASC" | "DESC" }>({
    payment_date: "DESC",
  });
  const orderHandler = (key: string) => {
    if (order[key] === "DESC") {
      setOrder({ [key]: "ASC" });
      return;
    }
    setOrder({ [key]: "DESC" });
  };
  useEffect(() => {
    if (data && data.payments && !isFetching) {
      setPayments((prev: any) => {
        const arr = [...prev, ...data.payments];
        return arr.reduce((o, i) => {
          if (!o.find((v: any) => v.id === i.id)) {
            o.push(i);
          }
          return o;
        }, []);
      });
    }
  }, [isFetching]);

  useEffect(() => {
    setQuery(queryCreator<IQuery>({ ...obj, ...order }));
    setPayments([]);
  }, [obj, order]);

  useEffect(() => {
    if (inView) {
      if (data && data.count % 50 && Math.trunc(data.count / 50) === 0) {
        return;
      } else if (
        data &&
        data.count % 50 &&
        page <= Math.trunc(data.count / 50)
      ) {
        setPage((prev) => prev + 1);
      } else if (
        data &&
        data.count % 50 === 0 &&
        page < Math.trunc(data.count / 50)
      ) {
        setPage((prev) => prev + 1);
      }
    }
  }, [inView]);

  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
      if (error.message === "Unathorized") {
        logout();
        navigator("/");
      }
    }
  }, [error]);

  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 70px)", width: "100%" }}>
      <Box
        sx={{
          flex: "1 1 auto",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          padding: "10px 16px",
          margin: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: COLORS.PRIMARY,
            padding: "5px 16px",
            borderRadius: BORDERRADIUS.PRIMARY,
            fontSize: "13px",
            marginBottom: "10px",
            textAlign: "center",
            color: "black",
            gap: "10px",
          }}
        >
          <Box
            sx={{ width: "65px", cursor: "pointer" }}
            onClick={() => {
              orderHandler("payment_date");
            }}
          >
            Date
          </Box>
          <Box sx={{ width: "170px" }}>Credentials</Box>
          <Box sx={{ width: "220px" }}>Transaction ID</Box>
          <Box sx={{ width: "110px" }}>Method</Box>
          <Box
            sx={{ width: "110px", cursor: "pointer" }}
            onClick={() => {
              orderHandler("amount");
            }}
          >
            Amount
          </Box>
          <Box sx={{ width: "70px" }}>Currency</Box>
          <Box sx={{ width: "100px" }}>Type</Box>
          <Box sx={{ width: "100px" }}>Status</Box>
          <Box sx={{ width: "65px", marginRight: "20px" }}>Updated</Box>
        </Box>
        <Box
          sx={{
            height: "calc(100% - 40px)",
            overflowY: "auto",
          }}
        >
          {payments.map((payment: any, index: number) => {
            if (index === payments.length - 4)
              return (
                <div ref={ref} key={payment.id}>
                  <PaymentCard item={payment} />
                </div>
              );
            return <PaymentCard item={payment} key={payment.id} />;
          })}
        </Box>
      </Box>
      <PaymentFilter
        playerId={id}
        page={page}
        setObj={setObj}
        setPage={setPage}
        setPayments={setPayments}
        currencies={user.wallets.map((w) => w.currency)}
      />
    </Box>
  );
};
