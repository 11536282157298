import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { lobbyService } from "../../services/lobby.service";

export const useChangeCounter = (
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (data: { id: number; counter: number }) =>
      await lobbyService.changeCounter(data.id, data.counter),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["mySlots"] });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
