import React, { useContext, useState } from "react";
import { ModalTemplate } from "../../../components/modal/modal.template";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import { InputB } from "../../../components/input-b/inputB";
import { useCreateBrand } from "../../../hooks/brand/useCreateBrand";
import { AlertContext } from "../../../hooks/notification/useAlert";
import { MyButton } from "../../../components/button/button";
import { COLORS } from "../../../consts";
import DeleteIcon from "@mui/icons-material/Delete";

type IBrandModalProps = {
  createModal: boolean;
  setCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const InitialValues: {
  brand_name: string;
  hostname: string;
  mirror_hostname?: string;
} = {
  brand_name: "",
  hostname: "",
  mirror_hostname: undefined,
};

export const BrandModal = ({
  createModal,
  setCreateModal,
}: IBrandModalProps) => {
  const formik = useFormik({
    initialValues: InitialValues,
    onSubmit: (value) => {
      createBrand({
        name: value.brand_name,
        host: value.hostname,
        currencies: currencies,
        mirror_hostname: value.mirror_hostname
          ? value.mirror_hostname
          : undefined,
      });
    },
  });
  const { alertHandler } = useContext(AlertContext);
  const { mutate: createBrand } = useCreateBrand(alertHandler, () => {
    setCreateModal(false);
  });
  const [currencies, setCurrencies] = useState<
    { name: string; default: boolean }[]
  >([{ name: "", default: true }]);
  const currenciesHandler = (index: number, value: string) => {
    const newCurrencies = currencies.map((currency, ind) => {
      if (ind === index) {
        return { name: value, default: currency.default };
      }
      return currency;
    });
    setCurrencies(newCurrencies);
  };
  const addCurrenciesHandler = () => {
    setCurrencies([...currencies, { name: "", default: false }]);
  };
  const deleteCurrenciesHandler = (index: number) => {
    setCurrencies(currencies.filter((c, i) => i !== index));
  };
  return (
    <ModalTemplate
      open={createModal}
      close={() => {
        setCreateModal(false);
      }}
      confirm={formik.handleSubmit}
      heading={"Add new brand"}
    >
      <Box
        sx={{
          maxWidth: "350px",
          width: "350px",
          maxHeight: "70vh",
          overflowY: "auto",
        }}
      >
        <InputB
          id={"brand_name"}
          onChange={formik.handleChange}
          value={formik.values.brand_name}
          label={"Brand name"}
        />
        <InputB
          id={"hostname"}
          onChange={formik.handleChange}
          value={formik.values.hostname}
          label={"Brand hostname"}
        />
        <InputB
          id={"mirror_hostname"}
          onChange={formik.handleChange}
          value={
            formik.values.mirror_hostname ? formik.values.mirror_hostname : ""
          }
          label={"Mirror hostname"}
        />
        {currencies.map((currency, index) => {
          if (index === 0) {
            return (
              <InputB
                key={"input_currency_" + index}
                id={`currency_${index}`}
                onChange={(e: any) => {
                  currenciesHandler(index, e.target.value);
                }}
                value={currency.name}
                label={"Default brand currency"}
              />
            );
          }
          return (
            <Box
              sx={{ display: "flex", alignItems: "flex-end" }}
              key={"input_currency_" + index}
            >
              <InputB
                id={`currency_${index}`}
                onChange={(e: any) => {
                  currenciesHandler(index, e.target.value);
                }}
                value={currency.name}
                label={"Additional currency"}
              />
              <DeleteIcon
                sx={{ margin: "0 0 16px 10px" }}
                onClick={() => {
                  deleteCurrenciesHandler(index);
                }}
              />
            </Box>
          );
        })}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "15px",
          }}
        >
          <MyButton
            text="Add currency"
            onClick={addCurrenciesHandler}
            sx={{
              backgroundColor: COLORS.BLUE,
              "&:hover": { backgroundColor: COLORS.BLUE + "de" },
            }}
          />
        </Box>
      </Box>
    </ModalTemplate>
  );
};
