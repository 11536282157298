import { Box } from "@mui/material";
import React from "react";
import { EditorComponent } from "../../editor/editor";
import { InputB } from "../../../../../components/input-b/inputB";
import { FormikErrors, useFormik } from "formik";
import { InputImage } from "../../../../../components/inputImage/inputImage";
import { IInitialValues } from "../../typing/initialValues";

type IEmailContentProps = {
  initialValues: IInitialValues;
  imagesHandler: (
    key: "bg_image" | "sub_image" | "email_image",
    file: File | null
  ) => void;
  setValues: (
    values: React.SetStateAction<IInitialValues>,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IInitialValues>>;
};

export const EmailContent = ({
  initialValues,
  imagesHandler,
  setValues,
}: IEmailContentProps) => {
  const formik = useFormik({
    initialValues: {
      emailTitle: "",
      emailDescription: "",
      emailFooter: "",
      emailSubject: "",
    },
    onSubmit: (values) => {
      setValues({
        ...initialValues,
        email: {
          title: values.emailTitle,
          description: values.emailDescription,
          footer: values.emailFooter,
          subject: values.emailSubject,
        },
      });
    },
  });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ maxWidth: "420px", marginBottom: "10px" }}>
        <InputImage
          id={"email_image"}
          label={"Upload email image"}
          upload={(value) => {
            imagesHandler("email_image", value ? value[0] : null);
          }}
        />
      </Box>
      <InputB
        id={"emailSubject"}
        onChange={formik.handleChange}
        value={formik.values.emailSubject}
        label={"Email Subject"}
      />
      <InputB
        id={"emailTitle"}
        onChange={formik.handleChange}
        value={formik.values.emailTitle}
        label={"Email Title"}
      />
      <EditorComponent
        onSave={(value: {
          emailTitle: string;
          emailDescription: string;
          emailFooter: string;
          emailSubject: string;
        }) => {
          formik.setValues(value);
          formik.handleSubmit();
        }}
        values={formik.values}
      />
    </Box>
  );
};
