import { AxiosError, AxiosResponse } from "axios";
import { HttpService, httpService } from "./httpService";
import { IFreespins, IFreespinsExample } from "../types/freespins.type";
import { IBonusExample } from "../types/bonus.type";
import { AlertError } from "../errors/AlertError";
import { IPercentage, IPercentageExample } from "../types/perscentage.type";
import { IMessage } from "../types/message.type";

class BonusService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  async createFS(value: IFreespinsExample) {
    const response = await this.httpService.post<AxiosResponse<IFreespins>>(
      {
        url: "/api/bo/bonuses/freespins",
        data: value,
      },
      true
    );
    return response.data;
  }
  async createPR(value: IPercentageExample) {
    const response = await this.httpService.post<AxiosResponse<IPercentage>>(
      {
        url: "/api/bo/bonuses/percentage",
        data: value,
      },
      true
    );
    return response.data;
  }
  async createBonus(value: FormData) {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL_BO || ""}/api/bo/bonus`,
      {
        method: "POST",
        body: value,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_TOKEN_BO
          )}`,
        },
      }
    );
    const data = await response.json();
    return data;
  }

  async getFreespins() {
    try {
      const response = await this.httpService.get<AxiosResponse<IFreespins[]>>(
        {
          url: "/api/bo/bonuses/freespins",
        },
        true
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async getPercentage() {
    try {
      const response = await this.httpService.get<AxiosResponse<IPercentage[]>>(
        {
          url: "/api/bo/bonuses/percentage",
        },
        true
      );
      return response.data;
    } catch (error) {
      // console.log(error);
      return [];
    }
  }

  async getBonuses(id?: number) {
    try {
      const response = await this.httpService.get<
        AxiosResponse<IBonusExample[]>
      >(
        {
          url: `/api/bo/bonuses?${id ? "id=" + id : ""}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async edit(value: FormData) {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL_BO || ""}/api/bo/bonuses/edit`,
      {
        method: "POST",
        body: value,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_TOKEN_BO
          )}`,
        },
      }
    );
    const data = await response.json();
    return data;
  }

  async dublicate(bonus: IBonusExample) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/bonuses/dublicate`,
          data: { bonus },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
}

export const bonusService = new BonusService(httpService);
