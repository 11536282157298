import React from "react";
import ReactDOM from "react-dom";

interface IPortalProps {
  children: React.ReactNode;
}

const portal = document.getElementById("portal");

export const Portal: React.FC<IPortalProps> = ({ children }) => {
  if (portal) {
    return ReactDOM.createPortal(children, portal);
  }
  return null;
};
