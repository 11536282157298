import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { CasinoHistoryItem } from "../casinoHistoryItem/casinoHistoryItem";
import { ICasinoRound } from "../../../types/casino.type";
import { Loader } from "../../../components/loader/loader";

type ICasinoHistoryProps = {
  page: number;
  history: ICasinoRound[];
  count: number;
  isFetching: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  orderHandler: (key: string) => void;
};

export const CasinoHistory = ({
  page,
  history,
  count,
  isFetching,
  setPage,
  orderHandler,
}: ICasinoHistoryProps) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  useEffect(() => {
    if (inView) {
      if (history && count % 100 && Math.trunc(count / 100) === 0) {
        return;
      } else if (count % 100 && page <= Math.trunc(count / 100)) {
        setPage((prev) => prev + 1);
      } else if (count % 100 === 0 && page < Math.trunc(count / 100)) {
        setPage((prev) => prev + 1);
      }
    }
  }, [inView]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: BORDERRADIUS.PRIMARY,
        backgroundColor: COLORS.PRIMARY_BG,
        padding: isFetching ? "10px 16px 0" : "10px 16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          backgroundColor: COLORS.GREY,
          width: "100%",
          padding: "6px 0",
          fontSize: "13px",
          color: COLORS.DARKGREY,
          borderRadius: BORDERRADIUS.PRIMARY,
          textAlign: "center",
        }}
      >
        <Box sx={{ width: "100px" }}>ID</Box>
        <Box
          sx={{ width: "70px", cursor: "pointer" }}
          onClick={() => {
            orderHandler("start_date");
          }}
        >
          Start date
        </Box>
        <Box sx={{ width: "260px" }}>Round ID</Box>
        <Box sx={{ width: "150px" }}>Game</Box>
        <Box sx={{ width: "100px" }}>Status</Box>
        <Box
          sx={{ width: "100px", cursor: "pointer" }}
          onClick={() => {
            orderHandler("amount");
          }}
        >
          Amount
        </Box>
        <Box
          sx={{ width: "100px", cursor: "pointer" }}
          onClick={() => {
            orderHandler("result");
          }}
        >
          Result
        </Box>
        <Box
          sx={{ width: "100px", cursor: "pointer" }}
          onClick={() => {
            orderHandler("profit");
          }}
        >
          Profit
        </Box>
        <Box sx={{ width: "60px" }}>Currency</Box>
        <Box sx={{ width: "70px", marginRight: "20px" }}>End date</Box>
      </Box>
      <Box
        sx={{
          height: isFetching ? "calc(100% - 80px)" : "calc(100% - 40px)",
          overflow: "auto",
        }}
      >
        {history.map((round_item) => {
          if (history.length - 4) {
            return (
              <div ref={ref} key={round_item.external_id}>
                <CasinoHistoryItem
                  historyItem={round_item}
                  key={round_item.id}
                />
              </div>
            );
          }
          return (
            <CasinoHistoryItem
              historyItem={round_item}
              key={round_item.external_id}
            />
          );
        })}
      </Box>
      {isFetching && (
        <Box
          sx={{
            position: "relative",
            height: "40px",
            widht: "100%",
            overflow: "hidden",
          }}
        >
          <Loader transparent />
        </Box>
      )}
    </Box>
  );
};
