import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { useGetPlayerFinances } from "../../../hooks/player/playerFinance/useGetFinances";
import { useLocation, useNavigate } from "react-router-dom";
import { FinanceCard } from "../../../modules/player/finance/financeCard/financeCard";
import { useInView } from "react-intersection-observer";
import { FinanceFilter } from "../../../modules/player/finance/financeFilter/financeFilter";
import { queryCreator } from "../../../utils/queryToObj";
import { AlertContext } from "../../../hooks/notification/useAlert";
import { EmployeeContext } from "../../../hooks/auth/useAuth";

interface InitialValues {
  [key: string]: string | number | null | undefined;
  transaction_id: string;
  from: null | string;
  to: null | string;
  type?:
    | "debit"
    | "credit"
    | "deposit"
    | "withdrawal"
    | "bonus_accrual"
    | "bonus_release"
    | "bonus_close"
    | "writeoff"
    | "manual_deposit";
}

interface IQuery extends InitialValues {
  page: number;
}

export const FinancePage = () => {
  const { alertHandler } = useContext(AlertContext);
  const location = useLocation();
  const navigator = useNavigate();
  const { logout } = useContext(EmployeeContext);
  const playerId = location.pathname.split("/")[2];
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<{ [key: string]: "ASC" | "DESC" }>({
    date: "DESC",
  });
  const orderHandler = (key: string) => {
    if (order[key] === "DESC") {
      setOrder({ [key]: "ASC" });
      return;
    }
    setOrder({ [key]: "DESC" });
  };
  const [obj, setObj] = useState<IQuery>({
    playerId,
    page,
    transaction_id: "",
    type: undefined,
    from: "",
    to: "",
  });
  const [query, setQuery] = useState(queryCreator<IQuery>(obj));
  const { data, isFetching, error } = useGetPlayerFinances(query);
  const [finances, setFinances] = useState<any[]>(data ? data.finances : []);
  useEffect(() => {
    if (data && data.finances && !isFetching) {
      setFinances((prev: any) => {
        const arr = [...prev, ...data.finances];
        return arr.reduce((o, i) => {
          if (!o.find((v: any) => v.id === i.id)) {
            o.push(i);
          }
          return o;
        }, []);
      });
    }
  }, [isFetching]);
  useEffect(() => {
    setQuery(queryCreator<IQuery>({ ...obj, ...order }));
  }, [obj, order]);

  useEffect(() => {
    if (inView) {
      if (data && data.count % 100 && Math.trunc(data.count / 100) === 0) {
        return;
      } else if (
        data &&
        data.count % 100 &&
        page <= Math.trunc(data.count / 100)
      ) {
        setPage((prev) => prev + 1);
      } else if (
        data &&
        data.count % 100 === 0 &&
        page < Math.trunc(data.count / 100)
      ) {
        setPage((prev) => prev + 1);
      }
    }
  }, [inView]);

  useEffect(() => {
    if (error) {
      //@ts-ignore
      alertHandler(error.message, error.code);
      if (error.message === "Unathorized") {
        logout();
        navigator("/");
      }
    }
  }, [error]);
  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 70px)", width: "100%" }}>
      <Box
        sx={{
          flex: "1 1 auto",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          padding: "10px 16px",
          margin: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: COLORS.PRIMARY,
            padding: "5px 0",
            borderRadius: BORDERRADIUS.PRIMARY,
            fontSize: "13px",
            marginBottom: "10px",
            textAlign: "center",
            color: "black",
            gap: "10px",
          }}
        >
          <Box
            sx={{ width: "100px", cursor: "pointer" }}
            onClick={() => {
              orderHandler("id");
            }}
          >
            ID
          </Box>
          <Box
            sx={{ width: "70px", cursor: "pointer" }}
            onClick={() => {
              orderHandler("date");
            }}
          >
            Date
          </Box>
          <Box sx={{ width: "250px" }}>Transaction ID</Box>
          <Box
            sx={{ width: "100px", cursor: "pointer" }}
            onClick={() => {
              orderHandler("amount");
            }}
          >
            Amount
          </Box>
          <Box sx={{ width: "100px" }}>Type</Box>
          <Box sx={{ width: "100px" }}>Balance Before</Box>
          <Box sx={{ width: "100px", marginRight: "20px" }}>Balance After</Box>
        </Box>
        <Box
          sx={{
            height: "calc(100% - 40px)",
            overflowY: "auto",
          }}
        >
          {finances.map((finance: any, index: number) => {
            if (index === finances.length - 4) {
              return (
                <div key={finance.id} ref={ref}>
                  <FinanceCard
                    finance={finance}
                    first={index === 0 || index === 1 ? true : false}
                  />
                </div>
              );
            }
            return (
              <FinanceCard
                key={finance.id}
                finance={finance}
                first={index === 0 || index === 1 ? true : false}
              />
            );
          })}
        </Box>
      </Box>
      <FinanceFilter
        playerId={playerId}
        page={page}
        setObj={setObj}
        setPage={setPage}
        setFinances={setFinances}
      />
    </Box>
  );
};
