import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { BORDERRADIUS, COLORS } from "../../../consts";
interface ISearchItemProps {
  id: string;
  full_name: string;
  email: string;
  balance: string;
  verification: string;
  date: string;
  inView?: (node?: Element | null | undefined) => void;
  lastElement?: boolean;
}
export const SearchItem = ({
  id,
  full_name,
  email,
  balance,
  verification,
  date,
  inView,
}: ISearchItemProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 16px",
        color: COLORS.DARKGREY,
        marginBottom: "5px",
        backgroundColor: COLORS.PRIMARY_BG,
        borderRadius: BORDERRADIUS.PRIMARY,
      }}
      ref={inView}
    >
      <Link to={`/player/${id}/info`} target="_blank">
        <Typography variant="subtitle2">{id}</Typography>
      </Link>
      <Typography variant="subtitle2" sx={{ minWidth: "150px" }}>
        {full_name}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          width: "140px",
          color: "#f8f8f8",
          backgroundColor: COLORS.GREEN,
          textAlign: "center",
          padding: "0 10px",
        }}
      >
        Отмониторенный
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          width: "150px",
          fontWeight: 400,
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {email}
      </Typography>
      <Typography variant="subtitle2" sx={{ minWidth: "120px" }}>
        {balance}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ minWidth: "120px", fontWeight: 400 }}
      >
        {verification}
      </Typography>
      <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
        {date}
      </Typography>
    </Box>
  );
};
