import React from "react";
import { Box, Typography } from "@mui/material";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { InputB } from "../../../components/input-b/inputB";
import { MyButton } from "../../../components/button/button";
import { useFormik } from "formik";
import { Loader } from "../../../components/loader/loader";

type IRoleModalProps = {
  isLoading: boolean;
  closeModal: () => void;
  submitHanler: (name: string) => void;
};

export const RoleModal = ({
  isLoading,
  closeModal,
  submitHanler,
}: IRoleModalProps) => {
  const formik = useFormik({
    initialValues: {
      role: "",
    },
    onSubmit: (values) => {
      submitHanler(values.role);
    },
  });
  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: COLORS.SEMITRANPARENT,
      }}
      onClick={closeModal}
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: COLORS.PRIMARY_BG,
          borderRadius: BORDERRADIUS.PRIMARY,
          padding: "10px 35px",
          width: "450px",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Typography
          variant="h4"
          sx={{ marginBottom: "15px", color: COLORS.DARKGREY }}
        >
          Create new role
        </Typography>
        <InputB
          id="role"
          onChange={formik.handleChange}
          value={formik.values.role}
          label="Name"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 0",
          }}
        >
          <MyButton
            type="button"
            text="Create Role"
            disabled={false}
            onClick={formik.handleSubmit}
          />
        </Box>
        {isLoading && <Loader />}
      </Box>
    </Box>
  );
};
