import styled from "styled-components";

export const PaymentContainerStyled = styled.div`
  height: calc(100vh - 36px);
  display: grid;
  gap: 10px;
  grid-template-columns: calc(100vw - 400px - 30px) 400px;
  grid-template-rows: repeat(4, 1fr);
  align-items: start;
  grid-template-areas:
    "payments queue"
    "payments queue"
    "payments queue"
    "payments queue";
  padding: 10px;
  & > div:nth-child(1) {
    width: 100%;
    height: 100%;
    grid-area: payments;
  }
  & > div:nth-child(2) {
    height: auto;
    grid-area: queue;
  }
`;
