import { useMutation } from "@tanstack/react-query";
import { userService } from "../../../services/userService";
import { AlertError } from "../../../errors/AlertError";

export const useActivate = (
  id: string,
  openHandler: (message: string, error?: string) => void
) => {
  return useMutation({
    mutationFn: () => userService.activate(id),
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
