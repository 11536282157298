import * as React from "react";
import { Outlet, createBrowserRouter } from "react-router-dom";
import { Info } from "../pages/player/info/info";
import { Search } from "../pages/search/search";
import { Payments } from "../pages/payments";
import { PlayerLayout } from "../layouts/player";
import { AllPayments } from "../pages/payments/all";
import { PaymentQueue } from "../pages/payments/queue";
import { Login } from "../pages/login/login";
import { Access } from "../pages/access/access";
import { Authorization } from "../pages/authorization/authorization";
import { Root, RootPage } from "../pages/root/root";
import { RoleId } from "../pages/access/roleId/roleId";
import { Bonuses } from "../pages/bonuses/bonuses";
import { BonusesCreate } from "../pages/bonuses/create/bonuses.create";
import { FinancePage } from "../pages/player/finance/finance";
import { PlayerPayments } from "../pages/player/payments/payments";
import { PlayerBonusesPage } from "../pages/player/bonuses/bonuses";
import { PlayerCasinoHistoryPage } from "../pages/player/casino/casino";
import { KYCPage } from "../pages/player/kyc/kyc";
import { BonusesEdit } from "../pages/bonuses/edit/bonuses.edit";
import { Lobby } from "../pages/lobby/lobby";
import { Brand } from "../pages/brand/brand";
import { Statistics } from "../pages/statistics/statistics";
import { PaymentMethods } from "../pages/payments/methods";
import { Game } from "../pages/casino/game";
export const routers = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <RootPage />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/access",
        element: <Access />,
      },
      {
        path: "/casino",
        element: <Game />,
      },
      {
        path: "/lobby",
        element: <Lobby />,
      },
      {
        path: "/access/:roleId",
        element: <RoleId />,
      },
      {
        path: "/bonuses",
        element: <Bonuses />,
      },
      {
        path: "/bonuses/create",
        element: <BonusesCreate />,
      },
      {
        path: "/bonuses/edit/:id",
        element: <BonusesEdit />,
      },
      {
        path: "/payments",
        element: <Payments />,
        children: [
          {
            index: true,
            element: <AllPayments />,
          },
          {
            path: "/payments/queue",
            element: <PaymentQueue />,
          },
          {
            path: "/payments/methods",
            element: <PaymentMethods />,
          },
        ],
      },
      {
        path: "/player",
        element: <Outlet />,
        children: [
          {
            path: "/player/:id",
            element: <PlayerLayout />,
            children: [
              {
                path: "/player/:id/info",
                element: <Info />,
              },
              {
                path: "/player/:id/kyc",
                element: <KYCPage />,
              },
              {
                path: "/player/:id/financial",
                element: <FinancePage />,
              },
              {
                path: "/player/:id/payments",
                element: <PlayerPayments />,
              },
              {
                path: "/player/:id/bonuses",
                element: <PlayerBonusesPage />,
              },
              {
                path: "/player/:id/casino",
                element: <PlayerCasinoHistoryPage />,
              },
            ],
          },
        ],
      },
      {
        path: "/brand",
        element: <Brand />,
      },
      {
        path: "/statistics",
        element: <Statistics />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/authorization",
    element: <Authorization />,
  },
]);
