import axios from "axios";
import { IConfig } from "../types/httpService.type";

export class HttpService {
  baseUrl;
  fetchService;
  constructor(baseUrl: string, fetchService: any) {
    this.baseUrl = baseUrl;
    this.fetchService = fetchService;
  }
  private getURL(url: string) {
    return this.baseUrl + url;
  }
  private setAuthorized() {
    return {
      Authorization:
        "Bearer " + localStorage.getItem(process.env.REACT_APP_TOKEN_BO),
    };
  }
  private getConfigs(config: IConfig) {
    const { url, data, ...otherConfigs } = config;
    return otherConfigs;
  }

  get<T>(configs: IConfig, Auth = false): Promise<T> {
    if (Auth) {
      configs.headers = {
        ...configs.headers,
        ...this.setAuthorized(),
      };
    }
    return this.fetchService.get(
      this.getURL(configs.url),
      this.getConfigs(configs)
    );
  }
  post<T>(configs: IConfig, Auth = false): Promise<T> {
    if (Auth) {
      configs.headers = {
        ...configs.headers,
        ...this.setAuthorized(),
      };
    }
    return this.fetchService.post(
      this.getURL(configs.url),
      { ...configs.data },
      this.getConfigs(configs)
    );
  }
  put<T>(configs: IConfig, Auth = false): Promise<T> {
    if (Auth) {
      configs.headers = {
        ...configs.headers,
        ...this.setAuthorized(),
      };
    }
    return this.fetchService.put(
      this.getURL(configs.url),
      { ...configs.data },
      this.getConfigs(configs)
    );
  }
  delete<T>(configs: IConfig, Auth = false): Promise<T> {
    if (Auth) {
      configs.headers = {
        ...configs.headers,
        ...this.setAuthorized(),
      };
    }
    return this.fetchService.delete(this.getURL(configs.url), {
      data: { ...configs.data },
      ...this.getConfigs(configs),
    });
  }
}
axios.defaults.withCredentials = true;
const AxiosService = axios.create();
AxiosService.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL_BO || ""}/api/bo/auth/refresh`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status === 403) {
        return Promise.reject(error);
      }
      const data = await response.json();
      localStorage.setItem(process.env.REACT_APP_TOKEN_BO, data.accessToken);
      originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
      return AxiosService.request(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const httpService = new HttpService(
  process.env.REACT_APP_SERVER_URL_BO,
  AxiosService
);
