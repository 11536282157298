import React from "react";
import { Box } from "@mui/material";
import { NavLink, Outlet } from "react-router-dom";
import { AppMenu } from "../../components/menu/menu";
import { COLORS } from "../../consts";

export const PaymentLayout = () => {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <AppMenu />
        <NavLink to="/payments">Payments</NavLink>
        <NavLink to="/payments/queue">Queue</NavLink>
        <NavLink to="/payments/methods">Payment Systems and Methods</NavLink>
      </Box>
      <Outlet />
    </Box>
  );
};
