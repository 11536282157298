import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IPaymentSystem } from "../../../types/payment.type";
import { httpService } from "../../../services/httpService";

interface IPaymentSystemListProps {
  paymentSystems: IPaymentSystem[];
}

const PaymentSystemList: React.FC<IPaymentSystemListProps> = ({
  paymentSystems,
}) => {
  const [localPaymentSystems, setPaymentSystems] = useState<IPaymentSystem[]>(
    []
  );

  useEffect(() => {
    setPaymentSystems(paymentSystems);
  }, [paymentSystems]);

  const [editingId, setEditingId] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    active: false,
  });

  const handleEditClick = (method: any) => {
    setEditingId(method.id);
    setFormData({
      name: method.name,
      active: Boolean(method.active),
    });
  };

  const handleUpdate = async () => {
    try {
      // debugger;
      const response = await httpService.put(
        {
          url: `/api/bo/payment/system/${editingId}`,
          data: formData,
        },
        true
      );
      console.log("Updated successfully:", response);

      setPaymentSystems((prevMethods) =>
        prevMethods.map((method) =>
          method.id === editingId ? { ...method, ...formData } : method
        )
      );

      setEditingId(null);
    } catch (error) {}
  };

  const handleInputChange = (e: any) => {
    const { name, type, checked, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {localPaymentSystems
            .sort((a, b) => a.id - b.id)
            .map((system) => (
              <TableRow key={system.id}>
                <TableCell>
                  {editingId === system.id ? (
                    <TextField
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  ) : (
                    system.name
                  )}
                </TableCell>
                <TableCell>{system.brand.brand_name}</TableCell>
                <TableCell>
                  {editingId === system.id ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.active}
                          onChange={handleInputChange}
                          name="active"
                        />
                      }
                      label=""
                    />
                  ) : system.active ? (
                    "Yes"
                  ) : (
                    "No"
                  )}
                </TableCell>
                <TableCell>
                  {editingId === system.id ? (
                    <>
                      <Button onClick={handleUpdate} color="primary">
                        Save
                      </Button>
                      <Button
                        onClick={() => setEditingId(null)}
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={() => handleEditClick(system)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentSystemList;
