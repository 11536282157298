import React, { useContext, useEffect, useMemo, useState } from "react";
import { IFreespins } from "../../../types/freespins.type";
import { useFormik } from "formik";
import { AlertContext } from "../../../hooks/notification/useAlert";
import { IPercentage } from "../../../types/perscentage.type";
import { useGetFreespins } from "../../../hooks/bonuses/useGetFS";
import { useGetPercentage } from "../../../hooks/bonuses/useGetPR";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { BORDERRADIUS, COLORS } from "../../../consts";
import { AppMenu } from "../../../components/menu/menu";
import { CreateFreespinsModal } from "../create/modals/create.freespins.modal";
import { CreatePercentageModal } from "../create/modals/create.percentage.modal";
import { EditAdditionalFields } from "./EditAdditionalFields/EditAdditionalFields";
import { IBonusExample } from "../../../types/bonus.type";
import { useEditBonus } from "../../../hooks/bonuses/useEditBonus";
import { PreviewModal } from "../create/modals/create.preview.modal";
import { useGetBrands } from "../../../hooks/brand/useGetBrands";
import { EditMainFields } from "./EditMainFields/EditMainFields";
import { IEditInitialValues } from "./typing/initialValues";

interface IBonusEditProps {
  [key: string]: any;
  bonus: IBonusExample;
  origionalImages: {
    sub_image: null | string;
    bg_image: null | string;
  };
}

export const BonusEdit = ({ bonus, origionalImages }: IBonusEditProps) => {
  const initialValues: IEditInitialValues = {
    display_name: bonus.display_name,
    description: bonus.description,
    fs: bonus.fs ? bonus.fs : undefined,
    pers: bonus.pers ? bonus.pers : undefined,
    min_dep: bonus.activation_rules.min_dep,
    start_date: bonus.promo_code.start_date,
    end_date: bonus.promo_code.end_date,
    duration: bonus.additional_information.duration,
    wager_fs: bonus.additional_information.wager_fs,
    wager_pers: bonus.additional_information.wager_pers,
    type: bonus.type,
    sub_type: bonus.additional_information.sub_type,
    place: bonus.additional_information.place,
    additional_items: {
      deposit: undefined,
      game: undefined,
    },
    email: undefined,
    brand: bonus.brand,
  };

  const navigate = useNavigate();
  const cancelHandler = () => {
    formik.setValues(initialValues);
    navigate("/bonuses");
  };
  const { data: brands } = useGetBrands();
  const { data: freespins } = useGetFreespins();
  const { data: percentage } = useGetPercentage();
  const [freespinsSelectArray, setFreespinsSelectArray] = useState<
    { value: IFreespins; text: string }[]
  >([]);
  const [percentageSelectArray, setPercentageSelectArray] = useState<
    { value: IPercentage; text: string }[]
  >([]);
  const { alertHandler } = useContext(AlertContext);
  const { mutate: edit } = useEditBonus(alertHandler);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append(
        "bonus",
        JSON.stringify({
          ...bonus,
          display_name: values.display_name,
          description: values.description,
          fs: values.fs,
          pers: values.pers,
          activation_rules: {
            min_dep: values.min_dep,
          },
          additional_information: {
            ...bonus.additional_information,
            duration: values.duration,
            wager_fs: values.wager_fs,
            wager_pers: values.wager_pers,
            sub_type: values.sub_type,
            place: values.place,
          },
          promo_code: {
            ...bonus.promo_code,
            start_date: values.start_date,
            end_date: values.end_date,
          },
        })
      );
      formData.append(
        "additional_items",
        JSON.stringify({
          ...values.additional_items,
        })
      );
      for (const key in images) {
        if (!images[key]) {
          continue;
        }
        //@ts-ignore
        formData.append(key, images[key]);
      }
      edit(formData);
    },
  });
  const startDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({
        ...formik.values,
        start_date: date.valueOf().toString(),
      });
      return;
    }
    formik.setValues({ ...formik.values, start_date: "" });
  };
  const endDateHandler = (date: Date) => {
    if (date !== null) {
      formik.setValues({
        ...formik.values,
        end_date: (date.valueOf() + 86340000).toString(),
      });
      return;
    }
    formik.setValues({ ...formik.values, end_date: "" });
  };
  const setFSValue = (value: IFreespins) => {
    formik.setValues({
      ...formik.values,
      fs: value,
    });
  };
  const setPRValue = (value: IPercentage) => {
    formik.setValues({
      ...formik.values,
      pers: value,
    });
  };
  const [images, setImages] = useState<{
    [key: string]: File | null;
    bg_image: File | null;
    sub_image: File | null;
    email_image: File | null;
  }>({ bg_image: null, sub_image: null, email_image: null });
  const imagesHandler = (key: "bg_image" | "sub_image", file: File | null) => {
    if (!file) {
      return;
    }
    setImages((state) => {
      const newState = { ...state };
      for (const keys in newState) {
        if (keys === key) newState[keys] = file;
      }
      return newState;
    });
  };
  const [fsModal, setFsModal] = useState(false);
  const [persModal, setPersModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const closeFSModal = () => {
    setFsModal(false);
  };
  const openFSModal = () => {
    setFsModal(true);
  };
  const closePersModal = () => {
    setPersModal(false);
  };
  const openPersModal = () => {
    setPersModal(true);
  };
  const closePreviewModal = () => {
    setPreviewModal(false);
  };
  const openPreviewModal = () => {
    setPreviewModal(true);
  };

  useEffect(() => {
    if (!freespins || !percentage) {
      return;
    }
    const newFSArray = freespins.map((freespin) => {
      return {
        value: freespin,
        text: `${freespin.count_fs} FS in game ${freespin.game_name} bet ${freespin.bet}`,
      };
    });
    const newPRArray = percentage.map((per) => {
      return {
        value: per,
        text: `${per.count_pers}% to Deposit min ${per.min_sum?.map(
          (min_sum) => `${min_sum.min_sum} ${min_sum.currency_name}`
        )} max ${per.max_sum?.map(
          (max_sum) => `${max_sum.max_sum} ${max_sum.currency_name}`
        )}`,
      };
    });
    setFreespinsSelectArray(newFSArray);
    setPercentageSelectArray(newPRArray);
  }, [freespins, percentage]);
  useEffect(() => {
    if (formik.values.brand?.id !== bonus.brand.id) {
      if (formik.values.brand) {
        formik.setValues({
          ...formik.values,
          min_dep: formik.values.brand.currencies.map((currency) => ({
            currency_name: currency.name,
            min_dep: "0",
          })),
        });
      }
    } else if (formik.values.brand?.id === bonus.brand.id) {
      formik.setValues({
        ...formik.values,
        min_dep: bonus.activation_rules.min_dep,
      });
    }
  }, [formik.values.brand?.id]);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_BG,
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <AppMenu />
      </Box>
      <Box
        sx={{
          margin: "10px",
          width: "calc(100% - 20px)",
          height: "calc(100vh - 54px)",
          display: "flex",
          alignItems: "flex-start",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "6px 16px",
            backgroundColor: COLORS.PRIMARY_BG,
            borderRadius: BORDERRADIUS.PRIMARY,
            gap: "3px",
            marginBottom: "10px",
            width: "100%",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              backgroundColor: COLORS.GREY,
              borderRadius: BORDERRADIUS.PRIMARY,
              color: COLORS.LIGHTGREY,
              fontWeight: "700",
              textAlign: "center",
              marginBottom: "12px",
            }}
          >
            Update Bonus
          </Typography>
          <Box sx={{ display: "flex", gap: "40px" }}>
            <EditMainFields
              brands={brands ? brands : []}
              handler={formik.handleChange}
              values={formik.values}
              FSArray={freespinsSelectArray}
              PRArray={percentageSelectArray}
              setValues={formik.setValues}
              startHandler={startDateHandler}
              endHandler={endDateHandler}
              setFS={setFSValue}
              setPR={setPRValue}
            />
            <EditAdditionalFields
              values={formik.values}
              images={images}
              imagesHandler={imagesHandler}
              setValues={formik.setValues}
              openFSModal={openFSModal}
              openPersModal={openPersModal}
              openPreviewModal={openPreviewModal}
              cancelHandler={cancelHandler}
              submitHandler={formik.handleSubmit}
              origionalImages={origionalImages}
            />
          </Box>
        </Box>
      </Box>
      <CreateFreespinsModal
        brands={brands ? brands : []}
        open={fsModal}
        close={closeFSModal}
      />
      <CreatePercentageModal
        brands={brands ? brands : []}
        open={persModal}
        close={closePersModal}
      />
      <PreviewModal
        open={previewModal}
        close={closePreviewModal}
        values={formik.values}
        images={images}
      />
    </Box>
  );
};
