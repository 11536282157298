import { AxiosError, AxiosResponse } from "axios";
import { HttpService, httpService } from "./httpService";
import { AlertError } from "../errors/AlertError";
import { IMessage } from "../types/message.type";
import { IStatistics } from "../types/statistics.type";

class StatisticsService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  async getStatistics(query: string) {
    try {
      const response = await this.httpService.get<AxiosResponse<IStatistics[]>>(
        {
          url: `/api/bo/stats/${query}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<IMessage>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
      throw error;
    }
  }
}

export const statisticsService = new StatisticsService(httpService);
