import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertError } from "../../errors/AlertError";
import { bonusService } from "../../services/bonusService";

export const useEditBonus = (
  openHandler: (message: string, error?: string) => void
) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (value: FormData) => await bonusService.edit(value),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["bonuses"] });
    },
    onError: (err: AlertError) => {
      openHandler(err.message, err.code);
    },
  });
};
