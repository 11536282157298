import { AxiosError, AxiosResponse } from "axios";
import { IRole } from "../types/role.type";
import { HttpService, httpService } from "./httpService";
import { AlertError } from "../errors/AlertError";
import { IMessage } from "../types/message.type";
import { IPermission } from "../types/permision.type";

class RoleService {
  httpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  async getRoles(id?: number) {
    try {
      const response = await this.httpService.get<AxiosResponse<IRole[]>>(
        {
          url: `/api/bo/role?id=${id}`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async createRole(name: string) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/role`,
          data: {
            name: name,
          },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async createPermission(name: string, description: string) {
    try {
      const response = await this.httpService.post<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/permission`,
          data: {
            name,
            description,
          },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async getPermissions(id?: number) {
    try {
      const response = await this.httpService.get<AxiosResponse<IPermission[]>>(
        {
          url: id ? `/api/bo/permission?id=${id}` : `/api/bo/permission`,
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async setPermissionsToRole(role: IRole, permissions: IPermission[]) {
    try {
      const response = await this.httpService.put<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/role`,
          data: { role, permissions },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }

  async deletePermissionFromRole(role: IRole, permission: IPermission) {
    try {
      const response = await this.httpService.delete<AxiosResponse<IMessage>>(
        {
          url: `/api/bo/role/permissions/delete`,
          data: { role, permission },
        },
        true
      );
      return response.data;
    } catch (error) {
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response) {
        const endError = new AlertError(
          `${newError.response.data.message}`,
          newError.response.status.toString()
        );
        throw endError;
      }
    }
  }
}

export const roleService = new RoleService(httpService);
